/* eslint-disable @nx/enforce-module-boundaries */
import { Box, HStack } from '@mybridge/ui/layout';
import { useContext } from 'react';
import { MultiStepFormContext } from '../context';

export const MultiStepFormIndicator = ({}) => {
  const { activeIndex, steps, active } = useContext(MultiStepFormContext);
  // console.log('active tab', active);
  // console.log('active index is', activeIndex);
  // console.log('steps ', steps);
  return (
    <HStack pos="sticky" top={0} py={4} w="100%">
      {steps?.map((_, index) => (
        <Box
          borderRadius="5"
          bg={activeIndex >= index ? 'brandPrimary.500' : 'brandPrimary.200'}
          h="3"
          w="100%"
          flex={1}
          key={index}
        />
      ))}
    </HStack>
  );
};
