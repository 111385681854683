/* eslint-disable @nx/enforce-module-boundaries */
import { useMemo, useState } from 'react';
import { useCompanies } from 'v4/lib/hooks/use-companies';
import { CustomDropdown } from '../custom-dropdown';

export const CompaniesDropdown = ({ ...props }) => {
  const [search, setSearch] = useState(props?.value ?? props?.defaultValue);

  const { data, createLoading, mutateAsync, isLoading } = useCompanies({
    search,
  });

  const companies = useMemo(
    () => data?.pages?.reduceRight?.((ac, p) => ac.concat(p?.results), []),
    [data?.pages]
  );

  return (
    <CustomDropdown
      {...props}
      data={companies}
      isLoading={isLoading}
      onSearch={(v) => setSearch(v)}
    />
  );
};
