/* eslint-disable @nx/enforce-module-boundaries */
import { useDisclosure, useToast } from '@mybridge/ui/hooks';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { createContext, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EMPLOYMENT_CHOICES, WORKPLACE_TYPE } from 'v4/lib/data';
import { getGlobalSkills } from 'v4/store/actions/global.data.actions';
import {
  postNewJob,
  UpdatePartialJob,
} from 'v4/store/actions/companyJobs.actions';
import { getCompanyPageInfo } from 'v4/store/actions/companyPages.actions';
import { getNextPageFromURL } from 'v4/utils/utils';
import _ from 'lodash';
export const CreateJobsContext = createContext({});

// eslint-disable-next-line no-empty-pattern
export const useCreateJobsContext = ({}) => {
  const { globalSkills } = useSelector((state) => state.globalData);
  const { companyPageDetail } = useSelector((state) => state.companyPage);
  const [section, setSection] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [jobDetails, setJobDetails] = useState({});
  const [screeningQuestions, setScreeningQuestions] = useState();
  const [selectCompany, setSelectCompany] = useState();
  const [windowMaximise, setWindowMaximise] = useState(false);
  const [jobUserVerification, setJobUserVerification] = useState(false);
  const [whyWorkHere, setWhyWorkHere] = useState();

  const { userProfileInfo } = useSelector((state) => state?.userProfile) ?? {};
  const { loggedIn } = useSelector((state) => state?.user) ?? {};
  const dispatch = useDispatch();
  const toast = useToast({
    position: 'top',
  });
  useEffect(() => {
    dispatch(getGlobalSkills());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (jobDetails && jobDetails?.hiring_company) {
      dispatch(getCompanyPageInfo({ id: jobDetails.hiring_company })).then(
        (res) => {
          setSelectCompany(res?.payload);
          setWhyWorkHere(res?.payload?.why_work_here);
        }
      );
    }
  }, [jobDetails?.hiring_company]);

  // create job
  const { mutateAsync: createJobAsync, isLoading: createJobLoading } =
    useMutation({
      mutationKey: ['create-job'],
      mutationFn: async (jobDetails) => {
        const resp = (await dispatch(postNewJob(jobDetails)))?.payload;
        return resp;
      },
    });

  // create job
  const { mutateAsync: updateJobAsync, isLoading: updateJobLoading } =
    useMutation({
      mutationKey: ['update-job'],
      mutationFn: async (jobDetails) => {
        const resp = (await dispatch(UpdatePartialJob(jobDetails)))?.payload;
        return resp;
      },
    });

  const createOrUpdateJob = async (jobDetails) => {
    try {
      let resp;
      if (jobDetails?.id) {
        resp = await updateJobAsync(jobDetails);
      } else {
        resp = await createJobAsync(jobDetails);
      }
      // console.log('response', resp);
      if (resp?.status_code >= 400) {
        toast({
          title: 'Error',
          description: resp?.detail,
          status: 'error',
          isClosable: true,
        });
      } else {
        setJobDetails(resp);
        if (resp?.hiring_company_details) {
          setSelectCompany(resp?.hiring_company_details);
          setWhyWorkHere(resp?.hiring_company_details?.why_work_here);
        }
        if (section <= 4) {
          setSection(section + 1);
        }
      }
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    } finally {
      console.log();
    }
  };

  return {
    section,
    setSection,
    isEdit,
    setIsEdit,
    jobDetails,
    setJobDetails,
    screeningQuestions,
    setScreeningQuestions,
    userProfileInfo,
    loggedIn,
    selectCompany,
    setSelectCompany,
    EMPLOYMENT_CHOICES,
    WORKPLACE_TYPE,
    toast,
    globalSkills,
    createOrUpdateJob,
    createJobLoading,
    updateJobLoading,
    companyPageDetail,
    windowMaximise,
    setWindowMaximise,
    whyWorkHere,
    setWhyWorkHere,
    jobUserVerification, setJobUserVerification
  };
};

export const CreateJobsProvider = ({ children, props }) => {
  const globalCtx = useCreateJobsContext({ ...props });
  return (
    <CreateJobsContext.Provider value={globalCtx}>
      {children}
    </CreateJobsContext.Provider>
  );
};
