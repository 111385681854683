/* eslint-disable @nx/enforce-module-boundaries */
import { getMessaging, getToken } from 'firebase/messaging';
import localforage from 'localforage';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getFirebaseApp } from 'v4/utils/firebase';

const useFcmToken = () => {
  const { loggedIn, userLoggedOut } = useSelector((state) => state.user);
  const [token, setToken] = useState('');
  const [notificationPermissionStatus, setNotificationPermissionStatus] =
    useState('');

  useEffect(() => {
    const retrieveToken = async () => {
      try {
        if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
          const messaging = getMessaging(getFirebaseApp());

          // Retrieve the notification permission status
          const permission = await Notification.requestPermission();
          setNotificationPermissionStatus(permission);

          // Check if permission is granted before retrieving the token
          if (permission === 'granted') {
            const currentToken = await getToken(messaging);
            if (currentToken) {
              setToken(currentToken);
              localforage.setItem('fcm_token', currentToken);
              console.log('fcm_token', currentToken);
            } else {
              console.log(
                'No registration token available. Request permission to generate one.'
              );
            }
          }
        }
      } catch (error) {
        console.log('An error occurred while retrieving token:', error);
      }
    };
    if (loggedIn) {
      retrieveToken();
    }
  }, [loggedIn]);

  useEffect(() => {
    const removeToken = async () => {
      try {
        if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
          const messaging = getMessaging(getFirebaseApp());

          // Retrieve the notification permission status
          const permission = await Notification.requestPermission();
          setNotificationPermissionStatus(permission);

          // Check if permission is granted before retrieving the token
          if (permission === 'granted') {
            const currentToken = await getToken(messaging);
            if (currentToken) {
              setToken('');
              // Delete the FCM token
              try {
                await messaging.deleteToken(token);
                await messaging.unsubscribeFromAllTopics();
                localforage.removeItem('fcm_token');
                console.log('Delete successful');
              } catch (e) {
                console.log(e);
              }

              // console.log('fcm_token', currentToken);
            } else {
              console.log(
                'No registration token available. Request permission to generate one.'
              );
            }
          }
        }
      } catch (error) {
        console.log('An error occurred while retrieving token:', error);
      }
    };
    if (userLoggedOut) {
      removeToken();
    }
  }, [userLoggedOut]);

  return { fcmToken: token, notificationPermissionStatus };
};

export default useFcmToken;
