import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const defaultInput = definePartsStyle({
  // define the part you're going to style
  field: {
    border: '1px solid',
    borderColor: 'brandGray.100',
    background: 'brandGray.200',
    borderRadius: '5px',
    padding:'14px 12px',
    height:'48px'
  },
})

const whiteInput = definePartsStyle({
  // define the part you're going to style
  field: {
    border: '1px solid',
    borderColor: 'brandGray.500',
    background: 'white',
    borderRadius: '5px',
    padding:'14px 12px',
    height:'48px'
  },
})

const greyRounded = definePartsStyle({
  // define the part you're going to style
  field: {
    border: '1px solid',
    borderColor: 'brandGray.300',
    background: 'brandGray.150',
    borderRadius: '25px',
    padding: '5px 12px',
    fontSize:'16px',
    height:'36px'
  },
  element:{
    height:'36px'
  },
  addon:{
    height:'36px'
  }
})

export const inputTheme = defineMultiStyleConfig({ defaultProps: {
  variant: 'defaultInput',
}, variants: { defaultInput, whiteInput, greyRounded }, })

export const numberInputTheme = defineMultiStyleConfig({ defaultProps: {
  variant: 'defaultInput',
}, variants: { defaultInput, whiteInput }, })