export const GoogleIcon = (props) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7041 8.64465V12.5965H16.1958C15.9546 13.8674 15.231 14.9435 14.1457 15.667L17.4574 18.2367C19.3869 16.4556 20.5001 13.8396 20.5001 10.732C20.5001 10.0084 20.4352 9.31261 20.3145 8.64476L10.7041 8.64465Z"
        fill="#4285F4"
      />
      <path
        d="M4.98526 12.4421L4.23834 13.0139L1.59448 15.0733C3.27353 18.4035 6.71487 20.7041 10.7038 20.7041C13.4588 20.7041 15.7686 19.795 17.457 18.2366L14.1453 15.667C13.2362 16.2792 12.0766 16.6503 10.7038 16.6503C8.05068 16.6503 5.79655 14.86 4.98943 12.448L4.98526 12.4421Z"
        fill="#34A853"
      />
      <path
        d="M1.59455 5.92664C0.89885 7.29951 0.5 8.84871 0.5 10.4999C0.5 12.1511 0.89885 13.7003 1.59455 15.0732C1.59455 15.0824 4.98982 12.4386 4.98982 12.4386C4.78574 11.8264 4.66511 11.1771 4.66511 10.4998C4.66511 9.82253 4.78574 9.17321 4.98982 8.56096L1.59455 5.92664Z"
        fill="#FBBC05"
      />
      <path
        d="M10.704 4.3589C12.2068 4.3589 13.5426 4.87837 14.6094 5.88025L17.5315 2.95818C15.7596 1.30698 13.4591 0.295776 10.704 0.295776C6.71508 0.295776 3.27353 2.58708 1.59448 5.92664L4.98965 8.56118C5.79666 6.14926 8.05089 4.3589 10.704 4.3589Z"
        fill="#EA4335"
      />
    </svg>
  );
};
