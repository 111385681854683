/* eslint-disable @nx/enforce-module-boundaries */
import { ChevronDownIcon } from '@chakra-ui/icons';
import { ArrowDown, CloseIcon } from '@mybridge/icons';
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  RadioGroup,
  Spinner,
  Stack,
} from '@mybridge/ui';
import _ from 'lodash';
import { forwardRef, useEffect, useRef, useState, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CustomRadioButton } from 'v4/components/custom-radio-checkbx';
import { FilterPopover } from 'v4/components/filter-popover';
import { formatCommaSeparated } from 'v4/lib/commons';
import { useCityStateCountry } from 'v4/lib/hooks/use-city-state-country';
import { useCountries } from 'v4/lib/hooks/use-countries';
import { useCurrentLocation } from 'v4/lib/hooks/use-location';
import { useRadioGroup } from '@chakra-ui/react';
import { SearchContext } from '../../../snippets/search/context';
import styles from '../../filter-popover/filter-popover.module.scss';
export const SearchFilterLocations = forwardRef(
  (
    {
      name = 'location',
      title = 'Locations',
      placeholder = 'Add a location',
      onChange,
      value,
      defaultValue,
      defaultArray,
      filterFor,
      ...props
    },
    ref
  ) => {
    const [allData, setAllData] = useState([]);
    const val = defaultValue ?? value;
    const tid = useRef(-1);
    const { handleSubmit, control, reset } = useForm();
    const [search, setSearch] = useState();
    const [location, setLocation] = useState();
    const [countryCode, setCountryCode] = useState();
    const [popperOpen, setPopperOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState();
    const { resetFilters, setResetFilters } = useContext(SearchContext);

    const onSubmit = (form) => {
      // const data_ = _.find(allData, function(o){return o.id == form});
      onChange?.({
        [name]: form,
      });
      setSelectedValue(form);
      setPopperOpen(false);
      setSearch(form);
    };
    const { countries } = useCountries({
      search,
    });
    const autoLocation = useCurrentLocation({
      enabled: !val?.country,
    });
    const mapDefaultLocations = (location) => {
      // console.log('location', location);
      return formatCommaSeparated([
        location?.city,
        location?.state ? location?.state : '',
        location?.country,
      ]);
    };
    const { data, isFetching, refetch } = useCityStateCountry({
      search,
      countryCode,
    });

    // useEffect(() => {
    //   setAllData(_.map(defaultArray, mapDefaultLocations));
    // }, [defaultArray]);

    const handleSearch = (e) => {
      clearTimeout(tid.current);
      tid.current = setTimeout(() => {
        setSearch(e?.target?.value);
      }, 300);
    };

    useEffect(() => {
      refetch();
    }, [search]);

    useEffect(() => {
      selectedValue ? setResetFilters(true) : setResetFilters(false)
    }, [selectedValue]);

    useEffect(() => {
      if(!resetFilters){
        setSelectedValue();
        setSearch();
      }
    }, [resetFilters]);

    useEffect(() => {
      if (search?.length) {
        // setAllData(flattenQueryResults(data));
        // const filteredResults = _.filter(allData?.[0], item => _.includes(item.toLowerCase(), search.toLowerCase()));

        // setAllData(_.map(_.uniq(_.compact(filteredResults))));
        setAllData(_.map(data?.pages?.[0], mapDefaultLocations));
      } else {
        if (defaultArray?.length && filterFor === 'people') {
          setAllData(
            _.map(_.uniqWith(_.compact(defaultArray)), mapDefaultLocations)
            // _.map(
            //   _.uniqWith(_.compact(defaultArray), (obj1, obj2) => {
            //     return (
            //       obj1?.city === obj2?.city ||
            //       obj1?.country === obj2?.country ||
            //       obj1?.state === obj2?.state
            //     );
            //   }),
            //   mapDefaultLocations
            // )
          );
        } else if (defaultArray?.length && filterFor === 'teams') {
          setAllData(_.map(_.uniq(_.compact(defaultArray))));
        } else if (defaultArray?.length && filterFor === 'company') {
          setAllData(
            _.map(_.uniqWith(_.compact(defaultArray)), mapDefaultLocations)
          );
        } else {
          setAllData([]);
        }
      }
    }, [data, search, defaultArray]);

    useEffect(() => {
      if (autoLocation) {
        setLocation(
          formatCommaSeparated([
            autoLocation?.city,
            autoLocation?.state_region,
            autoLocation?.country,
          ])
        );
        setCountryCode(autoLocation?.countryCode);
      }
    }, [autoLocation]);

    useEffect(() => {
      if (!defaultValue) {
        reset();
      }
    }, [defaultValue]);

    const { getRadioProps, getRootProps } = useRadioGroup({
      onChange: onSubmit,
    });

    // console.log('selectedValue', selectedValue);

    return (
      <>
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <FilterPopover
          trigger={
            <Box w="100%">
              <Button
                variant="transparent"
                fontSize="15px"
                color="brandGray.750"
                rightIcon={selectedValue ? '' : ''}
                onClick={(e) => setPopperOpen((prev) => !prev)}
                justifyContent="space-between"
                w="100%"
              >
                {selectedValue && resetFilters ? selectedValue.length >26 ? selectedValue.slice(0,26)+ "..." : selectedValue : title}
                {selectedValue && resetFilters ? (
                  <CloseIcon
                    width="15"
                    height="15"
                    color="#3D5A80"
                    cursor="pointer"
                    onClick={() => {onSubmit(null); reset();}}
                  />
                ) : (
                  <ArrowDown />
                )}
              </Button>
            </Box>
          }
          isFetching={isFetching}
          onPopClose={(e) => setPopperOpen(e)}
        >
          <InputGroup pl={6}>
            <Input
              placeholder={placeholder}
              defaultValue={search}
              onChange={handleSearch}
              variant="transparent"
              border="none"
              h="24px"
            />
            {/* {isFetching ? (
                    ''
                  ) : (
                    <InputRightElement>
                      <ArrowDown />
                    </InputRightElement>
                  )} */}
          </InputGroup>
          {isFetching ? (
            <HStack w="100%" justifyContent="center">
              <Spinner />
            </HStack>
          ) : (
            <Controller
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack
                    maxH="48"
                    overflowY="hidden"
                    className={styles.filterList}
                  >
                    {allData?.map?.((v, vIndex) => (
                      // <Checkbox py={1} key={vIndex} value={v + ''}>
                      //   {v}
                      // </Checkbox>
                      <CustomRadioButton
                        key={vIndex}
                        type="checkbox"
                        justifyContent="flex-start"
                        {...getRadioProps({ value: v + '' })}
                        // isChecked={true}
                      >
                        {v}
                      </CustomRadioButton>
                    ))}
                  </Stack>
                </RadioGroup>
              )}
              control={control}
              name={name}
            />
          )}
        </FilterPopover>
        {/* </form> */}
      </>
    );
  }
);
