/* eslint-disable @nx/enforce-module-boundaries */
'use client';

import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { store } from 'v4/store/store';
import Footer from '../footer';
import Header from '../header';

export const RootLayout = ({ children }) => {
  return (
    <StoreProvider store={store}>
      <React.Fragment>
        <Header />
        <main>{children}</main>
        <Footer />
      </React.Fragment>
    </StoreProvider>
  );
};
