import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

// define the base component styles
const baseStyle = {
    borderRadius: '8px', // add a border radius
    fontWeight: 'normal', // change the font weight
    padding:3,
    color:'white',
    // background:"#5B5B5B"
  }

const round = defineStyle({
    borderRadius: "4"
});

export const tooltipTheme = defineStyleConfig({
    variants: { round },
    baseStyle
});
