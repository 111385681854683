import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const base = defineStyle({
  minH: '12',
  py: 2,
  outline: 'none',
  border: '1px solid !important',
  borderColor: 'brandGray.100',
  background: 'brandGray.200',
  borderRadius: '5px',
    _focusVisible:{
      borderColor:'#3D5A80',
      boxShadow:'none'
    }
});
const whiteInput = definePartsStyle({
  field: {
    minH: '12',
    py: 2,
    outline: 'none',
    border: '1px solid',
    borderColor: 'brandGray.500',
    background: 'white',
    borderRadius: '5px',
    _focusVisible:{
      borderColor:'#3D5A80',
      boxShadow:'none'
    }
   },
 })

const baseStyle = definePartsStyle({
  // define the part you're going to style
  field: base
});

export const selectTheme = defineMultiStyleConfig({
  baseStyle, variants:{whiteInput}
});
