/* eslint-disable @nx/enforce-module-boundaries */
import { createReducer } from '@reduxjs/toolkit';
import localforage from 'localforage';
import {
  activateYourAccount,
  changeEmailRequest,
  forgetPassword,
  googleSignIn,
  resendVerificationEmail,
  resetPassword,
  setInactiveEmail,
  setLoggedIn,
  signInUser,
  signUpUser,
  addToken,
  addUser,
  logout,
  setSignInModalOpen,
  setSignUpModalOpen,
  openSignInModal,
  openSignUpModal,
  requestTokenRefresh,
  setUserFirstLogin,
  setOpenAuthModal,
  getMatrixToken,
  setChatLoggedIn,
  setPageLoaded,
} from '../actions/auth.actions';
import { clearAllCookies } from 'v4/lib/auth';
const isClient = typeof window !== 'undefined';

const initialState = {
  user: '',
  msg: '',
  loading: false,
  error: false,
  signInError: false,
  signInPage: false,
  email: '',
  id_token: '',
  refresh: '',
  access: '',
  isActivated: false,
  forgetPasswordMsg: '',
  loggedIn: false,
  userInactive: false,
  inactiveEmailId: '',
  changedEmail: false,
  openAuthModal: null,
  chatLoggedIn: false,
  pageLoaded: false,
  userLoggedOut:false
};

export const authReducer = createReducer(initialState, (builder) => {
  builder

    .addCase(setPageLoaded, (state, action) => {
      state.pageLoaded = true;
    })
    .addCase(addToken, (state, action) => {
      state.access = localStorage.getItem('access');
      state.refresh = localStorage.getItem('refresh');
    })
    .addCase(addUser, (state, action) => {
      state.user = localStorage.setItem('user', action.payload);
    })
    .addCase(setOpenAuthModal, (state, action) => {
      state.openAuthModal = action.payload;
    })
    .addCase(logout, (state, action) => {
      if (isClient) {
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        localStorage.clear();
        localforage.clear();
        clearAllCookies()
      }
      state.access = null;
      state.refresh = null;
      state.loggedIn = false;
      state.chatLoggedIn = false;
      state.userLoggedOut = true;
      // localStorage.clear();
    })
    .addCase(signUpUser.pending, (state, action) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(signUpUser.fulfilled, (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        // state.msg = a ction.payload.msg;
        if (action.payload.email && action.payload.status_code === 400) {
          state.msg = 'User with email already exists. Sign In to continue';
          state.error = true;
        } else {
          state.msg = action.payload.message;
          state.error = false;
          state.access = action.payload.access;
          state.refresh = action.payload.refresh;
          if (action.payload.access) {
            localStorage.setItem('access', action.payload.access);
            localStorage.setItem('refresh', action.payload.refresh);
          }
          state.loggedIn = true;
        }
      }
    })
    .addCase(signUpUser.rejected, (state, action) => {
      state.loading = false;
    })

    /* sign in */

    .addCase(signInUser.pending, (state, action) => {
      state.pending = true;
    })
    .addCase(signInUser.fulfilled, (state, action) => {
      state.pending = false;
      if (action.payload.code === 'INACTIVE_USER') {
        state.loggedIn = false;
        state.userInactive = true;
        state.error =
          'It seems your account is not active please activate your account!';
      } else if (action.payload.code === 'INVALID_CREDS') {
        state.error =
          "Incorrect login credentials, if you don't have an account please Sign Up";
        state.loggedIn = false;
      } else {
        state.access = action.payload.access;
        state.refresh = action.payload.refresh;
        localStorage.setItem('access', action.payload.access);
        localStorage.setItem('refresh', action.payload.refresh);
        state.loggedIn = true;
        state.userLoggedOut = false;
      }
    })
    .addCase(signInUser.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(getMatrixToken.fulfilled, (state, action) => {
      state.pending = false;
      state.matrixToken = action.payload.matrix_access_token;
      state.matrixRefresh = action.payload.matrix_refresh_token;
      localStorage.setItem('chatAccess', action.payload.matrix_access_token);
      localStorage.setItem('chatRefresh', action.payload.matrix_refresh_token);
      localStorage.setItem('chatDeviceId', action.payload.matrix_device_id);
      state.chatLoggedIn = true;
      state.chatLoginDetails = action.payload;
    })
    .addCase(requestTokenRefresh.pending, (state, action) => {
      state.pending = true;
    })
    .addCase(requestTokenRefresh.fulfilled, (state, action) => {
      state.pending = false;
      state.access = action.payload.access;
      localStorage.setItem('access', action.payload.access);
      state.loggedIn = true;
    })
    .addCase(requestTokenRefresh.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })

    /* Forget password actions */
    .addCase(forgetPassword.pending, (state, action) => {
      state.loading = true;
      state.error = false;
      state.forgetPasswordMsg = '';
    })
    .addCase(forgetPassword.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.status_code === 400) {
        state.forgetPasswordMsg = action?.payload?.email[0];
        state.error = true;
      } else {
        state.forgetPasswordMsg = 'Link is sent to your mail';
        //state.forgetPasswordMsg = action.payload.forgetPasswordMsg;
      }
    })
    .addCase(forgetPassword.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.forgetPasswordMsg = 'Link is not sent to your mail';
    })

    /* ResetPassword */

    .addCase(resetPassword.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    })
    .addCase(resetPassword.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.status === 'OK') {
        state.msg = 'Password is updated';
      } else {
        state.msg = 'Something is wrong';
        state.error = true;
      }
    })
    .addCase(resetPassword.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.msg = 'Link is not sent to your mail';
    })

    /* google sign in */

    .addCase(googleSignIn.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    })
    .addCase(googleSignIn.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.access) {
        state.access = action.payload.access;
        state.refresh = action.payload.refresh;

        localStorage.setItem('access', action.payload.access);
        localStorage.setItem('refresh', action.payload.refresh);
      } else {
        state.error = true;
      }

      state.loggedIn = true;
    })
    .addCase(googleSignIn.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      //  state.msg = "not logged in";
    })
    /* activate account */
    .addCase(activateYourAccount.pending, (state, action) => {
      state.loading = true;
      state.error = false;
      state.isActivated = false;
    })
    .addCase(activateYourAccount.fulfilled, (state, action) => {
      state.loading = false;
      state.access = action.payload.access;
      state.refresh = action.payload.refresh;
      if (action.payload.access) {
        state.isActivated = true;
        localStorage.setItem('access', action.payload.access);
        localStorage.setItem('refresh', action.payload.refresh);
      } else {
        state.error = true;
      }
    })
    .addCase(activateYourAccount.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.msg = 'not logged in';
    })

    /* Resend Verification Link */
    .addCase(resendVerificationEmail.pending, (state, action) => {
      state.loading = true;
      state.error = false;
      state.isActivated = false;
    })
    .addCase(resendVerificationEmail.fulfilled, (state, action) => {
      state.loading = false;
      state.isActivated = false;
      state.verifyMailSent = true;
      state.error = false;
    })
    .addCase(resendVerificationEmail.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.msg = 'not logged in';
    })
    /* Change Email ID Request */
    .addCase(changeEmailRequest.pending, (state, action) => {
      state.loading = true;
      state.error = false;
      state.isActivated = false;
    })
    .addCase(changeEmailRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.isActivated = false;
      if (action.payload.status_code === 200) {
        state.changedEmail = true;
        state.error = false;
      } else {
        state.changedEmail = false;
        state.emailMessage = action.payload.detail;
        state.error = true;
      }
    })
    .addCase(changeEmailRequest.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.msg = 'not logged in';
    })

    .addCase(setInactiveEmail, (state, action) => {
      state.inactiveEmailId = action.payload;
    })
    .addCase(setUserFirstLogin, (state, action) => {
      state.userFirstLogin = action.payload;
    })
    .addCase(setLoggedIn, (state, action) => {
      state.loggedIn = action.payload;
    })
    .addCase(setChatLoggedIn, (state, action) => {
      state.chatLoggedIn = action.payload;
    });
});
