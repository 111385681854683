import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

const brandPrimary = definePartsStyle({
  container: {
    bg: 'brandPrimary.500',
    color: 'white',
  },
});

export const tagTheme = defineMultiStyleConfig({
  variants: {
    primary: brandPrimary,
  },
});
