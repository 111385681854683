/* eslint-disable @nx/enforce-module-boundaries */
import { EditIcon } from '@mybridge/icons/Edit';
import { GmailIcon } from '@mybridge/icons/Gmail';
import { Button } from '@mybridge/ui/button';
import { Divider } from '@mybridge/ui/divider';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@mybridge/ui/form-control';
import { useToast } from '@mybridge/ui/hooks';
import { IconButton } from '@mybridge/ui/icon-button';
import { Input, InputGroup, InputRightElement } from '@mybridge/ui/input';
import { Box, HStack, Stack } from '@mybridge/ui/layout';
import { Link } from '@mybridge/ui/link';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { Text } from '@mybridge/ui/text';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getUserFullName } from 'v4/lib/commons';
import {
  openSignInModal,
  resendVerificationEmail,
  setLoggedIn,
  setOpenAuthModal,
} from 'v4/store/actions/auth.actions';
import API from 'v4/store/common/api';
import { APIEndPoints } from 'v4/store/common/endPoint';

export const SendVerifyLinkModal = ({ ...props }) => {
  const { push } = useRouter();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const toast = useToast({
    position: 'top',
    isClosable: true,
  });
  const { userProfileInfo } = useSelector((state) => state.userProfile) ?? {};
  const { email } = userProfileInfo ?? {};
  const onFormSubmit = async () => {
    const payload = {
      email,
    };
    setLoading(true);
    try {
      const res = await dispatch(resendVerificationEmail(payload));
      const { status_code } = res ?? {};
      if (status_code >= 400) {
        let title = 'Error while sending link!';
        const { email } = res ?? {};
        if (email.length) {
          title = email?.[0];
        }
        toast({
          title,
          status: 'error',
        });
        return;
      }
      toast({
        title: 'Verification code sent successfully!',
        status: 'success',
      });
      dispatch(setOpenAuthModal('activate-account'));
    } catch (e) {
      toast({
        title: 'Error while sending link!',
        description: e?.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal size="lg" isCentered {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          You are almost there {getUserFullName(userProfileInfo)}
        </ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <ModalBody pb={6}>
            <Stack>
              <Text>
                We need to confirm your email address. Check your {email}{' '}
                account or request a new confirmation link.
              </Text>
              <HStack alignItems="flex-start">
                <FormControl isInvalid={errors?.email}>
                  <InputGroup>
                    <Input
                      type="email"
                      isDisabled={true}
                      autoFocus
                      value={email}
                      placeholder="Email"
                      {...register('email', { required: true })}
                    />
                    <InputRightElement>
                      <IconButton
                        onClick={(e) =>
                          dispatch(setOpenAuthModal('change-email'))
                        }
                        variant="ghost"
                      >
                        <EditIcon />
                      </IconButton>
                    </InputRightElement>
                  </InputGroup>
                  {errors?.email && (
                    <FormErrorMessage>
                      Please enter a valid email
                    </FormErrorMessage>
                  )}
                </FormControl>
              </HStack>
            </Stack>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Stack flex={1}>
              <Button
                py={6}
                fontWeight="bold"
                w="100%"
                variant="primary"
                isLoading={loading}
                type="submit"
                onClick={onFormSubmit}
              >
                Resend Email
              </Button>
              <Button
                onClick={(e) => dispatch(setOpenAuthModal('activate-account'))}
                py={6}
                fontWeight="bold"
                w="100%"
                variant="ghost"
              >
                I already have code
              </Button>
            </Stack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
