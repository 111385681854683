export const SearchIcon = (props) => {
  return(
    <svg
    {...props} width={props.width || '18px'} height={props.height || '18px'}
      viewBox="0 0 18 18"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1082_38728)">
        <path
          d="M8.53311 0.634766C8.82456 0.676239 9.11601 0.709418 9.4033 0.763334C10.7148 1.00388 11.8723 1.57207 12.8674 2.45545C13.9958 3.45911 14.741 4.68673 15.0991 6.1466C15.5113 7.83042 15.2823 9.45204 14.5495 11.0073C14.4704 11.1732 14.4746 11.2644 14.612 11.3971C15.478 12.2474 16.3399 13.1059 17.1976 13.9644C18.0594 14.827 18.0386 16.2827 17.1643 17.079C16.3607 17.809 15.1866 17.8214 14.383 17.079C13.8292 16.5689 13.3088 16.0214 12.7717 15.4906C12.3761 15.0966 11.9764 14.7026 11.585 14.3044C11.5018 14.2215 11.4393 14.2049 11.3311 14.2588C10.211 14.7897 9.03691 15.0883 7.79199 15.0344C6.11406 14.9639 4.62765 14.3915 3.35775 13.2966C2.12532 12.2349 1.31342 10.9119 0.996984 9.31518C0.534824 6.99681 1.06777 4.91069 2.62495 3.10659C3.67835 1.88727 5.00654 1.12001 6.58454 0.788218C6.86767 0.730155 7.15496 0.705271 7.43808 0.663797C7.49221 0.655502 7.54633 0.64306 7.60046 0.634766C7.91273 0.634766 8.22084 0.634766 8.53311 0.634766ZM13.6002 7.84287C13.6002 4.80286 11.1229 2.33103 8.06679 2.33103C5.01486 2.33103 2.53335 4.80286 2.53335 7.84287C2.53335 10.8829 5.0107 13.3547 8.06679 13.3506C11.1229 13.3506 13.6002 10.8829 13.6002 7.84287Z"
          fill={props?.color ?? '#747474'}
        />
      </g>
    </svg>
  );
};
