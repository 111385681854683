import { createReducer } from '@reduxjs/toolkit';
import {
  getGlobalCountries,
  getGlobalStates,
  getGlobalCities,
  getGlobalCompanies,
  createGlobalCompanies,
  getGlobalCompanyTypes,
  getGlobalCompanySize,
  getGlobalIndustries,
  createGlobalSchools,
  createGlobalIndustries,
  getGlobalSkills,
  getFeelingsAndActivities,
  getGlobalLanguages,
  getGlobalCurrencies,
  setPageHead,
  setPageHeadPrepend,
  setPageHeadAppend,
  searchGlobalLocations,
  setMobileDevice, setTabletDevice, getRecentSearch, getStaticPageContent, getSearchArticles,
  getUpcomingMeetingCount, updateMeetingSeen, setHeaderGlobalNotifCount
} from '../actions/global.data.actions';

const initialState = {
  msg: '',
  loading: false,
  error: false,
  globalCountries: [],
  globalStates: [],
  globalCities: [],
  globalCompanies: [],
  globalCompanyTypes: [],
  globalCompanySize: [],
  globalSkills: [],
  globalSchools: [],
  globalColleges: [],
  globalCertificates: [],
  globalCourses: [],
  globalDegrees: [],
  globalIndustries: [],
  globalCurrencies: [],
  globalLanguages: [],
  companyCreated: false,
  industryCreated: false,
  globalLocationSearch:null,
  isMobile: false,
  isTablet: false,
  recentSearches:null,
  pageContetStatic:null,
  userArticlesList:[],
  userArticlesListCount:0,
  unreadMeetingCount : 0,
  headerGlobalNotifCount:0
};

const globalDataReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getFeelingsAndActivities.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getFeelingsAndActivities.fulfilled, (state, action) => {
      const data = action?.payload?.data ?? {};
      const arr = [];
      for (let k in data) {
        data?.[k]?.forEach?.((fa) => {
          fa.type = k === 'Feelings' ? 'feeling' : k?.toLowerCase?.();
          arr.push(fa);
        });
      }
      state.feelingsAndActivities = data;
      state.feelingsAndActivitiesArr = arr;
      state.loading = false;
    })
    .addCase(getFeelingsAndActivities.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(getGlobalCountries.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getGlobalCountries.fulfilled, (state, action) => {
      state.globalCountries = action?.payload?.results?.map((item) => item);
      state.loading = false;
    })
    .addCase(getGlobalCountries.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(getGlobalStates.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getGlobalStates.fulfilled, (state, action) => {
      state.globalStates = action?.payload?.results?.map((item) => item);
      state.loading = false;
    })
    .addCase(getGlobalStates.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(getGlobalCities.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getGlobalCities.fulfilled, (state, action) => {
      state.globalCities = action?.payload?.results?.map((item) => item);
      state.loading = false;
    })
    .addCase(getGlobalCities.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(getGlobalSkills.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getGlobalSkills.fulfilled, (state, action) => {
      state.globalSkills = action?.payload?.results?.map((item) => item);
      state.loading = false;
    })
    .addCase(getGlobalSkills.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(getGlobalCompanies.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getGlobalCompanies.fulfilled, (state, action) => {
      state.globalCompanies = action?.payload?.results?.map((item) => item);
      state.loading = false;
    })
    .addCase(getGlobalCompanies.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(createGlobalCompanies.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(createGlobalCompanies.fulfilled, (state, action) => {
      state.companyCreated = true;
    })
    .addCase(createGlobalCompanies.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(getGlobalIndustries.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getGlobalIndustries.fulfilled, (state, action) => {
      state.globalIndustries = action?.payload?.results?.map(
        (item) => item.name
      );
      state.loading = false;
    })
    .addCase(getGlobalIndustries.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(getGlobalCurrencies.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getGlobalCurrencies.fulfilled, (state, action) => {
      state.globalCurrencies = action?.payload?.results;
      state.loading = false;
    })
    .addCase(getGlobalCurrencies.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(getGlobalCompanyTypes.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getGlobalCompanyTypes.fulfilled, (state, action) => {
      state.globalCompanyTypes = action?.payload?.results?.map(
        (item) => item.name
      );
      state.loading = false;
    })
    .addCase(getGlobalCompanyTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(searchGlobalLocations.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(searchGlobalLocations.fulfilled, (state, action) => {
      state.globalLocationSearch = action?.payload;
      state.loading = false;
    })
    .addCase(searchGlobalLocations.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(getGlobalCompanySize.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getGlobalCompanySize.fulfilled, (state, action) => {
      state.globalCompanySize = action?.payload?.results?.map(
        (item) => item.name
      );
      state.loading = false;
    })
    .addCase(getGlobalCompanySize.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(createGlobalIndustries.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(createGlobalIndustries.fulfilled, (state, action) => {
      state.industryCreated = true;
      state.loading = false;
    })
    .addCase(createGlobalIndustries.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(getGlobalLanguages.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getGlobalLanguages.fulfilled, (state, action) => {
      state.globalLanguages = action?.payload?.results?.map((item) => item);
      state.loading = false;
    })
    .addCase(getGlobalLanguages.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(getRecentSearch.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getRecentSearch.fulfilled, (state, action) => {
      state.recentSearches = action?.payload;
      state.loading = false;
    })
    .addCase(getRecentSearch.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(getStaticPageContent.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getStaticPageContent.fulfilled, (state, action) => {
      state.pageContetStatic = action?.payload;
      state.loading = false;
    })
    .addCase(getStaticPageContent.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(getSearchArticles.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getSearchArticles.fulfilled, (state, action) => {
      state.userArticlesList = action?.payload;
      state.userArticlesListCount = action?.payload?.count;
      state.loading = false;
    })
    .addCase(getSearchArticles.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(setPageHead, (state, action) => {
      state.pageHead = action.payload;
    })
    .addCase(setPageHeadPrepend, (state, action) => {
      state.pageHeadPrepend = action.payload;
    })
    .addCase(setPageHeadAppend, (state, action) => {
      state.pageHeadAppend = action.payload;
    })
    .addCase(setMobileDevice, (state, action) => {
      state.isMobile = action.payload;
    })
    .addCase(setTabletDevice, (state, action) => {
      state.isTablet = action.payload;
    })
    .addCase(setHeaderGlobalNotifCount, (state, action) => {
      state.headerGlobalNotifCount = action.payload;
    })
    .addCase(getUpcomingMeetingCount.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getUpcomingMeetingCount.fulfilled, (state, action) => {
      state.unreadMeetingCount = action.payload.count;
      state.loading = false;
    })
    .addCase(getUpcomingMeetingCount.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(updateMeetingSeen.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(updateMeetingSeen.fulfilled, (state, action) => {
      state.meetingSeen = action?.payload;
      state.loading = false;
    })
    .addCase(updateMeetingSeen.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })


    // eslint-disable-next-line @typescript-eslint/no-empty-function
    .addDefaultCase((state, action) => {});
});

export default globalDataReducer;
