'use client';

import { MyBridgeThemeProvider } from '@mybridge/ui/theme-provider';
import { Provider } from 'react-redux';
import {
  SearchContextProvider,
  useSearchContext,
} from 'v4/snippets/search/context';
import { store } from '../../store/store';

export const ThemeProviders = ({ children, ...props }) => {
  return (
    <>
      <Provider store={store}>
        {/* <SearchContext.Provider value={searchContext}> */}
        <SearchContextProvider>
          <MyBridgeThemeProvider {...props}>{children}</MyBridgeThemeProvider>
        </SearchContextProvider>
        {/* </SearchContext.Provider> */}
      </Provider>
    </>
  );
};

