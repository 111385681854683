import { Modal as Modal_ } from '@chakra-ui/react';
import { forwardRef } from 'react';
export const Modal = forwardRef(({ ...props }, ref) => {
  return (
    <Modal_ isCentered={true} ref={ref} {...props}>
      {props?.children}
    </Modal_>
  );
});

import { ModalOverlay as ModalOverlay_ } from '@chakra-ui/react';
export const ModalOverlay = forwardRef(({ ...props }, ref) => {
  return (
    <ModalOverlay_ ref={ref} {...props}>
      {props?.children}
    </ModalOverlay_>
  );
});

import { ModalContent as ModalContent_ } from '@chakra-ui/react';
export const ModalContent = forwardRef(({ ...props }, ref) => {
  return (
    <ModalContent_ ref={ref} {...props}>
      {props?.children}
    </ModalContent_>
  );
});

import { ModalHeader as ModalHeader_ } from '@chakra-ui/react';
export const ModalHeader = forwardRef(({ ...props }, ref) => {
  return (
    <ModalHeader_ ref={ref} {...props}>
      {props?.children}
    </ModalHeader_>
  );
});

import { ModalFooter as ModalFooter_ } from '@chakra-ui/react';
export const ModalFooter = forwardRef(({ ...props }, ref) => {
  return (
    <ModalFooter_ ref={ref} {...props}>
      {props?.children}
    </ModalFooter_>
  );
});

import { ModalBody as ModalBody_ } from '@chakra-ui/react';
export const ModalBody = forwardRef(({ ...props }, ref) => {
  return (
    <ModalBody_ ref={ref} {...props}>
      {props?.children}
    </ModalBody_>
  );
});

import { ModalCloseButton as ModalCloseButton_ } from '@chakra-ui/react';
export const ModalCloseButton = forwardRef(({ ...props }, ref) => {
  return (
    <ModalCloseButton_ ref={ref} {...props}>
      {props?.children}
    </ModalCloseButton_>
  );
});
