import { switchAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(switchAnatomy.keys)

const baseStyle = definePartsStyle({
    // define the part you're going to style
    container: {
        border: '1px solid #d9d9d9',
        padding: '2px',
        borderRadius: '20px',
},
    thumb: {
    bg: 'brandGray.700',
    _checked: {
        bg: 'brandGray.800',
    },
},
    track: {
    bg: 'brandGray.300',
    _checked: {
        bg: 'brandGray.350',
    },
},
})

export const switchTheme = defineMultiStyleConfig({ baseStyle })