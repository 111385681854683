import { Text as Text_ } from '@chakra-ui/react';
import { forwardRef } from 'react';
export const Text = forwardRef(({ truncate = false, size, ...props }, ref) => {
  return (
    <Text_
      ref={ref}
      color="brandGray.700"
      fontSize={size}
      {...(truncate
        ? {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            wordBreak: 'break-word'
          }
        : { wordBreak: 'break-word' })}
      {...props}
    >
      {props?.children}
    </Text_>
  );
});
