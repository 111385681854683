import {
  Box,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Portal,
} from '@mybridge/ui';
import { forwardRef } from 'react';
export const ImagePopup = forwardRef(({ src, ...props }, ref) => {
  return (
    <Portal>
      <Modal size="full" {...props}>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>
        </ModalHeader> */}
          <ModalBody
            bgColor="blackAlpha.900"
            overflowY="auto"
            as={HStack}
            justifyContent="center"
            pos="relative"
          >
            <HStack pos="absolute" zIndex={2} left={0} right={0} top={0}>
              <Box p={2}>
                <Image src="/images/logoMb.png" w="8" h="8" />
              </Box>
              <ModalCloseButton />
            </HStack>
            <Image
              pos="relative"
              zIndex={1}
              bgColor="white"
              src={src}
              maxW="100%"
              maxH="99vh"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Portal>
  );
});
