import { Card as Card_ } from "@chakra-ui/react";
import { forwardRef } from "react";
export const Card = forwardRef(({ ...props }, ref) => {
    return <Card_ ref={ref} {...props}>{props?.children}</Card_>
});

import { CardBody as CardBody_ } from "@chakra-ui/react";
export const CardBody = forwardRef(({ ...props }, ref) => {
    return <CardBody_ ref={ref} {...props}>{props?.children}</CardBody_>
});

import { CardFooter as CardFooter_ } from "@chakra-ui/react";
export const CardFooter = forwardRef(({ ...props }, ref) => {
    return <CardFooter_ ref={ref} {...props}>{props?.children}</CardFooter_>
});

import { CardHeader as CardHeader_ } from "@chakra-ui/react";
export const CardHeader = forwardRef(({ ...props }, ref) => {
    return <CardHeader_ ref={ref} {...props}>{props?.children}</CardHeader_>
});