/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../common/api';
import { CampaignAPIEndPoints } from '../common/endPoint';
import { jsonToFormData } from 'v4/lib/utils';
export const clear = createAction('clear');

export const addNewCampaign = createAsyncThunk('addNewCampaign', async (payload) => {
  try {
    const obj = {
      url: CampaignAPIEndPoints.Campaign,
      method: 'POST',
      body: JSON.stringify(payload),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getCampaignDetails = createAsyncThunk('getCampaignDetails', async (campaignId) => {
  try {
    const obj = {
      url: CampaignAPIEndPoints.CapmaignDetails(campaignId),
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const updateCampaign = createAsyncThunk('updateCampaign', async (payload) => {
  try {
    const obj = {
      url: CampaignAPIEndPoints.Campaign,
      method: 'PUT',
      body: JSON.stringify(payload),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getAllCampaign = createAsyncThunk('getAllCampaign', async ({ pageParam = 1, size = 10, search = '' }) => {
  try {
    const obj = {
      url: `${CampaignAPIEndPoints.Campaign}?query=${search}&page=${pageParam}&page_size=${size}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const addObjectiveDetail = createAsyncThunk('addObjectiveDetail', async (payload) => {
  try {
    const obj = {
      url: CampaignAPIEndPoints.Objective,
      method: 'POST',
      body: JSON.stringify(payload),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getObjectiveDetail = createAsyncThunk('getObjectiveDetail', async (campaignId) => {
  try {
    const obj = {
      url: `${CampaignAPIEndPoints.Objective}?campaign_id=${campaignId}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getExperienceAndAge = createAsyncThunk('getExperienceAndAge', async (payload) => {
  try {
    const obj = {
      url: CampaignAPIEndPoints.ExperienceAndAge,
      method: 'GET',
      body: JSON.stringify(payload),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const createAudienceDetail = createAsyncThunk('createAudienceDetail', async (payload) => {
  try {
    const obj = {
      url: CampaignAPIEndPoints.Audience(payload.id),
      method: 'POST',
      body: JSON.stringify(payload.payload),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const createBudget = createAsyncThunk('createBudget', async (payload) => {
  try {
    const obj = {
      url: CampaignAPIEndPoints.Budget,
      method: 'POST',
      body: payload,
      isFormData: true
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const createSingleImageFormat = createAsyncThunk('createSingleImageFormat', async (payload) => {
  try {
    const obj = {
      url: `${CampaignAPIEndPoints.adFormat(payload?.campaign)}/${payload?.campaign_format}`,
      method: 'POST',
      body: payload?.formData ? jsonToFormData(payload?.content) : JSON.stringify(payload?.content),
      isFormData: payload?.formData
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const updateSingleImageFormat = createAsyncThunk('updateSingleImageFormat', async (payload) => {
  try {
    const obj = {
      url: `${CampaignAPIEndPoints.updatedFormat(payload?.format_id)}/${payload?.campaign_format}`,
      method: 'PUT',
      body: payload?.formData ? jsonToFormData(payload?.content) : JSON.stringify(payload?.content),
      isFormData: payload?.formData
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const uploadAdMediaFiles = createAsyncThunk('uploadAdMediaFiles', async (payload) => {
  try {
    const obj = {
      url: `${CampaignAPIEndPoints.UploadCampaignMedia}`,
      method: 'POST',
      body: jsonToFormData(payload),
      isFormData: true
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const launchCampaign = createAsyncThunk('launchCampaign', async (payload) => {
  try {
    const obj = {
      url: `${CampaignAPIEndPoints.LaunchCampaign(payload)}`,
      method: 'POST',
      // body: jsonToFormData(payload),
      // isFormData: true
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getExistingAdsets = createAsyncThunk('getExistingAdsets', async (payload) => {
  try {
    const obj = {
      url: CampaignAPIEndPoints.GetAdFormats(payload),
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getDashboardSpotlights = createAsyncThunk('getDashboardSpotlights', async ({location}) => {
  try {
    const obj = {
      url: CampaignAPIEndPoints.getSpotlightAds(location),
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getCampaignPerformanceReport = createAsyncThunk('getCampaignPerformanceReport', async (payload) => {
  try {
    const obj = {
      
      url: `${CampaignAPIEndPoints.adAdPerformance(payload?.id)}?start=${payload?.startDate}&end=${payload?.endDate}&group=${payload?.type}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});
export const updateCampaignPerformanceReport = createAsyncThunk('updateCampaignPerformanceReport', async (payload) => {
  try {
    const obj = {
      url: CampaignAPIEndPoints.adAdPerformance(payload?.id),
      method: 'POST',
      body: JSON.stringify(payload.body),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const updatecurrentPerformanceData = createAction('updatecurrentPerformanceData');
export const clearCampaignData = createAction('clearCampaignData');
export const clearCampaignLaunchData = createAction('clearCampaignLaunchData');
