import { List as List_ } from "@chakra-ui/react";
import { forwardRef } from "react";
export const List = forwardRef(({ ...props }, ref) => {
    return <List_ ref={ref} {...props}>{props?.children}</List_>
});

import { ListIcon as ListIcon_ } from "@chakra-ui/react";
export const ListIcon = forwardRef(({ ...props }, ref) => {
    return <ListIcon_ ref={ref} {...props}>{props?.children}</ListIcon_>
});

import { ListItem as ListItem_ } from "@chakra-ui/react";
export const ListItem = forwardRef(({ ...props }, ref) => {
    return <ListItem_ ref={ref} {...props}>{props?.children}</ListItem_>
});

import { OrderedList as OrderedList_ } from "@chakra-ui/react";
export const OrderedList = forwardRef(({ ...props }, ref) => {
    return <OrderedList_ ref={ref} {...props}>{props?.children}</OrderedList_>
});


import { UnorderedList as UnorderedList_ } from "@chakra-ui/react";
export const UnorderedList = forwardRef(({ ...props }, ref) => {
    return <UnorderedList_ ref={ref} {...props}>{props?.children}</UnorderedList_>
});