import { createReducer } from '@reduxjs/toolkit';
import { teamList, addTeam, updateTeam, deleteTeam, getPages } from '../actions/permissions.actions';

const initialState = {
    msg: '',
    loading: false,
    error: false,
    teamInfo: {},
    isTeamUpdated: false,
    pages: null
}

export const permissionsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(teamList.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    })
    .addCase(teamList.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.teamInfo = action.payload;
      }
    })
    .addCase(teamList.rejected, (state, action) => {
        state.loading = false
    })
    .addCase(addTeam.pending, (state, action) => {
        state.loading = true
        state.error = false;
        state.isTeamUpdated = false;
    })
    .addCase(addTeam.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.error || !action.payload.id) {
            state.error = action.payload.error
            state.message = action.payload?.message
        } else {
            state.msg = action.payload.id;
            state.isTeamUpdated = true;
        }
    })
    .addCase(addTeam.rejected, (state, action) => {
        state.loading = false
    })

    .addCase(updateTeam.pending, (state, action) => {
        state.loading = true
        state.error = false;
        state.isTeamUpdated = false;
    })
    .addCase(updateTeam.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.error) {
            state.error = action.payload.error
        } else {
            state.msg = action.payload.msg;
            state.isTeamUpdated = true;
        }
    })
    .addCase(updateTeam.rejected, (state, action) => {
        state.loading = false
    })

    /* delete team */

    .addCase(deleteTeam.pending, (state, action) => {
        state.loading = true;
        state.error = false;
        state.isTeamUpdated = false;
    })
    .addCase(deleteTeam.fulfilled, (state, action) => {
        state.loading = false;
        state.isTeamUpdated = true;
    })
    .addCase(deleteTeam.rejected, (state, action) => {
        state.loading = false;
    })

    /* pages */
    .addCase(getPages.pending, (state, action) => {
        state.error = false;
        state.pages = null;
    })
    .addCase(getPages.fulfilled, (state, action) => {
        if (action.payload.error) {
            state.error = action.payload.error
        } else {
            state.msg = action.payload.msg;
            state.pages = action.payload;
        }
    })
    .addCase(getPages.rejected, (state, action) => {
        state.loading = false
        state.pages = null;
    })
});
