import { useQuery } from '@tanstack/react-query';
import { createContext, useEffect, useMemo, useState } from 'react';

export const LocationContext = createContext({});

export const fetchLocationByPostalCode = async (postal, countryCode) => {
  // const result = await fetch(
  //   `/api/places?postalCode=${postal}${
  //     countryCode?.length ? `&countryCode=${countryCode}` : ``
  //   }`
  // );
  const result = await fetch(
    `${process.env.BASE_API_URL}api/v1/location/google/search?postal_code=${postal}`
  );
  const data = await result?.json?.();
  return data;
};

/**
 * Provide a postal code, and it returns all the nearby places based on google autocomplete api
 * @param {*} param0
 * @returns
 */
export const usePostalLocation = ({
  postal,
  countryCode,
  onSuccess,
  onError,
}) => {
  const [locationLoading, setLocationLoading] = useState(false);
  const [location, setLocation] = useState();

  const fetchLocation = async (code, countryCode_) => {
    setLocationLoading(false);
    if (!code || code.length <= 0) return;
    try {
      setLocationLoading(true);
      const res = await fetchLocationByPostalCode(
        code ?? postal,
        countryCode_ ?? countryCode
      );
      setLocation(res);
      onSuccess?.(res);
      return res;
    } catch (e) {
      console.error(e);
      onError?.(e);
      setLocationLoading(false);
    } finally {
      setLocationLoading(false);
    }
  };

  return {
    fetchLocation,
    locationLoading,
    setLocationLoading,
    location,
  };
};

/**
 * First it searches the ip addresses of current logged in user
 * second, it gets all the location data based on the fetched IP
 * @param {*} param0
 * @returns
 */
export const useCurrentLocation = ({ enabled = true, searchPlace = false }) => {
  const [ip, setIp] = useState();

  const {
    data,
    isLoading: ipLoading,
    isFetching: ipFetching = true,
    refetch: fetchIp,
  } = useQuery({
    queryKey: ['use-location'],
    queryFn: async () => {
      return await (await fetch('https://api.ipify.org?format=json'))?.json?.();
    },
    refetchOnWindowFocus: false,
    enabled,
  });

  const {
    data: countryData,
    refetch,
    isLoading: dataLoading,
    isFetching: dataFetching = true,
  } = useQuery({
    queryKey: ['use-location-country', ip],
    queryFn: async () => {
      if (ip?.length) {
        return await (await fetch(`https://ipapi.co/${ip}/json/`))?.json?.();
      } else return {};
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    country_name: country,
    city,
    country_area: countryArea,
    country_code: countryCode,
    currency,
    latitude,
    longtitude,
    region: state,
    postal,
    region_code: stateCode,
  } = useMemo(() => countryData ?? {}, [countryData]);

  useEffect(() => {
    if (ip?.length) {
      refetch?.(ip);
    }
  }, [ip]);

  useEffect(() => {
    if (data?.ip) {
      setIp(data.ip);
    }
  }, [data]);

  return {
    ip,
    postal,
    countryArea,
    countryCode,
    country,
    currency,
    latitude,
    longtitude,
    ipLoading,
    dataLoading,
    isLoading: ipFetching || dataFetching,
    city,
    state,
    fetchIp,
    fetchIpData: refetch,
  };
};
