

import { ChakraProvider } from "@chakra-ui/react"
import { CacheProvider } from "@chakra-ui/next-js"
import { theme } from "../theme"
import { ColorModeScript } from "@chakra-ui/react"
/**
 * Base theme provider for myBridge UI
 * @param {*} props 
 * @returns 
 */
export const MyBridgeThemeProvider = (props) => {
    return <>
        <ColorModeScript />
        <ChakraProvider theme={theme} cssVarsRoot="body" {...props} />
    </>
}