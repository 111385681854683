import API from 'packages/mybridgev4/store/common/api';
import { APIEndPoints } from 'packages/mybridgev4/store/common/endPoint';


export const getIntegrationURL = async () => {
  try {
    const obj = {
      url: APIEndPoints.GOOGLE_INTEGRATION_CONTACT,
      method: 'POST',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getIntegrationCallBack = async ({ queryKey }) => {
  try {
    const queryString = queryKey[1];
    const obj = {
      url: APIEndPoints.GOOGLE_INTEGRATION_CALLBACK(queryString),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getCalendarIntegrationURL = async () => {
  try {
    const obj = {
      url: APIEndPoints.GOOGLE_INTEGRATION,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getMsTeamsIntegrationURL = async () => {
  try {
    const obj = {
      url: APIEndPoints.MS_TEAMS_INTEGRATION,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};