import { Drawer as Drawer_ } from "@chakra-ui/react";
import { forwardRef } from "react";
export const Drawer = forwardRef(({ ...props }, ref) => {
    return <Drawer_ ref={ref} {...props}>{props?.children}</Drawer_>
});

import { DrawerBody as DrawerBody_ } from "@chakra-ui/react";
export const DrawerBody = forwardRef(({ ...props }, ref) => {
    return <DrawerBody_ ref={ref} {...props}>{props?.children}</DrawerBody_>
});

import { DrawerCloseButton as DrawerCloseButton_ } from "@chakra-ui/react";
export const DrawerCloseButton = forwardRef(({ ...props }, ref) => {
    return <DrawerCloseButton_ ref={ref} {...props}>{props?.children}</DrawerCloseButton_>
});

import { DrawerContent as DrawerContent_ } from "@chakra-ui/react";
export const DrawerContent = forwardRef(({ ...props }, ref) => {
    return <DrawerContent_ ref={ref} {...props}>{props?.children}</DrawerContent_>
});

import { DrawerHeader as DrawerHeader_ } from "@chakra-ui/react";
export const DrawerHeader = forwardRef(({ ...props }, ref) => {
    return <DrawerHeader_ ref={ref} {...props}>{props?.children}</DrawerHeader_>
});

import { DrawerFooter as DrawerFooter_ } from "@chakra-ui/react";
export const DrawerFooter = forwardRef(({ ...props }, ref) => {
    return <DrawerFooter_ ref={ref} {...props}>{props?.children}</DrawerFooter_>
});

import { DrawerOverlay as DrawerOverlay_ } from "@chakra-ui/react";
export const DrawerOverlay = forwardRef(({ ...props }, ref) => {
    return <DrawerOverlay_ ref={ref} {...props}>{props?.children}</DrawerOverlay_>
});