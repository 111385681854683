export const AppleIcon = (props) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_11096_7986)">
<path d="M13.9679 0.890909C13.9679 1.92727 13.5197 2.95455 12.8979 3.69091C12.2215 4.50909 11.0888 5.11818 10.1824 5.11818C10.0733 5.11818 9.97331 5.1 9.90967 5.09091C9.90058 5.03636 9.87331 4.89091 9.87331 4.73636C9.87331 3.69091 10.3933 2.67273 10.9697 2.02727C11.7006 1.17273 12.9169 0.536364 13.9224 0.5C13.9497 0.618182 13.9679 0.754545 13.9679 0.890909ZM18.1179 15.1727C18.0906 15.2364 17.6969 16.6091 16.7379 18.0091C15.8788 19.2273 14.9742 20.4727 13.6197 20.4727C12.2406 20.4727 11.8924 19.6727 10.3197 19.6727C8.77604 19.6727 8.22695 20.5 6.98331 20.5C5.73149 20.5 4.86331 19.3545 3.86695 17.9545C2.69695 16.3 1.75513 13.7455 1.75513 11.3364C1.75513 7.44545 4.29785 5.38182 6.8024 5.38182C8.11876 5.38182 9.23422 6.24545 10.0751 6.24545C10.8615 6.24545 12.0951 5.32727 13.6224 5.32727C14.1797 5.32727 16.246 5.38182 17.5988 7.31818C17.4806 7.4 15.4324 8.56364 15.4324 11.1273C15.4324 14.0909 18.026 15.1455 18.1179 15.1727Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_11096_7986">
<rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>

  );
};
