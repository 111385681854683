import { HStack } from "@mybridge/ui/layout"
import { Box } from "../layout"

export const MediaSlider = ({ children, ...props }) => {
    return <HStack alignItems="stretch" overflowX="auto" maxW="100%" {...props}>
        {children}
    </HStack>
}

export const MediaSliderItem = ({ children, ...props }) => {
    return (
        <HStack
            position="relative"
            overflow="hidden"
            borderRadius="lg"
            border="1px solid"
            borderColor="gray.300"
            alignItems="center"
            justifyContent="center"
            {...props}
        >
            {children}
        </HStack>
    )
}