import { inputAnatomy, formAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(formAnatomy.keys)
  const activeLabelStyles = {
    transform: "scale(0.85) translateY(-35px)"
  };


const floating = definePartsStyle({
  container: {
    _focusWithin: {
      label: {
        ...activeLabelStyles
      }
    },
    input: {
      border: '1px solid',
      borderColor: 'brandGray.500',
      background: 'white',
      borderRadius: '5px',
      padding:'14px 12px',
      height:'60px'
    },
    "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label, .chakra-input__group + label, .chakra-input__group > input:not(:placeholder-shown) ~ label": {
      ...activeLabelStyles
    },
    label: {
      top: "7px",
      left: 0,
      zIndex: 2,
      position: "absolute",
      backgroundColor: "white",
      pointerEvents: "none",
      mx: 3,
      px: 1,
      my: 3,
      transformOrigin: "left top"
    }
  }
})





export const formTheme = defineMultiStyleConfig({ variants: { floating }, })
