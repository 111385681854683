import { createReducer } from '@reduxjs/toolkit';

import {
  acceptInviteRequest,
  approveGroupPost,
  clearDiskAccessUpdated,
  clearIsPostAccepted,
  clearTeamCreated,
  clearTeamLeft,
  clearTeamReported,
  clearTeamUpdated,
  clearUserAcceptedInvite,
  clearUserInvited,
  clearUserRequested,
  clearUsersUpdated,
  createGourpPost,
  createTeamCommunity,
  deleteTeam,
  getBlockedMemberList,
  getGroupAdminPost,
  getGroupPost,
  getInvitedMemberList,
  getRequestedMemberList,
  getTeamCommunityList,
  getTeamCommunityListByPage,
  getTeamDetails,
  getTeamDetailsBySlug,
  getTeamMemberList,
  getTeamOrganiserList,
  inviteUsersToFollowCompany,
  inviteUsersToTeam,
  joinTeamRequest,
  leaveTeamCommunity,
  removeTeamMember,
  reportTeamCommunity,
  setEditModalOpen,
  setGroupId,
  setTeamId,
  updateDiskStatus,
  updateTeamDetails,
  updateTeamDetailsImages,
  updateUserRole,
  withdrawTeamInvite,
  sendApprovedMessageToGroup,
  getTeamCommunityAllTeamsList
} from '../actions/teamCommunity.actions';

const initialState = {
  msg: '',
  loading: false,
  error: false,
  teamCommunityList: {},
  createdTeam: {},
  isTeamCreated: false,
  isTeamUpdated: false,
  isTeamLeft: false,
  isTeamReported: false,
  pages: null,
  groupId: null,
  teamId: null,
  teamPage: {},
  teamSlugPage: {},
  teamMembers: {},
  teamOrganisers: {},
  usersInvited: false,
  isPostCreated: false,
  teamPosts: {},
  isPostAccepted: false,
  pending: false,
  usersUpdated: false,
  userRequested: false,
  userWithdrawn: false,
  userAcceptedInvite: false,
  userRequestedTeams: [],
  resquestedMembers: {},
  blockedMembers: {},
  invitedMembers: {},
  teamUserPosts: {},
  errorMessage: null,
  page: 0,
  noMoreData: false,
  diskAccessUpdated: false,
  isRemovingTeamMember: false,
  editModalOpen: false,
  approvedMessage:null,
  allTeamsListForChat:null,
};

export const teamCommunityReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getTeamCommunityList.pending, (state, action) => {
      state.pending = true;
      state.error = false;
      state.loading = true;
      state.page = 0;
    })
    .addCase(getTeamCommunityList.fulfilled, (state, action) => {
      state.pending = false;
      state.loading = false;
      state.page = 0;
      if (action.payload.error || action.payload.status_code >= 400) {
        state.error = action.payload.detail;
        state.noMoreData = true;
      } else {
        state.msg = action.payload.msg;
        if (!action.payload.results || action.payload.results.length < 0) {
          state.noMoreData = true;
        } else {
          state.teamCommunityList = action.payload?.results ?? [];
          state.noMoreData = false;
          state.page = 1;
        }
      }
    })
    .addCase(getTeamCommunityList.rejected, (state, action) => {
      state.loading = false;
    })

    .addCase(getTeamCommunityListByPage.pending, (state, action) => {
      state.pending = true;
      state.error = false;
      state.loading = true;
    })
    .addCase(getTeamCommunityListByPage.fulfilled, (state, action) => {
      state.pending = false;
      state.loading = false;
      if (action.payload.error || action.payload.status_code >= 400) {
        state.error = action.payload.detail;
        state.noMoreData = true;
      } else {
        state.msg = action.payload.msg;
        if (!action.payload.results || action.payload.results.length < 0) {
          state.noMoreData = true;
        } else {
          state.teamCommunityList = [
            ...(state.teamCommunityList ?? []),
            ...(action.payload.results ?? []),
          ];
          state.noMoreData = false;
          state.page = state.page + 1;
        }
      }
    })
    .addCase(getTeamCommunityListByPage.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(getTeamCommunityAllTeamsList.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getTeamCommunityAllTeamsList.fulfilled, (state, action) => {
      state.loading = false;
      state.allTeamsListForChat = action.payload.results;
    })
    .addCase(getTeamCommunityAllTeamsList.rejected, (state, action) => {
      state.loading = false;
    })

    .addCase(createTeamCommunity.pending, (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
      state.isTeamCreated = false;
    })
    .addCase(createTeamCommunity.fulfilled, (state, action) => {
      state.loading = false;
      state.pending = false;
      if (action.payload.id) {
        state.msg = 'Team Created succesfully!';
        state.message = undefined;
        state.error = false;
        state.createdTeam = action.payload;
        state.isTeamCreated = true;
      } else {
        state.error = true;
        state.message = action.payload.slug?.[0] ?? action.payload.message;
      }
    })
    .addCase(createTeamCommunity.rejected, (state, action) => {
      state.loading = false;
      state.loading = false;
      state.error = true;
    })

    .addCase(createGourpPost.pending, (state, action) => {
      state.pending = true;
      state.error = false;
      state.isTeamUpdated = false;
    })
    .addCase(createGourpPost.fulfilled, (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.isPostCreated = true;
      }
    })
    .addCase(createGourpPost.rejected, (state, action) => {
      state.loading = false;
    })

    .addCase(updateTeamDetails.pending, (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
      state.isTeamUpdated = false;
    })
    .addCase(updateTeamDetails.fulfilled, (state, action) => {
      state.pending = false;
      state.loading = false;
      if (!action.payload.id) {
        state.error = action.payload.error;
        state.message = action.payload.message;
      } else {
        state.msg = action.payload.msg;
        state.isTeamUpdated = true;
      }
    })
    .addCase(updateTeamDetails.rejected, (state, action) => {
      state.loading = false;
    })

    .addCase(updateTeamDetailsImages.pending, (state, action) => {
      state.pending = true;
      state.error = false;
      state.isTeamUpdated = false;
    })
    .addCase(updateTeamDetailsImages.fulfilled, (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.isTeamUpdated = true;
      }
    })
    .addCase(updateTeamDetailsImages.rejected, (state, action) => {
      state.loading = false;
    })

    /* delete team */

    .addCase(deleteTeam.pending, (state, action) => {
      state.pending = true;
      state.error = false;
      state.isTeamUpdated = false;
    })
    .addCase(deleteTeam.fulfilled, (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.isTeamUpdated = true;
      }
    })
    .addCase(deleteTeam.rejected, (state, action) => {
      state.loading = false;
    })

    .addCase(leaveTeamCommunity.pending, (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
      state.isTeamLeft = false;
    })
    .addCase(leaveTeamCommunity.fulfilled, (state, action) => {
      state.pending = false;
      state.loading = false;
      if (!action.payload.message?.toLowerCase?.()?.includes?.('user left')) {
        state.error = true;
        state.message = action.payload.message;
      } else {
        state.msg = action.payload.message;
        state.isTeamLeft = true;
      }
    })
    .addCase(leaveTeamCommunity.rejected, (state, action) => {
      state.loading = false;
    })

    .addCase(reportTeamCommunity.pending, (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
      state.isTeamReported = false;
    })
    .addCase(reportTeamCommunity.fulfilled, (state, action) => {
      state.pending = false;
      state.loading = false;
      if (!action.payload.message.includes('reported')) {
        state.error = true;
        state.message = action.payload.message;
      } else {
        state.msg = action.payload.message;
        state.isTeamReported = true;
      }
    })
    .addCase(reportTeamCommunity.rejected, (state, action) => {
      state.loading = false;
    })

    .addCase(approveGroupPost.pending, (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
      state.isPostAccepted = false;
    })
    .addCase(approveGroupPost.fulfilled, (state, action) => {
      state.loading = false;
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.isPostAccepted = true;
      }
    })
    .addCase(approveGroupPost.rejected, (state, action) => {
      state.loading = false;
    })

    /* Team Details */
    .addCase(getTeamDetails.pending, (state, action) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(getTeamDetails.fulfilled, (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.teamPage = action.payload;
      }
    })

    .addCase(getTeamDetails.rejected, (state, action) => {
      state.loading = false;
    })

    .addCase(getTeamDetailsBySlug.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    })
    .addCase(getTeamDetailsBySlug.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.teamSlugPage = action.payload?.[0];
        state.teamId = action.payload?.[0]?.id;
        state.loading = false;
      }
    })
    .addCase(getTeamDetailsBySlug.rejected, (state, action) => {
      state.loading = false;
    })

    .addCase(getTeamMemberList.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    })
    .addCase(getTeamMemberList.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.teamMembers = action.payload;
        state.pending = false;
        state.teamMembers = action.payload;

      }
    })
    .addCase(getTeamMemberList.rejected, (state, action) => {
      state.loading = false;
    })

    // Getting Invited members in team
    .addCase(getInvitedMemberList.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    })
    .addCase(getInvitedMemberList.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.pending = false;
        state.invitedMembers = action.payload;
      }
    })
    .addCase(getInvitedMemberList.rejected, (state, action) => {
      state.loading = false;
    })

    // Getting Requested members in team
    .addCase(getRequestedMemberList.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    })
    .addCase(getRequestedMemberList.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.pending = false;
        state.resquestedMembers = action.payload;
      }
    })
    .addCase(getRequestedMemberList.rejected, (state, action) => {
      state.loading = false;
    })

    // Getting Blocked members in team
    .addCase(getBlockedMemberList.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    })
    .addCase(getBlockedMemberList.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.pending = false;
        state.blockedMembers = action.payload;
      }
    })
    .addCase(getBlockedMemberList.rejected, (state, action) => {
      state.loading = false;
    })

    .addCase(getTeamOrganiserList.pending, (state, action) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(getTeamOrganiserList.fulfilled, (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.teamOrganisers = action.payload;
        state.pending = false;
      }
    })
    .addCase(getTeamOrganiserList.rejected, (state, action) => {
      state.pending = false;
    })

    .addCase(getGroupPost.pending, (state, action) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(getGroupPost.fulfilled, (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.teamPosts = action.payload;
      }
    })
    .addCase(getGroupPost.rejected, (state, action) => {
      state.loading = false;
    })

    .addCase(getGroupAdminPost.pending, (state, action) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(getGroupAdminPost.fulfilled, (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.teamPosts = action.payload;
      }
    })
    .addCase(getGroupAdminPost.rejected, (state, action) => {
      state.loading = false;
    })

    .addCase(inviteUsersToTeam.pending, (state, action) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(inviteUsersToTeam.fulfilled, (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.usersInvited = true;
      }
    })
    .addCase(inviteUsersToTeam.rejected, (state, action) => {
      state.loading = false;
    })

    .addCase(inviteUsersToFollowCompany.pending, (state, action) => {
      state.pending = true;
      state.error = false;
    })
    .addCase(inviteUsersToFollowCompany.fulfilled, (state, action) => {
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.usersInvited = true;
      }
    })
    .addCase(inviteUsersToFollowCompany.rejected, (state, action) => {
      state.loading = false;
    })

    .addCase(joinTeamRequest.pending, (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
      state.userRequested = false;
    })
    .addCase(joinTeamRequest.fulfilled, (state, action) => {
      state.pending = false;
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.userRequested = true;
      }
    })
    .addCase(joinTeamRequest.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(withdrawTeamInvite.pending, (state, action) => {
      state.pending = true;
      state.loading = true;
      state.userWithdrawn = false;
      state.error = false;
    })
    .addCase(withdrawTeamInvite.fulfilled, (state, action) => {
      state.pending = false;
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.userWithdrawn = true;
      }
    })
    .addCase(withdrawTeamInvite.rejected, (state, action) => {
      state.loading = false;
    })

    .addCase(acceptInviteRequest.pending, (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
    })
    .addCase(acceptInviteRequest.fulfilled, (state, action) => {
      state.pending = false;
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.userAcceptedInvite = true;
      }
    })
    .addCase(acceptInviteRequest.rejected, (state, action) => {
      state.loading = false;
    })

    .addCase(updateUserRole.pending, (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
      state.usersUpdated = false;
    })
    .addCase(updateUserRole.fulfilled, (state, action) => {
      state.loading = false;
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.usersUpdated = true;
      }
    })
    .addCase(updateUserRole.rejected, (state, action) => {
      state.loading = false;
    })

    .addCase(updateDiskStatus.pending, (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
      state.diskAccessUpdated = false;
    })
    .addCase(updateDiskStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.diskAccessUpdated = true;
      }
    })
    .addCase(updateDiskStatus.rejected, (state, action) => {
      state.loading = false;
    })

    .addCase(removeTeamMember.pending, (state, action) => {
      state.pending = true;
      state.isRemovingTeamMember = true;
      state.error = false;
      state.usersUpdated = false;
    })
    .addCase(removeTeamMember.fulfilled, (state, action) => {
      state.isRemovingTeamMember = false;
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.usersUpdated = true;
      }
    })
    .addCase(removeTeamMember.rejected, (state, action) => {
      state.isRemovingTeamMember = false;
    })

    .addCase(sendApprovedMessageToGroup.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(sendApprovedMessageToGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.approvedMessage = action.payload;
    })

    .addCase(setTeamId, (state, action) => {
      state.teamId = action.payload;
    })
    .addCase(setEditModalOpen, (state, action) => {
      state.editModalOpen = action.payload;
    })
    .addCase(setGroupId, (state, action) => {
      state.groupId = action.payload;
    })
    .addCase(clearTeamUpdated, (state, action) => {
      state.isTeamUpdated = false;
    })
    .addCase(clearTeamCreated, (state, action) => {
      state.isTeamCreated = false;
    })
    .addCase(clearTeamLeft, (state, action) => {
      state.isTeamLeft = false;
      state.error = false;
      state.message = undefined;
      state.msg = undefined;
    })
    .addCase(clearTeamReported, (state, action) => {
      state.error = false;
      state.message = undefined;
      state.msg = undefined;
      state.isTeamReported = false;
    })
    .addCase(clearUserInvited, (state, action) => {
      state.error = false;
      state.message = undefined;
      state.msg = undefined;
      state.usersInvited = false;
    })
    .addCase(clearUserRequested, (state, action) => {
      state.error = false;
      state.message = undefined;
      state.msg = undefined;
      state.userRequested = false;
    })
    .addCase(clearUserAcceptedInvite, (state, action) => {
      state.error = false;
      state.message = undefined;
      state.msg = undefined;
      state.userAcceptedInvite = false;
    })
    .addCase(clearUsersUpdated, (state, action) => {
      state.error = false;
      state.message = undefined;
      state.msg = undefined;
      state.usersUpdated = false;
    })
    .addCase(clearDiskAccessUpdated, (state, action) => {
      state.error = false;
      state.message = undefined;
      state.msg = undefined;
      state.diskAccessUpdated = false;
    })
    .addCase(clearIsPostAccepted, (state, action) => {
      state.error = false;
      state.message = undefined;
      state.msg = undefined;
      state.isPostAccepted = false;
    });
});
