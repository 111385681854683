export const monthOptions = [
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

export const genralMonths = [
  { label: 'January', value: '1' },
  { label: 'February', value: '2' },
  { label: 'March', value: '3' },
  { label: 'April', value: '4' },
  { label: 'May', value: '5' },
  { label: 'June', value: '6' },
  { label: 'July', value: '7' },
  { label: 'August', value: '8' },
  { label: 'September', value: '9' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

export const monthToNameMapping = {
  1: 'January',
  '01': 'January',
  2: 'February',
  '02': 'February',
  3: 'March',
  '03': 'March',
  4: 'April',
  '04': 'April',
  5: 'May',
  '05': 'May',
  6: 'June',
  '06': 'June',
  7: 'July',
  '07': 'July',
  8: 'August',
  '08': 'August',
  9: 'September',
  '09': 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const getDayOptionsInMonth = (rawMonth, rawYear) => {
  const month = rawMonth || new Date().getMonth();
  const year = rawYear || new Date().getFullYear();
  return [...Array(new Date(year, month, 0).getDate())].map((_, i) => ({
    label: i + 1,
    value: i + 1,
  }));
};

export const generalCauses = [
  'Animal Welfare',
  'Civil Rights and Social Action',
  'Economic Empowerment',
  'Environment',
  'Human Rights',
  'Poverty Alleviation',
  'Social Services',
  'Children',
  'Disaster and Humanitarian Relief',
  'Education',
  'Health',
  'Politics',
  'Science and Technology',
  'Others',
];

export const employmentChoices = [
  { label: 'Full-Time', value: 'FT' },
  { label: 'Part-Time', value: 'PT' },
  { label: 'Contractor', value: 'C' },
  { label: 'Temporary', value: 'T' },
  { label: 'Other', value: 'O' },
];

export const CelebratingIconMap = [
  {
    name: 'work Anniversary',
    iconPath: '/icons/Celebrating/work Anniversary.svg',
  },
  {
    name: 'milestone',
    iconPath: '/icons/Celebrating/milestone.svg',
  },
  {
    name: 'victory',
    iconPath: '/icons/Celebrating/victory.svg',
  },
  {
    name: 'holiday',
    iconPath: '/icons/Celebrating/holiday.svg',
  },
  {
    name: 'something',
    iconPath: '/icons/Celebrating/something.svg',
  },
  {
    name: 'my new job',
    iconPath: '/icons/Celebrating/my new job.svg',
  },
  {
    name: 'international Women\'s day',
    iconPath: '/icons/Celebrating/international Women_s day.svg',
  },
];
export const AttendingIconMap = [
  {
    name: 'an Event',
    iconPath: '/icons/Attending/an Event.svg',
  },
  {
    name: 'work',
    iconPath: '/icons/Attending/work.svg',
  },
  {
    name: 'a meeting',
    iconPath: '/icons/Attending/a meeting.svg',
  },
  {
    name: 'a game',
    iconPath: '/icons/Attending/a game.svg',
  },
  {
    name: 'a seminar',
    iconPath: '/icons/Attending/a seminar.svg',
  },
  {
    name: 'a party',
    iconPath: '/icons/Attending/a party.svg',
  },
  {
    name: 'a conference',
    iconPath: '/icons/Attending/a conference.svg',
  },
];
export const FeelingsIconMap = [
  {
    name: 'happy',
    iconPath: '/icons/Feelings/happy.svg',
  },
  {
    name: 'sad',
    iconPath: '/icons/Feelings/sad.svg',
  },
  {
    name: 'thankful',
    iconPath: '/icons/Feelings/thankful.svg',
  },
  {
    name: 'excited',
    iconPath: '/icons/Feelings/excited.svg',
  },
  {
    name: 'greatful',
    iconPath: '/icons/Feelings/greatful.svg',
  },
  {
    name: 'wonderful',
    iconPath: '/icons/Feelings/wonderful.svg',
  },
  {
    name: 'positive',
    iconPath: '/icons/Feelings/positive.svg',
  },
  {
    name: 'hopeful',
    iconPath: '/icons/Feelings/hopeful.svg',
  },
  {
    name: 'motivated',
    iconPath: '/icons/Feelings/motivated.svg',
  },
  {
    name: 'proud',
    iconPath: '/icons/Feelings/proud.svg',
  },
  {
    name: 'angry',
    iconPath: '/icons/Feelings/angry.svg',
  },
  {
    name: 'delighted',
    iconPath: '/icons/Feelings/delighted.svg',
  },
  {
    name: 'drained',
    iconPath: '/icons/Feelings/drained.svg',
  },
  {
    name: 'confident',
    iconPath: '/icons/Feelings/confident.svg',
  },
  {
    name: 'determined',
    iconPath: '/icons/Feelings/determined.svg',
  },
  {
    name: 'exhausted',
    iconPath: '/icons/Feelings/exhausted.svg',
  },
  {
    name: 'annoyed',
    iconPath: '/icons/Feelings/annoyed.svg',
  },
  {
    name: 'lucky',
    iconPath: '/icons/Feelings/lucky.svg',
  },
  {
    name: 'bored',
    iconPath: '/icons/Feelings/bored.svg',
  },
  {
    name: 'professional',
    iconPath: '/icons/Feelings/professional.svg',
  },
  {
    name: 'disappointed',
    iconPath: '/icons/Feelings/disappointed.svg',
  },
  {
    name: 'worried',
    iconPath: '/icons/Feelings/worried.svg',
  },
  {
    name: 'inspired',
    iconPath: '/icons/Feelings/inspired.svg',
  },
  {
    name: 'concerned',
    iconPath: '/icons/Feelings/concerned.svg',
  },
  {
    name: 'safe',
    iconPath: '/icons/Feelings/safe.svg',
  },
  {
    name: 'afraid',
    iconPath: '/icons/Feelings/afraid.svg',
  },
  {
    name: 'smart',
    iconPath: '/icons/Feelings/smart.svg',
  },
];
