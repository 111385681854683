// theme.ts (Version 2 needs to be a tsx file, due to usage of StyleFunctions)
import { extendTheme } from '@chakra-ui/react';
import { avatarTheme } from '../avatar/avatar-style';
import { buttonTheme } from '../button/button-style';
import { cardTheme } from '../card/card-style';
import { closeButtonTheme } from '../close-button/close-button-style';
import { dividerTheme } from '../divider/dividerStyle';
import { inputTheme, numberInputTheme } from '../input/input-style';
import { linkTheme } from '../link/link-style';
import { selectTheme } from '../select/select-style';
import { headingTheme } from '../heading/heading-style';
import { tagTheme } from '../tag/tag-style';
import { modalTheme } from '../modal/modalStyles';
import { textareaTheme } from '../text-area/text-area-style';
import { tooltipTheme } from '../tooltip/tooltip-style';
import { fileUploadTheme } from '../file-upload/file-input';
import { switchTheme } from '../switch/switch';
import { tabsTheme } from '../tabs/tabs';
import { checkboxTheme } from '../checkbox/checkbox';
import { radioTheme } from '../radio/radio';
import { stepperTheme } from '../stepper/stepper';
import { formTheme } from '../form-control/form-style';
// Version 1: Using objects

export const theme = extendTheme({
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  //TODO @ ketan: add comments specifing unit sizes for font sizes below
  fontSizes: {
    xs: '0.75rem', //12px from figma
    sm: '0.875rem', //14px from figma
    md: '1rem', //16px from figma
    lg: '1.125rem', //18px
    xl: '1.25rem', //20px
    '2xl': '1.5rem', //24px
    '3xl': '1.875rem', //30px
    '4xl': '2.25rem', //36
    '5xl': '3rem', //48
    '6xl': '3.75rem', //60px
    '7xl': '4.5rem', //72
    '8xl': '6rem', //96
    '9xl': '8rem', //128
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  colors: {
    brandGray: {
      1000: '#4F4F4F',
      900: '#003049',
      850: '#747474',
      800: '#3D5A80',
      750: '#2E2E2A',
      700: '#5B5B5B', //--dark-gray
      600: '#9F9F9F',
      500: '#E4E6EB',
      400: '#F6F6F6',
      300: '#d9d9d9',
      350: '#E7F3FF',
      200: '#F8F8F8',
      150: '#F1F1F1',
      100: '#EFEFEF',
      50: '#EEEEEE',
      40: '#FAFAFA',
      30: '#868585',
      20: '#EFEFEF',
      10: '#B1B1B1',
      9: '#222222',
      5: '#B8ADAD',
      4: '#666666',
    },
    brandPrimary: {
      100: '#eceff2',
      200: '#b1bdcc',
      300: '#8b9cb3',
      400: '#647b99',
      500: '#3D5A80',
      600: '#375173',
      700: '#314866',
      800: '#2b3f5a',
      900: '#003049',
    },
    black: '#000000',
    white: '#ffffff',
    brandGreen: {
      500: '#00A84B',
      900: '#037F29',
    },
    brandRed: {
      500: '#FF0600',
      900: '#E41C19',
    },
  },
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: '#F6F6F6',
        color: 'brandGray.700',
        minHeight: '100vh',
        padding: '0px',
        margin: '0px',
        fontSize: 'md',
      },
      // styles for the `a`
      a: {
        color: 'brandPrimary.500',
        _hover: {
          color: 'brandPrimary.900',
          textDecoration: 'none !important',
        },
      },
    },
  },

  components: {
    Button: buttonTheme,
    Card: cardTheme,
    Input: inputTheme,
    Modal: modalTheme,
    Divider: dividerTheme,
    Textarea: textareaTheme,
    CloseButton: closeButtonTheme,
    Tooltip: tooltipTheme,
    Avatar: avatarTheme,
    Link: linkTheme,
    FileUpload: fileUploadTheme,
    Switch: switchTheme,
    Checkbox: checkboxTheme,
    Radio: radioTheme,
    Heading: headingTheme,
    NumberInput: numberInputTheme,
    Select: selectTheme,
    Tag: tagTheme,
    Tabs: tabsTheme,
    Stepper: stepperTheme,
    Form: formTheme,
  },
});
