import { GiphyFetch } from '@giphy/js-fetch-api';
import { Grid } from '@giphy/react-components';
import { useEffect, useState } from 'react';

function Giphy({ searchQuery, searched, onSelect, ...props }) {
  const [fetched, setFetched] = useState(false);
  const giphyFetch = new GiphyFetch('EZ2HTEFqs0ZAA6gCPEUHwmWG21LI7RXG');
  const width = 280;

  const fetchGif = () => {
    searched
      ? searchGifs().then((res) => {
          setFetched(true);
        })
      : trendingGifs().then((res) => {
          setFetched(true);
        });
  };

  function trendingGifs() {
    return giphyFetch.trending({ limit: 16 });
  }

  function searchGifs() {
    return giphyFetch.search(searchQuery, { limit: 16 });
  }

  useEffect(() => {
    setFetched(false);
    fetchGif();
  }, [searched, searchQuery]);

  return (
    fetched && (
      <Grid
        hideAttribution={true}
        noLink={true}
        noResultsMessage="No Data Found!"
        fetchGifs={searched ? searchGifs : trendingGifs}
        width={width}
        borderRadius={10}
        onGifClick={onSelect}
        columns={4}
        gutter={5}
        {...props}
      />
    )
  );
}

export default Giphy;
