export const FollowersIcon = (props) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      {...(props ?? {})}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1087_123519)">
        <path
          d="M12.2259 4.47322C13.1506 5.05303 13.7991 6.03418 13.9171 7.17308C14.3049 7.3555 14.7281 7.45026 15.1566 7.45063C16.7767 7.45063 18.0898 6.13751 18.0898 4.51765C18.0898 2.89753 16.7767 1.58441 15.1566 1.58441C13.552 1.58491 12.2503 2.87457 12.2259 4.47322ZM10.3042 10.479C11.9244 10.479 13.2375 9.16559 13.2375 7.54572C13.2375 5.92586 11.9241 4.61274 10.3042 4.61274C8.68438 4.61274 7.37052 5.92611 7.37052 7.54597C7.37052 9.16584 8.68438 10.479 10.3042 10.479ZM11.5485 10.6789H9.05952C6.98864 10.6789 5.30387 12.3639 5.30387 14.4348V17.4786L5.31161 17.5263L5.52127 17.5919C7.49756 18.2094 9.21452 18.4153 10.6277 18.4153C13.388 18.4153 14.9879 17.6283 15.0865 17.5782L15.2824 17.4791H15.3034V14.4348C15.3041 12.3639 13.6194 10.6789 11.5485 10.6789ZM16.4013 7.6508H13.9316C13.9065 8.60444 13.5055 9.5095 12.8159 10.1687C14.6567 10.7161 16.0035 12.423 16.0035 14.4393V15.3772C18.442 15.2879 19.8472 14.5968 19.9398 14.5503L20.1358 14.451H20.1567V11.4062C20.1567 9.33557 18.472 7.6508 16.4013 7.6508ZM5.15736 7.45113C5.73118 7.45113 6.26506 7.28365 6.71733 6.99836C6.85923 6.08047 7.34758 5.25189 8.08186 4.68313C8.08486 4.62822 8.0901 4.5738 8.0901 4.51839C8.0901 2.89828 6.77673 1.58516 5.15736 1.58516C3.537 1.58516 2.22413 2.89828 2.22413 4.51839C2.22413 6.13776 3.537 7.45113 5.15736 7.45113ZM7.79158 10.1687C7.10585 9.51265 6.70542 8.61337 6.67664 7.66478C6.58504 7.65804 6.49444 7.6508 6.40109 7.6508H3.91239C1.8415 7.6508 0.156738 9.33557 0.156738 11.4062V14.4505L0.164476 14.4974L0.374135 14.5636C1.95956 15.0585 3.37451 15.2866 4.60351 15.3585V14.4393C4.60401 12.423 5.95032 10.7166 7.79158 10.1687Z"
          fill={props?.color ?? '#5B5B5B'}
        />
      </g>
    </svg>
  );
};

export const UnfollowersIcon = (props) => {
  return (
    <svg
      width="57"
      height="33"
      viewBox="0 0 57 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.320312" width="56" height="32" rx="4" fill="#E4E6EB" />
      <path
        d="M28.5464 7.32035C31.2251 7.30929 33.4112 9.47816 33.4223 12.1671C33.4333 14.8395 31.2528 17.0305 28.5686 17.036C25.8788 17.0415 23.6983 14.8727 23.6983 12.1892C23.6927 9.50582 25.8567 7.33142 28.5464 7.32035Z"
        fill="#5B5B5B"
      />
      <path
        d="M29.7588 25.4414C29.7146 25.4414 29.6592 25.4414 29.6094 25.4414C26.7481 25.4414 23.8923 25.4414 21.031 25.4414C20.2728 25.4414 19.6751 24.96 19.5256 24.2408C19.498 24.0859 19.498 23.9254 19.5035 23.7705C19.509 23.2615 19.4758 22.7414 19.5422 22.2434C19.6695 21.2696 20.2507 20.5559 20.9867 19.9584C21.9221 19.2004 23.0123 18.7411 24.1635 18.4036C25.9124 17.8946 27.7 17.7508 29.5098 17.8282C29.6205 17.8337 29.7256 17.8503 29.8308 17.8559C27.8439 20.3678 27.8218 22.8852 29.7588 25.4414Z"
        fill="#5B5B5B"
      />
      <path
        d="M38.4986 21.6886C38.4931 24.2559 36.4232 26.3196 33.8607 26.3196C31.2927 26.3196 29.2063 24.2282 29.2173 21.6665C29.2339 19.1103 31.3204 17.03 33.8718 17.0411C36.4342 17.0466 38.5097 19.1325 38.4986 21.6886ZM33.3516 21.921C33.3184 21.91 33.3128 21.91 33.3073 21.91C33.0416 21.7329 32.7815 21.5614 32.5159 21.3843C32.1617 21.152 31.7576 21.2183 31.5473 21.5337C31.3315 21.8546 31.4256 22.2475 31.7798 22.4854C32.1672 22.7454 32.5601 23.0055 32.9475 23.2655C33.3571 23.5366 33.6338 23.5145 33.977 23.1659C34.5802 22.5628 35.189 21.9542 35.7922 21.3511C35.9085 21.2349 36.0358 21.1243 36.1409 20.997C36.318 20.7813 36.3512 20.5323 36.2239 20.2833C36.0966 20.0343 35.8808 19.8849 35.6041 19.9347C35.4325 19.9624 35.2443 20.0509 35.1226 20.1726C34.5193 20.7425 33.9437 21.3345 33.3516 21.921Z"
        fill="#5B5B5B"
      />
    </svg>
  );
};
