import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../common/api';
import { APIEndPoints } from '../common/endPoint';

export const createSupportTicket = createAsyncThunk('createSupportTicket', async (body) => {
    const obj = {
        url: `${APIEndPoints.Support}`,
        method: 'POST',
        body: body,
        isNoToken: true,
        isFormData:true
    }
    return await API(obj)
})

