export default async function API({
  url,
  method = 'GET',
  body = '',
  isFormData = false,
  isNoToken = false,
  headers,
  isResponseJSON = true,
  external = false,
  caching = false
}) {
  let options = {
    method,
    headers: {
      Accept: 'application/json',
    },
  };
  if (!isNoToken) {
    options.headers['Authorization'] =
      'Bearer ' + localStorage.getItem('access');
  }
  if (body) {
    options.body = body;
  }
  if (!isFormData) {
    options.headers['content-type'] = 'application/json; charset=UTF-8';
  }
  if (headers) {
    options.headers['content-type'] =
      'multipart/form-data; boundary=63c5979328c44e2c869349443a94200e';
  }
  if (caching) {
    options.headers['Cache-Control'] =
    "public, max-age=86400";
  }

  const res = await fetch(
    `${external ? '' : process.env.BASE_API_URL}${url}`,
    options
  );
  if (isResponseJSON) {
    return await res.json();
  }
  return res;
}
