import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../common/api';
import { APIEndPoints, UserAPIEndpoints } from '../common/endPoint';

export const clear = createAction('clear');
export const setAllDegreeConnections = createAction('setAllDegreeConnections');

export const getConnectionsData = createAsyncThunk(
  'getConnectionsData',
  async (opts) => {
    const { page, forUserId } = opts ?? {};
    const obj = {
      url: `${APIEndPoints.Connections}${
        forUserId ? '/' + forUserId : ''
      }?page=${page ? page : 1}&page_size=${
        opts?.pageSize ? opts?.pageSize : 15
      }`,
    };
    return await API(obj);
  }
);

export const getAllConnectionsData = createAsyncThunk(
  'getAllConnectionsData',
  async (page) => {
    const obj1 = {
      url: `${UserAPIEndpoints.GetDegreeCount(1)}`,
    };
    const obj2 = {
      url: `${UserAPIEndpoints.GetDegreeCount(2)}`,
    };
    const obj3 = {
      url: `${UserAPIEndpoints.GetDegreeCount(3)}`,
    };
    return await Promise.all([API(obj1), API(obj2), API(obj3)]);
  }
);


export const getTagUsersConnectionsData = createAsyncThunk(
  'getTagUsersConnectionsData',
  async (page) => {
    const obj1 = {
      url: `${UserAPIEndpoints.GetDegreeCount(1)}&only_mentions_allowed=true`,
    };
    // const obj2 = {
    //   url: `${UserAPIEndpoints.GetDegreeCount(2)}`,
    // };
    // const obj3 = {
    //   url: `${UserAPIEndpoints.GetDegreeCount(3)}`,
    // };
    return await API(obj1);
  }
);

export const getUserConnectionsData = createAsyncThunk(
  'getUserConnectionsData',
  async (id) => {
    const obj = {
      url: `${APIEndPoints.Connections}/${id}`,
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const getFollowers = createAsyncThunk('getFollowers', async (body) => {
  const obj = {
    url: APIEndPoints.Follow(body),
  };
  return await API(obj);
});

export const getInvitations = createAsyncThunk(
  'getInvitations',
  async (body) => {
    const obj = {
      url: APIEndPoints.Invitations(body),
    };
    return await API(obj);
  }
);

export const getFollowingPages = createAsyncThunk(
  'getFollowingPages',
  async (body) => {
    const obj = {
      url: APIEndPoints.FollowingPages(body),
    };
    return await API(obj);
  }
);

export const getMutualConnections = createAsyncThunk(
  'getMutualConnections',
  async (body) => {
    const obj = {
      url: APIEndPoints.MutualConnections(body),
    };
    return await API(obj);
  }
);

export const getConnectionsByUserId = createAsyncThunk(
  'getConnectionsByUserId',
  async (userId) => {
    const obj = {
      url: APIEndPoints.ConnectionsByUserId(userId),
      // isNoToken: true,
    };
    return await API(obj);
  }
);

export const getLimitedDegreeConnectionsByUserId = createAsyncThunk(
  'getLimitedDegreeConnectionsByUserId',
  async (body) => {
    const obj = {
      url: APIEndPoints.DegreeConnectionsByUserIdLimited(body),
      // isNoToken: true,
    };
    return await API(obj);
  }
);

export const getDegreeConnectionsSelfUser = createAsyncThunk(
  'getDegreeConnectionsSelfUser',
  async (body) => {
    const obj = {
      url: APIEndPoints.DegreeConnectionsSelfUser(body),
      // isNoToken: true,
    };
    return await API(obj);
  }
);

export const getDegreeConnectionsByUserId = createAsyncThunk(
  'getDegreeConnectionsByUserId',
  async (body) => {
    const obj = {
      url: APIEndPoints.DegreeConnectionsByUserId(body),
      // isNoToken: true,
    };
    return await API(obj);
  }
);

export const getSuggestions = createAsyncThunk(
  'getSuggestions',
  async (body) => {
    const obj = {
      url: APIEndPoints.Suggestions(body),
    };
    return await API(obj);
  }
);

export const getPublicSuggestions = createAsyncThunk(
  'getSuggestions',
  async (body) => {
    const obj = {
      url: APIEndPoints.SuggestionsPublic(body),
    };
    return await API(obj);
  }
);

export const getSharedConnectionsData = createAsyncThunk(
  'getSharedConnectionsData',
  async (body) => {
    const obj = {
      url: APIEndPoints.SharedConnections(body.id),
    };
    return await API(obj);
  }
);

export const blockConnection = createAsyncThunk(
  'blockConnection',
  async (body) => {
    const obj = {
      url: APIEndPoints.BlockConnection,
      method: 'DELETE',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const unblockConnection = createAsyncThunk(
  'unblockConnection',
  async (body) => {
    const obj = {
      url: APIEndPoints.UnblockConnection,
      method: 'DELETE',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const reportConnection = createAsyncThunk(
  'reportConnection',
  async (body) => {
    const obj = {
      url: APIEndPoints.ReportConnection,
      method: 'DELETE',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const requestConnection = createAsyncThunk(
  'requestConnection',
  async (body) => {
    const obj = {
      url: APIEndPoints.RequestConnection,
      method: 'POST',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const withdrawConnection = createAsyncThunk(
  'withdrawConnection',
  async (body) => {
    const obj = {
      url: APIEndPoints.RequestConnection,
      method: 'DELETE',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const unfriendConnection = createAsyncThunk(
  'withdrawConnection',
  async (body) => {
    const obj = {
      url: APIEndPoints.UnfriendConnection(body),
      method: 'DELETE',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const remindConnectionRequest = createAsyncThunk(
  'remindConnectionRequest',
  async (body) => {
    const obj = {
      url: APIEndPoints.RemindConnectionRequest(body),
      method: 'PUT',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const requestFollow = createAsyncThunk('requestFollow', async (body) => {
  const obj = {
    url: APIEndPoints.Follower,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const unfollow = createAsyncThunk('unfollow', async (body) => {
  const obj = {
    url: APIEndPoints.Follower,
    method: 'DELETE',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const acceptRequest = createAsyncThunk('acceptRequest', async (body) => {
  const obj = {
    url: APIEndPoints.RequestConnection,
    method: 'PUT',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const rejectRequest = createAsyncThunk('rejectRequest', async (body) => {
  const obj = {
    url: APIEndPoints.RequestConnection,
    method: 'DELETE',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const getAllConnectionIDs = createAsyncThunk(
  'getAllConnectionIDs',
  async ({ page = 1, search = '' } = {}) => {
    const obj = {
      url: `${APIEndPoints.AllConnections}`,
      method: 'GET',
    };
    return await API(obj);
  }
);

export const getUserByUuid = createAsyncThunk('getUserByUuid', async (uuid) => {
  const obj = {
    url: `${APIEndPoints.UserDetailsByUuid}?uuid=${uuid}`,
    method: 'GET',
  };
  return await API(obj);
});

export const aggregatedPhotosCount = createAsyncThunk('aggregatedPhotosData', async (body) => {
  const obj = {
    url: `${APIEndPoints.getAggregatedPhotosCount(body)}`,
    method: 'GET',
  };
  return await API(obj);
});

export const getPhotosOfYou = createAsyncThunk('getPhotosOfYou', async (body) => {
  const obj = {
    url: `${APIEndPoints.getPhotosOfYou(body)}`,
    method: 'GET',
  };
  return await API(obj);
})

export const getYourPhotos = createAsyncThunk('getYourPhotos', async (body) => {
  const obj = {
    url: `${APIEndPoints.getYourPhotos(body)}`,
    method: 'GET',
  };
  return await API(obj);
})

export const getCoverPhotos = createAsyncThunk('getCoverPhotos', async (body) => {
  const obj = {
    url: `${APIEndPoints.getCoverPhotos(body)}`,
    method: 'GET',
  };
  return await API(obj);
})

export const getProfilePhotos = createAsyncThunk('getProfilePhotos', async (body) => {
  const obj = {
    url: `${APIEndPoints.getProfilePhotos(body)}`,
    method: 'GET',
  };
  return await API(obj);
})

export const getYourVideos = createAsyncThunk('getYourVideos', async (body) => {
  const obj = {
    url: `${APIEndPoints.getYourVideos(body)}`,
    method: 'GET',
  };
  return await API(obj);
})

export const getVideosOfYour = createAsyncThunk('getVideosOfYour', async (body) => {
  const obj = {
    url: `${APIEndPoints.getVideosOfYour(body)}`,
    method: 'GET',
  };
  return await API(obj);
})

export const startConversationWithUser = createAction(
  'startConversationWithUser'
);
export const setIdforMutual = createAction('setIdforMutual');
