import { createReducer } from '@reduxjs/toolkit';
import {
  acceptRequest,
  blockConnection,
  clear,
  getAllConnectionIDs,
  getAllConnectionsData,
  getConnectionsData,
  getFollowers,
  getInvitations,
  getSharedConnectionsData,
  getSuggestions,
  getUserConnectionsData,
  rejectRequest,
  reportConnection,
  requestConnection,
  requestFollow,
  setAllDegreeConnections,
  setIdforMutual,
  startConversationWithUser,
  unfollow,
  withdrawConnection,
  getFollowingPages,getTagUsersConnectionsData
} from '../actions/connection.actions';

const initialState = {
  data: [],
  loading: false,
  refetchData: false,
  mutualConnections: [],
  follow: [],
  invites: [],
  suggestions: [],
  childLoading: false,
  isCircleAvailable: false,
  block: null,
  report: null,
  requestChange: false,
  requestDeclined: false,
  requestedHandshakes: [],
  userConversationId: null,
  mutualUserId: null,
  followRequested: false,
  followRequestLoading: false,
  allConnectionIDs: [],
  allDegreeConnections: [],
  allDegreeConnectionsArr: [],
  myFollowingPages:null,
  taggedListUsers:null
};

export const connectionReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getConnectionsData.pending, (state, action) => {
      state.loading = true;
      state.isCircleAvailable = true;
    })
    .addCase(getConnectionsData.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    })
    .addCase(getConnectionsData.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
      state.isCircleAvailable = state.data.length < 1 ? false : true;
    })
    .addCase(getAllConnectionsData.pending, (state, action) => {
      state.loading = true;
      state.isCircleAvailable = true;
    })
    .addCase(getAllConnectionsData.fulfilled, (state, action) => {
      state.allDegreeConnections = action.payload
        ?.map(
          (p, pIndex) =>
            p?.results?.map?.((r) => ({ ...(r ?? {}), degree: pIndex + 1 })) ??
            []
        )
        ?.reduceRight((acc, v) => [...(acc ?? []), ...(v ?? [])], []);

      state.allDegreeConnectionsArr = action.payload?.map(
        (p) => p?.results ?? []
      );
      state.loading = false;
    })
    .addCase(getAllConnectionsData.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
      state.isCircleAvailable = state.data.length < 1 ? false : true;
    })

    .addCase(getUserConnectionsData.pending, (state, action) => {
      state.loading = true;
      state.isCircleAvailable = true;
    })
    .addCase(getUserConnectionsData.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    })
    .addCase(getUserConnectionsData.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
      state.isCircleAvailable = state.data.length < 1 ? false : true;
    })

    // Getting Followers / Following users
    .addCase(getFollowers.pending, (state, action) => {
      state.loading = true;
      state.isCircleAvailable = true;
    })
    .addCase(getFollowers.fulfilled, (state, action) => {
      state.loading = false;
      state.follow = action.payload?.results;
    })
    .addCase(getFollowers.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
      state.isCircleAvailable = state.data.length < 1 ? false : true;
    })

    // Getting Invitations / Sent / Pending
    .addCase(getInvitations.pending, (state, action) => {
      state.loading = true;
      state.isCircleAvailable = true;
    })
    .addCase(getInvitations.fulfilled, (state, action) => {
      state.loading = false;
      state.invites = action.payload?.results;
    })
    .addCase(getInvitations.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
      state.isCircleAvailable = state.data.length < 1 ? false : true;
    })

    // Getting Suggestions
    .addCase(getSuggestions.pending, (state, action) => {
      state.loading = true;
      state.isCircleAvailable = true;
    })
    .addCase(getSuggestions.fulfilled, (state, action) => {
      state.loading = false;
      state.suggestions = action.payload;
    })
    .addCase(getSuggestions.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
      state.isCircleAvailable = state.data.length < 1 ? false : true;
    })

    .addCase(getSharedConnectionsData.pending, (state, action) => {
      state.childLoading = true;
    })
    .addCase(getSharedConnectionsData.fulfilled, (state, action) => {
      state.childLoading = false;
      state.mutualConnections = action.payload;
    })
    .addCase(getSharedConnectionsData.rejected, (state, action) => {
      state.childLoading = false;
      state.error = 'Try again later or contact customer support';
    })

    .addCase(blockConnection.pending, (state, action) => {
      state.childLoading = true;
    })
    .addCase(blockConnection.fulfilled, (state, action) => {
      state.childLoading = false;
      state.block = action.payload;
      state.refetchData = true;
    })
    .addCase(blockConnection.rejected, (state, action) => {
      state.childLoading = false;
      state.error = 'Try again later or contact customer support';
    })

    .addCase(reportConnection.pending, (state, action) => {
      state.childLoading = true;
    })
    .addCase(reportConnection.fulfilled, (state, action) => {
      state.childLoading = false;
      state.report = action.payload;
      state.refetchData = true;
    })
    .addCase(reportConnection.rejected, (state, action) => {
      state.childLoading = false;
      state.error = 'Try again later or contact customer support';
    })

    // Accept connection request
    .addCase(acceptRequest.pending, (state, action) => {
      state.childLoading = true;
    })
    .addCase(acceptRequest.fulfilled, (state, action) => {
      state.childLoading = false;
      state.report = action.payload;
      state.requestChange = true;
    })
    .addCase(acceptRequest.rejected, (state, action) => {
      state.childLoading = false;
      state.error = 'Try again later or contact customer support';
    })

    // Reject connection request
    .addCase(rejectRequest.pending, (state, action) => {
      state.childLoading = true;
    })
    .addCase(rejectRequest.fulfilled, (state, action) => {
      state.childLoading = false;
      state.report = action.payload;
      state.requestDeclined = true;
    })
    .addCase(rejectRequest.rejected, (state, action) => {
      state.childLoading = false;
      state.error = 'Try again later or contact customer support';
    })

    // Send Handshake request
    .addCase(requestConnection.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(requestConnection.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.id) {
        state.requestChange = true;
      } else {
        state.error = true;
        state.message = action.payload.message;
      }
    })
    .addCase(requestConnection.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })

    // Withdraw Handshake request
    .addCase(withdrawConnection.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(withdrawConnection.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.id) {
        state.requestChange = true;
      } else {
        state.error = true;
        state.message = action.payload.message;
      }
    })
    .addCase(withdrawConnection.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })

    // Send Follow request
    .addCase(requestFollow.pending, (state, action) => {
      state.followRequestLoading = true;
    })
    .addCase(requestFollow.fulfilled, (state, action) => {
      state.followRequestLoading = false;
      state.report = action.payload;
      state.followRequested = true;
    })
    .addCase(requestFollow.rejected, (state, action) => {
      state.followRequestLoading = false;
      state.error = 'Try again later or contact customer support';
    })

    // Pages I follow
  
    .addCase(getFollowingPages.fulfilled, (state, action) => {
      state.myFollowingPages = action.payload;
      
    })
    .addCase(getFollowingPages.rejected, (state, action) => {
      state.error = 'Try again later or contact customer support';
    })

    //  Unfollow
    .addCase(unfollow.pending, (state, action) => {
      state.followRequestLoading = true;
    })
    .addCase(unfollow.fulfilled, (state, action) => {
      state.followRequestLoading = false;
      state.report = action.payload;
    })
    .addCase(unfollow.rejected, (state, action) => {
      state.followRequestLoading = false;
      state.error = 'Try again later or contact customer support';
    })

    .addCase(startConversationWithUser, (state, action) => {
      state.userConversationId = action.payload;
    })

    .addCase(getAllConnectionIDs.pending, (state, action) => {
      state.loading=true
    })
    .addCase(getAllConnectionIDs.fulfilled, (state, action) => {
      state.loading=false
      state.allConnectionIDs = action?.payload ?? [];
    })

    .addCase(getTagUsersConnectionsData.pending, (state, action) => {
      state.loading=true
    })
    .addCase(getTagUsersConnectionsData.fulfilled, (state, action) => {
      state.loading=false
      state.taggedListUsers = action?.payload?.results ?? [];
    })

    .addCase(setIdforMutual, (state, action) => {
      state.mutualUserId = action.payload;
    })
    .addCase(clear, (state, action) => {
      state.userConversationId = null;
    })
    .addCase(setAllDegreeConnections, (state, action) => {
      state.allDegreeConnections = action.payload;
    });
});
