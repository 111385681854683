import { Step as Step_ } from "@chakra-ui/react";
import { forwardRef } from "react";
export const Step = forwardRef(({ ...props }, ref) => {
    return <Step_ ref={ref} {...props}>{props?.children}</Step_>
});

import { StepDescription as StepDescription_ } from "@chakra-ui/react";
export const StepDescription = forwardRef(({ ...props }, ref) => {
    return <StepDescription_ ref={ref} {...props}>{props?.children}</StepDescription_>
});

import { StepIcon as StepIcon_ } from "@chakra-ui/react";
export const StepIcon = forwardRef(({ ...props }, ref) => {
    return <StepIcon_ ref={ref} {...props}>{props?.children}</StepIcon_>
});

import { StepIndicator as StepIndicator_ } from "@chakra-ui/react";
export const StepIndicator = forwardRef(({ ...props }, ref) => {
    return <StepIndicator_ ref={ref} {...props}>{props?.children}</StepIndicator_>
});

import { StepNumber as StepNumber_ } from "@chakra-ui/react";
export const StepNumber = forwardRef(({ ...props }, ref) => {
    return <StepNumber_ ref={ref} {...props}>{props?.children}</StepNumber_>
});

import { StepSeparator as StepSeparator_ } from "@chakra-ui/react";
export const StepSeparator = forwardRef(({ ...props }, ref) => {
    return <StepSeparator_ ref={ref} {...props}>{props?.children}</StepSeparator_>
});

import { StepStatus as StepStatus_ } from "@chakra-ui/react";
export const StepStatus = forwardRef(({ ...props }, ref) => {
    return <StepStatus_ ref={ref} {...props}>{props?.children}</StepStatus_>
});

import { StepTitle as StepTitle_ } from "@chakra-ui/react";
export const StepTitle = forwardRef(({ ...props }, ref) => {
    return <StepTitle_ ref={ref} {...props}>{props?.children}</StepTitle_>
});

import { Stepper as Stepper_ } from "@chakra-ui/react";
export const Stepper = forwardRef(({ ...props }, ref) => {
    return <Stepper_ ref={ref} {...props}>{props?.children}</Stepper_>
});

export { useSteps } from "@chakra-ui/react";
