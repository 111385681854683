import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../common/api';
import { APIEndPoints } from '../common/endPoint';

const initialState = {
  data: [],
  items: [],
  isItemsLoading: false,
  flag: false,
  locationList: [],
  searchQuery: null,
};

export const searchCall = createAsyncThunk('searchCall', async (query) => {
  const obj = {
    url: APIEndPoints.Search(query),
    method: 'GET',
  };

  return await API(obj);
});

export const searchItem = createAsyncThunk('searchItem', async (searchType) => {
  const obj = {
    url: APIEndPoints.SearchItem(searchType),
    method: 'GET',
  };
  return await API(obj);
});

export const locations = createAsyncThunk('locations', async () => {
  const obj = {
    url: APIEndPoints.locations(),
    method: 'GET',
  };

  return await API(obj);
});

export const addQuery = createAction('addQuery');

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {},
  extraReducers: {
    [addQuery]: (state, action) => {
      state.searchQuery = action.payload;
    },
    [searchCall.pending]: (state, action) => {
      state.flag = true;
    },
    [searchCall.fulfilled]: (state, action) => {
      state.flag = false;
      state.data = action.payload;
    },
    [searchCall.rejected]: (state, action) => {
      state.flag = false;
      state.error = 'Try again later or contact customer support';
    },
    [searchItem.pending]: (state, action) => {
      state.isItemsLoading = true;
    },
    [searchItem.fulfilled]: (state, action) => {
      state.isItemsLoading = false;
      state.items = action.payload;
    },
    [searchItem.rejected]: (state, action) => {
      state.isItemsLoading = false;
      state.error = 'Try again later or contact customer support';
    },

    [locations.pending]: (state, action) => {
      state.flag = true;
    },
    [locations.fulfilled]: (state, action) => {
      state.flag = false;
      state.locationList = action.payload;
    },
    [locations.rejected]: (state, action) => {
      state.flag = false;
      state.error = 'Try again later or contact customer support';
    },
  },
});

export default searchSlice.reducer;
