import { Box, Stack } from '@mybridge/ui';
import { forwardRef } from 'react';
export const DividerWithText = forwardRef(({ children, ...props }, ref) => {
  return (
    <>
      <Stack pos="relative" alignItems="center">
        <Box bg="white" pos="relative" zIndex={1}>
          {children}
        </Box>
        <Box
          top={5}
          pos="absolute"
          zIndex={0}
          border="1px solid"
          borderColor="blackAlpha.500"
          h={0}
          flex={1}
          w="100%"
          {...props}
        />
      </Stack>
    </>
  );
});
