/* eslint-disable @nx/enforce-module-boundaries */
import { Box, Stack } from '@mybridge/ui/layout';
import { useContext, useEffect, useState } from 'react';
import { MultiStepFormContext } from '../context';
import { randomId } from 'v4/lib/commons';

export const MultiStepFormStep = ({ name, children }) => {
  const [name_] = useState(name ?? randomId());
  const { active, register, unregister } = useContext(MultiStepFormContext);
  useEffect(() => {
    register(name_);
    return () => unregister(name_);
  }, []);

  return active !== name_ ? (
    <></>
  ) : (
    <Box
      opacity={active !== name_ ? 0 : 1}
      h={active !== name_ ? 0 : 'auto'}
      overflow={active !== name_ ? 'hidden' : ''}
    >
      {children}
    </Box>
  );
};
