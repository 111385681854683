import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const borderedCard = definePartsStyle({
  container: {
    backgroundColor: 'brandGray.40',
    borderWidth: '6px',
    borderColor: 'white',
  },
  body: {
    backgroundColor: 'brandGray.40',
    padding: '0',
  },
});

const lightBgCard = definePartsStyle({
  container: {
    backgroundColor: 'brandGray.100',
  },
  body: {
    padding: '0',
  },
});

const thinBorderedCard = definePartsStyle({
  container: {
    borderWidth: '1px',
    borderColor: 'brandGray.500',
    boxShadow: '0px 2px 6px 0px #00000033'

  },
  body: {
    padding: '0',
  },
});

export const cardTheme = defineMultiStyleConfig({
  variants: { borderedCard, thinBorderedCard, lightBgCard },
});
