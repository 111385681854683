import { Tag as Tag_ } from "@chakra-ui/react";
import { forwardRef } from "react";
export const Tag = forwardRef(({ ...props }, ref) => {
    return <Tag_ ref={ref} {...props}>{props?.children}</Tag_>
});

import { TagCloseButton as TagCloseButton_ } from "@chakra-ui/react";
export const TagCloseButton = forwardRef(({ ...props }, ref) => {
    return <TagCloseButton_ ref={ref} {...props}>{props?.children}</TagCloseButton_>
});

import { TagLabel as TagLabel_ } from "@chakra-ui/react";
export const TagLabel = forwardRef(({ ...props }, ref) => {
    return <TagLabel_ ref={ref} {...props}>{props?.children}</TagLabel_>
});

import { TagLeftIcon as TagLeftIcon_ } from "@chakra-ui/react";
export const TagLeftIcon = forwardRef(({ ...props }, ref) => {
    return <TagLeftIcon_ ref={ref} {...props}>{props?.children}</TagLeftIcon_>
});

import { TagRightIcon as TagRightIcon_ } from "@chakra-ui/react";
export const TagRightIcon = forwardRef(({ ...props }, ref) => {
    return <TagRightIcon_ ref={ref} {...props}>{props?.children}</TagRightIcon_>
});