import { Select as Select_ } from '@chakra-ui/react';
import { forwardRef } from 'react';
import { ArrowDownSelect, ChevronDownNew } from '@mybridge/icons';
export const Select = forwardRef(({ ...props }, ref) => {
  return (
    <Select_
      _invalid={{ borderColor: 'brandGray.100 !important' }}
      icon={<ChevronDownNew />}
      ref={ref}
      {...props}
    >
      {props?.children}
    </Select_>
  );
});
