/* eslint-disable @nx/enforce-module-boundaries */
import {
  ArticleIconColored,
  CalendarNewIcon,
  ChevronDown,
  ChevronRight,
  ChevronUp,
  DiskNewIcon,
  JobsIconColored,
  MyNetworkNewIcon,
  PageIcon,
  PageIconColored,
} from '@mybridge/icons';
import { MyEventsIcon, MyEventsIconColored } from '@mybridge/icons/MyEvents';
import { TeamsIcon, TeamsIconColored } from '@mybridge/icons/user-menu';
import { Button, Card, Link, Badge, Circle } from '@mybridge/ui';
import { Heading } from '@mybridge/ui/heading';
import { Box, HStack, Stack } from '@mybridge/ui/layout';
import { Text } from '@mybridge/ui/text';
import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAllConnectionsData } from 'v4/store/actions/connection.actions';

export const SiteShortcutLinks = ({ showCopyright, isDropDown, ...props }) => {
  const [showAll, setShowAll] = useState(true);
  const [showMore, setShowMore] = useState(false);

  const { allDegreeConnectionsArr } = useSelector((state) => state.connection);
  const { unreadMeetingCount } = useSelector((state) => state.globalData);
  const dispatch = useDispatch();

  // console.log("unreadMeetingCount = " + unreadMeetingCount);
  useEffect(() => {
    if (allDegreeConnectionsArr?.length === 0) {
      dispatch(getAllConnectionsData());
    }

  }, []);

  const connections = allDegreeConnectionsArr?.[0] ?? [];

  useEffect(() => {
    if (connections?.length) {
      if (connections?.length >= 4) {
        setShowMore(true);
        return
      }
      setShowAll(true)
    }
  }, [connections])

  const links = useMemo(
    () => [
      {
        title: 'myNetwork',
        icon: (
          <MyNetworkNewIcon
            width={isDropDown ? '18' : '24'}
            height={isDropDown ? '18' : '24'}
          />
        ),
        link: '/profile/network',
      },
      {
        title: 'myCalendar',
        icon: (
          <CalendarNewIcon
            width={isDropDown ? '18' : '24'}
            height={isDropDown ? '18' : '24'}
          />
        ),
        link: '/profile/calendar',
      },
      {
        title: 'myDisk',
        icon: (
          <DiskNewIcon
            width={isDropDown ? '18' : '24'}
            height={isDropDown ? '18' : '24'}
          />
        ),
        link: '/profile/disk',
      },
      {
        title: 'myPages',
        icon: (
          <PageIconColored
            width={isDropDown ? '18' : '24'}
            height={isDropDown ? '18' : '24'}
          />
        ),
        link: '/settings/pages',
      },
      {
        title: 'Jobs',
        icon: (
          <JobsIconColored
            width={isDropDown ? '18' : '24'}
            height={isDropDown ? '18' : '24'}
          />
        ),
        link: '/jobs',
      },
      {
        title: 'Teams',
        icon: (
          <TeamsIconColored
            width={isDropDown ? '18' : '24'}
            height={isDropDown ? '18' : '24'}
          />
        ),
        link: '/teams',
      },
      {
        title: 'Events',
        icon: (
          <MyEventsIconColored
            width={isDropDown ? '18' : '24'}
            height={isDropDown ? '18' : '24'}
          />
        ),
        link: '/myevents',
      },
      {
        title: 'Articles',
        icon: (
          <ArticleIconColored
            width={isDropDown ? '16' : '24'}
            height={isDropDown ? '16' : '24'}
          />
        ),
        link: '/articles',
      },
    ],
    []
  );
  return (
    <Stack {...props}>
      <Stack pos="relative" pb={showCopyright ? 4 : 0}>
        {!isDropDown && (
          <HStack zIndex={1} top="0" p={3} pb={0}>
            {/* <Box borderRadius="full" bg="white" p={2}>
            <ShortcutsIll />
          </Box> */}
            <Heading fontSize="md" fontWeight="400" color="brandPrimary.500">
              Your Shortcuts
            </Heading>
          </HStack>
        )}

        <Stack p={isDropDown ? 0 : 3} spacing={0}>
          {links
            ?.filter?.((_, ind) => showAll || isDropDown || ind < 3)
            ?.map?.((li, liIndex) => (
              <HStack
                as={Link}
                href={li.link}
                _hover={{
                  bg: '#C6C6C6',
                  boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                }}
                cursor="pointer"
                p={1}
                borderRadius={5}
                key={liIndex}
              >
                <Box
                  w={isDropDown ? '30px' : '50px'}
                  h={isDropDown ? '30px' : '50px'}
                  borderRadius="full"
                  bg="#D9D9D9"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {li.icon}
                </Box>
                <Text fontSize={'15px'} flex={1}>
                  {li.title}
                </Text>
                {li.title == "myCalendar" && unreadMeetingCount > 0 && (
                  <Circle
                    bg="brandRed.500"
                    color="white"
                    w="20px"
                    h="20px"
                    p="8px"
                    borderRadius="100%"
                  >
                    {unreadMeetingCount}
                  </Circle>
                )}
                <ChevronRight height="12" />
              </HStack>
            ))}
        </Stack>
{console.log(isDropDown,showMore,"mmmmmmmoee")}
        {!isDropDown && showMore && <Button
          rightIcon={
            showAll ? (
              <ChevronUp color={'#3D5A80'} />
            ) : (
              <ChevronDown color={'#3D5A80'} />
            )
          }
          onClick={(e) => { setShowAll(!showAll); sessionStorage.setItem("showMore", showAll ? 'true' : '') }}
          variant="transparent"
        >
          <Text color="brandPrimary.500">See {showAll ? 'Less' : 'More'}</Text>
        </Button>}
        {/* {showCopyright ? <MainCopyrightsText /> : <></>} */}
        <div></div>
      </Stack>
    </Stack>
  );
};
