import { AlertDialog as AlertDialog_ } from "@chakra-ui/react"
export const AlertDialog = ({ ...props }) => {
    return <AlertDialog_ {...props}>{props?.children}</AlertDialog_>
}

import { AlertDialogBody as AlertDialogBody_ } from "@chakra-ui/react"
export const AlertDialogBody = ({ ...props }) => {
    return <AlertDialogBody_ {...props}>{props?.children}</AlertDialogBody_>
}

import { AlertDialogFooter as AlertDialogFooter_ } from "@chakra-ui/react"
export const AlertDialogFooter = ({ ...props }) => {
    return <AlertDialogFooter_ {...props}>{props?.children}</AlertDialogFooter_>
}

import { AlertDialogHeader as AlertDialogHeader_ } from "@chakra-ui/react"
export const AlertDialogHeader = ({ ...props }) => {
    return <AlertDialogHeader_ {...props}>{props?.children}</AlertDialogHeader_>
}

import { AlertDialogContent as AlertDialogContent_ } from "@chakra-ui/react"
export const AlertDialogContent = ({ ...props }) => {
    return <AlertDialogContent_ {...props}>{props?.children}</AlertDialogContent_>
}

import { AlertDialogOverlay as AlertDialogOverlay_ } from "@chakra-ui/react"
export const AlertDialogOverlay = ({ ...props }) => {
    return <AlertDialogOverlay_ {...props}>{props?.children}</AlertDialogOverlay_>
}

import { AlertDialogCloseButton as AlertDialogCloseButton_ } from "@chakra-ui/react"
export const AlertDialogCloseButton = ({ ...props }) => {
    return <AlertDialogCloseButton_ {...props}>{props?.children}</AlertDialogCloseButton_>
}