import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const header = defineStyle({
  py: '10px',
  fontSize: 'xl',
  fontWeight: 'bold',
  color: 'brandPrimary.500',
});
const sm = defineStyle({
  fontSize: 'sm',
  py: '6',
});

const baseStyle = definePartsStyle({
  // define the part you're going to style
  overlay: {
    bg: 'blackAlpha.200', //change the background
  },
  dialog: {
    borderRadius: 'md',
    border: '1px solid',
    borderColor: 'gray.100',
  },
  closeButton:{
    background:'transparent',
    fontSize:'lg',
    fontWeight:'bold',
    color:'brandPrimiary.500'
  }
});

const xlDailog = defineStyle({
    width:'1000px',
})

const sizes = {
  xl3: definePartsStyle({ header:header, dialog: xlDailog}),
  '2xl': definePartsStyle({ header }),
  xl: definePartsStyle({ header }),
  md: definePartsStyle({ header }),
};

export const modalTheme = defineMultiStyleConfig({
  defaultProps: {
    size: 'md',
    variant: 'default',
  },
  sizes,
  baseStyle,
});
