import {
  ChatIcon,
  FollowIcon,
  FollowingIcon,
  HandShakeSimpleIcon,
} from '@mybridge/icons';
import { MeetingDefaultIcon } from '@mybridge/icons/NotificationIcons';
import { HStack, IconButton, Tooltip, Button } from '@mybridge/ui';
import { MatrixContext } from 'packages/mybridgev4/context/chat-context';
import { forwardRef, useContext } from 'react';
import { MyNetworkContext } from '../network/context';
export const UserNetworkingActions = forwardRef(
  ({ user, size, showHandshakeOnly, ...props }, ref) => {
    const networkCtx = useContext(MyNetworkContext);
    const { startChatSingle } = useContext(MatrixContext);
    const { startMeetingSingle } = useContext(MatrixContext);
    const {
      isFollowed,
      isFriend,
      followUser,
      handshakeUser,
      handshakeLoading,
      followLoading,
      isHandshaked,
      setSelectedUser,
      selectedUser,
      handleHandshakeSendRevoke,
    revokeHandshakeLoading,
    } = networkCtx ?? {};
    return isFriend?.(user) ? (
      <HStack {...props}>
        <Tooltip label={'Chat'}>
          <IconButton
            onClick={(e) => {
              startChatSingle?.(user);
            }}
            variant={'secondary'}
            size={size ? size : 'md'}
          >
            <ChatIcon
              width={size === 'sm' ? 8 : 20}
              height={size === 'sm' ? 8 : 20}
            />
          </IconButton>
        </Tooltip>
        <Tooltip label={'Meeting'}>
          <IconButton
            onClick={(e) => {
              startMeetingSingle?.(user);
            }}
            variant={'secondary'}
            size={size ? size : 'md'}
          >
            <MeetingDefaultIcon
              width={size === 'sm' ? 8 : 20}
              height={size === 'sm' ? 8 : 20}
            />
          </IconButton>
        </Tooltip>
      </HStack>
    ) : (
      <HStack {...props}>
        {showHandshakeOnly ? (
          <>
            {isHandshaked?.(user) ? (
              <Tooltip label="Withdraw Handshake">
                <IconButton
                  onClick={(e) => {
                    setSelectedUser?.(user);
                    handleHandshakeSendRevoke?.(user);
                  }}
                  variant="secondary"
                  isLoading={revokeHandshakeLoading && selectedUser?.id === user?.id}
                  size={size ? size : 'md'}
                  
                  icon={
                    <HandShakeSimpleIcon
                      color="#E8BAB9"
                      width={20}
                      height={20}
                    />
                  }
                />
              </Tooltip>
            ) : (
              <Tooltip label="Send Handshake Request">
                <IconButton
                  onClick={(e) => {
                    setSelectedUser?.(user);
                    handshakeUser?.(user);
                  }}
                  variant="secondary"
                  isLoading={handshakeLoading && selectedUser?.id === user?.id}
                  size={size ? size : 'md'}
                  // isDisabled={user?.field_audience_visibility?.WHO_CAN_SEE_FRIENDS === 1 ? false : true}
                  icon={
                    <HandShakeSimpleIcon
                      {...(isHandshaked?.(user) ? {} : { color: 'green' })}
                      width={20}
                      height={20}
                    />
                  }
                />
              </Tooltip>
            )}
          </>
        ) : (
          <>
            <Tooltip label={isFollowed?.(user) ? 'Following' : 'Follow'}>
              <IconButton
                isDisabled={isFollowed?.(user)}
                onClick={(e) => {
                  setSelectedUser?.(user);
                  followUser?.(user);
                }}
                variant={isFollowed?.(user) ? 'secondary' : 'secondary'}
                isLoading={followLoading}
                size={size ? size : 'md'}
              >
                <FollowingIcon
                  {...(isFollowed?.(user) ? {} : { color: 'white' })}
                  width={20}
                  height={20}
                />
              </IconButton>
            </Tooltip>
            <Tooltip
              label={isHandshaked?.(user) ? 'Handshake sent' : 'Handshake'}
            >
              <IconButton
                isDisabled={isHandshaked?.(user)}
                onClick={(e) => {
                  setSelectedUser?.(user);
                  handshakeUser?.(user);
                }}
                variant="secondary"
                isLoading={handshakeLoading}
                size={size ? size : 'md'}
              >
                <HandShakeSimpleIcon
                  {...(isHandshaked?.(user) ? {} : { color: 'green' })}
                  width={20}
                  height={20}
                />
              </IconButton>
            </Tooltip>
          </>
        )}
      </HStack>
    );
  }
);
