import { Button } from '@mybridge/ui/button';
import { FormControl, FormErrorMessage } from '@mybridge/ui/form-control';
import { useToast } from '@mybridge/ui/hooks';
import { Input } from '@mybridge/ui/input';
import { Box, HStack, Stack } from '@mybridge/ui/layout';
import { Link } from '@mybridge/ui/link';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { PinInput, PinInputField } from '@mybridge/ui/pin-input';
import { Text } from '@mybridge/ui/text';
import { useRouter } from 'next/router';
import { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  activateYourAccount,
  forgetPassword,
  resetPassword,
  setLoggedIn,
  setOpenAuthModal,
} from 'v4/store/actions/auth.actions';
import { getUserProfileInfo } from 'v4/store/actions/user-profile.actions';
import API from 'v4/store/common/api';
import { APIEndPoints } from 'v4/store/common/endPoint';

export const RequestResetPasswordModal = ({ ...props }) => {
  const { push } = useRouter();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm();

  const toast = useToast({
    position: 'top',
    isClosable: true,
  });
  const onFormSubmit = async ({ email }) => {
    setLoading(true);
    try {
      const resp = await dispatch(forgetPassword({ email }));
      const { code } = resp?.payload ?? {};
      if (code) {
        toast({
          title: 'Invalid email entered! Please try again...',
          status: 'error',
        });
        setLoading(false);
        return;
      } else {
        toast({
          title: 'A verification link has been sent to your email-id!',
          description:'Follow the instructions in that email, to reset your password.',
          status: 'success',
        });
        dispatch(setOpenAuthModal('sign-in'));
      }
    } catch (e) {
      toast({
        title: 'Error while registering!',
        description: e?.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal size="lg" isCentered {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Forgot Password</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <ModalBody pb={6}>
            <Stack>
              <Text>Reset your password in 2 easy steps</Text>
              <HStack flex={1} alignItems="flex-start" justifyContent="center">
                <FormControl isInvalid={errors?.email}>
                  <Input
                  placeholder="Email"
                    type="email"
                    {...register('email', { required: true })}
                  />
                  <FormErrorMessage>
                    Please enter a valid email
                  </FormErrorMessage>
                </FormControl>
              </HStack>
            </Stack>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Stack flex={1}>
              <Button
                py={6}
                fontWeight="bold"
                w="100%"
                type="submit"
                variant="primary"
                isLoading={loading}
              >
                Reset Password
              </Button>
            </Stack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
