import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const outline = defineStyle({
  border: '1px solid',
  borderColor: 'brandGray.500',
});
const primary = defineStyle({
  border: '1px solid',
  borderColor: 'brandGray.100',
  background: 'brandGray.200',
  borderRadius: '5px',
});

export const textareaTheme = defineStyleConfig({
  variants: { outline, primary },
  defaultProps: {
    variant: 'primary',
  },
});
