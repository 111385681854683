/* eslint-disable import/first */
import { Link as Link_ } from '@chakra-ui/react';
import NLink from 'next/link';
import { forwardRef } from 'react';
import { Box } from '@chakra-ui/react';
export const Link = forwardRef(
  ({ scroll = false, shallow, href, target, ...props }, ref) => {
    return href?.length ? (
      <NLink href={href} scroll={scroll} shallow={shallow} target={target}>
        <Link_
          ref={ref}
          as={Box}
          _hover={{ textDecoration: 'none' }}
          {...props}
        >
          {props?.children}
        </Link_>
      </NLink>
    ) : (
      <Link_ ref={ref} href={href} target={target} {...props}>
        {props?.children}
      </Link_>
    );
  }
);

import { LinkOverlay as LinkOverlay_ } from '@chakra-ui/react';
export const LinkOverlay = forwardRef(({ ...props }, ref) => {
  return (
    <LinkOverlay_ ref={ref} {...props}>
      {props?.children}
    </LinkOverlay_>
  );
});

import { LinkBox as LinkBox_ } from '@chakra-ui/react';
export const LinkBox = forwardRef(({ ...props }, ref) => {
  return (
    <LinkBox_ ref={ref} {...props}>
      {props?.children}
    </LinkBox_>
  );
});

export const LinkButton = forwardRef(({ ...props }, ref) => {
  return (
    <Link fontWeight="normal" color="brandPrimary.500" ref={ref} {...props}>
      {props?.children}
    </Link>
  );
});
