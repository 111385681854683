export const CopyIcon = (props) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.46879 12.1875C5.00441 12.1875 3.8125 10.9955 3.8125 9.53117V3.125H2.71879C1.77065 3.125 1 3.89551 1 4.84367V13.2812C1 14.2293 1.77065 15 2.71879 15H10.5312C11.4793 15 12.25 14.2293 12.25 13.2812V12.1875H6.46879Z"
        fill={props?.color ?? '#5B5B5B'}
      />
      <path
        d="M14.75 1.71879C14.75 0.769395 13.9806 0 13.0313 0H6.46879C5.51939 0 4.75 0.769395 4.75 1.71879V9.53121C4.75 10.4806 5.51939 11.25 6.46879 11.25H13.0313C13.9806 11.25 14.75 10.4806 14.75 9.53121V1.71879Z"
        fill={props?.color ?? '#5B5B5B'}
      />
    </svg>
  );
};
