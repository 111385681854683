/* eslint-disable @nx/enforce-module-boundaries */
import React, { forwardRef } from 'react';
import { Box, Button } from '@mybridge/ui';
import { useRadio } from '@chakra-ui/react';
import { chakra } from '@chakra-ui/react';

export const CustomRadioButton = forwardRef((props, ref) => {
  const { children, isDisabled, ...radioProps } = props;
  const { state, getInputProps, getRadioProps, htmlProps, getLabelProps } =
    useRadio(radioProps);

    // console.log('checkState', state);
  return (
    <chakra.label {...htmlProps} cursor="pointer" bg={state.isChecked ? '#E4E6EB' : 'transparent'}>
      <input {...getInputProps({})} hidden />
      <Box
        ref={ref}
        bg={state.isChecked ? '#E4E6EB' : 'transparent'}
        color={state.isChecked ? '#5B5B5B' : '#5B5B5B'}
        border={`1px solid ${state.isChecked ? '#C1C1C1' : 'transparent'}`}
        aria-checked={state.isChecked}
        // isDisabled={state.isDisabled}
        borderRadius={'8px'}
        fontSize="md"
        p={1}
        {...getRadioProps()}
      >
        {children}
      </Box>
    </chakra.label>
  );
});
