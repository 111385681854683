import { Slider as Slider_ } from "@chakra-ui/react";
import { forwardRef } from "react";
export const Slider = forwardRef(({ ...props }, ref) => {
    return <Slider_ ref={ref} {...props}>{props?.children}</Slider_>
});

import { SliderTrack as SliderTrack_ } from "@chakra-ui/react";
export const SliderTrack = forwardRef(({ ...props }, ref) => {
    return <SliderTrack_ ref={ref} {...props}>{props?.children}</SliderTrack_>
});

import { SliderFilledTrack as SliderFilledTrack_ } from "@chakra-ui/react";
export const SliderFilledTrack = forwardRef(({ ...props }, ref) => {
    return <SliderFilledTrack_ ref={ref} {...props}>{props?.children}</SliderFilledTrack_>
});

import { SliderThumb as SliderThumb_ } from "@chakra-ui/react";
export const SliderThumb = forwardRef(({ ...props }, ref) => {
    return <SliderThumb_ ref={ref} {...props}>{props?.children}</SliderThumb_>
});

import { SliderMark as SliderMark_ } from "@chakra-ui/react";
export const SliderMark = forwardRef(({ ...props }, ref) => {
    return <SliderMark_ ref={ref} {...props}>{props?.children}</SliderMark_>
});