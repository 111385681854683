import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../common/api';
import { APIEndPoints } from '../common/endPoint';

export const setFcmToken = createAsyncThunk('setFcmToken', async (body) => {
  const obj = {
    url: `${APIEndPoints.Notification}/token/update`,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const createNotification = createAsyncThunk(
  'createNotification',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.Notification}/create`,
      method: 'POST',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const updateNotification = createAsyncThunk(
  'updateNotification',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.Notification}/status/update/${body.id}`,
      method: 'PUT',
      body: JSON.stringify({"status":body.status}),
    };
    return await API(obj);
  }
);

export const getNotificationList = createAsyncThunk(
  'getNotificationList',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.Notification}/list`,
    };
    return await API(obj);
  }
);

export const markAllRead = createAsyncThunk(
  'markAllRead',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.Notification}/status/read_all`,
      method: 'POST',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const setGlobalNotificationCount = createAsyncThunk(
  'setGlobalNotificationCount',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.Notification}/clear_count`,
    };
    return await API(obj);
  }
);

// export const setGlobalNotificationCount = createAction('setGlobalNotificationCount');
