import { createReducer } from '@reduxjs/toolkit';
import {
  getNotificationList,createNotification,updateNotification, setFcmToken, setGlobalNotificationCount, markAllRead
} from '../actions/notification.actions';
const isClient = typeof window !== 'undefined';

const initialState = {
  error:null,
  log:null,
  loading:false,
  notificationsList:null,
  updatedStatus:false,
  globalNotifCount:0
};

export const notificationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getNotificationList.pending, (state, action) => {
      state.loading = true
    })
    .addCase(getNotificationList.fulfilled, (state, action) => {
      state.notificationsList = action.payload.data;
      state.loading = false
      state.updatedStatus = false;
      state.globalNotifCount = action.payload.count;
    })
    .addCase(getNotificationList.rejected, (state, action) => {
      state.loading = false
      state.error = true
      state.log = action.payload
    })
    .addCase(createNotification.pending, (state, action) => {
      state.loading = true
    })
    .addCase(createNotification.fulfilled, (state, action) => {
      state.notificationsList = action.payload;
      state.loading = false
    })
    .addCase(createNotification.rejected, (state, action) => {
      state.loading = false
      state.error = true
      state.log = action.payload
    })
    .addCase(updateNotification.fulfilled, (state, action) => {
      state.updatedStatus = true;
      state.loading = false
    })
    .addCase(updateNotification.rejected, (state, action) => {
      state.updatedStatus = false;
    })
    .addCase(setFcmToken.fulfilled, (state, action) => {
      state.updatedStatus = true;
      state.loading = false
    })
    .addCase(markAllRead.fulfilled, (state, action) => {
      state.updatedStatus = true;
      state.loading = false
    })
    .addCase(setGlobalNotificationCount.fulfilled, (state, action) => {
      state.globalNotifCount = 0;
    })
});

