import { createReducer } from '@reduxjs/toolkit';
import {
  getCompanyAllJobs,
  getCompanyActiveJobs,
  getCompanyCloseJobs,
  getCompanyDraftJobs,
  postNewJob,
  getCandidates,
  getJobCheckout,
  getJobSubscriptionDetails,
  setGlobalJobId,
  clearGlobalJobId,
  jobSubscriptionPriceDetails,
  createJobSubscription,
  previewJobSubscription,
  updateJobSubscription,
  closeJob,
  getJobScreeningQuestions,
  checkCompanyJobFreeSlot,
  RepostJob,
  purchasedJobSlots,
  setSubscriptionCompanyId,
  clearSubscriptionCompanyId
} from '../actions/companyJobs.actions';

const initialState = {
  loading: false,
  overviewLoading: true,
  companyJobList: [],
  activeJobs: [],
  closedJobs: [],
  draftJobs: [],
  jobPostedSuccess: undefined,
  candidateList: [],
  candidateListByJobs: [],
  checkoutLink: null,
  userJobSubscription: [],
  globalJobId: null,
  screeningQuestions:{},
  jobSubscriptionPlans:null,
  jobSubscriptionProcess:{},
  jobClosed:false,
  freeSlotAvailable:null,
  returnPurchasedSlots:null,
  companyIdForSubscriptionRedirect:null,
  jobSubscriptionPreview:null,
};

const companyJobsReducer = createReducer(initialState, (builder) => {
  builder
  .addCase(getCompanyAllJobs.fulfilled, (state, action) => {
    state.companyJobList = action.payload?.results;
  })
  .addCase(getCompanyActiveJobs.fulfilled, (state, action) => {
    state.activeJobs = action.payload?.results;
    state.overviewLoading = false;
  })
  .addCase(getCompanyActiveJobs.rejected, (state, action) => {
    state.overviewLoading = false;
  })
  .addCase(getCompanyCloseJobs.fulfilled, (state, action) => {
    state.closedJobs = action.payload?.results;
  })
  .addCase(getCompanyDraftJobs.fulfilled, (state, action) => {
    state.draftJobs = action.payload?.results;
  })
  .addCase(getCandidates.fulfilled, (state, action) => {
    state.candidateList = action.payload?.results;
  })
  .addCase(postNewJob.fulfilled, (state, action) => {
    state.jobPostedSuccess = action.payload;
  })
  .addCase(RepostJob.fulfilled, (state, action) => {
    state.jobPostedSuccess = action.payload;
  })
  .addCase(getJobCheckout.fulfilled, (state, action) => {
    state.checkoutLink = action.payload;
  })
  .addCase(getJobSubscriptionDetails.pending, (state, action) => {
    state.loading = true;
  })
  .addCase(getJobSubscriptionDetails.fulfilled, (state, action) => {
    state.userJobSubscription = action.payload;
    state.loading = false;
  })
  .addCase(jobSubscriptionPriceDetails.fulfilled, (state, action) => {
    state.jobSubscriptionPlans = action.payload;
  })
  .addCase(createJobSubscription.fulfilled, (state, action) => {
    state.jobSubscriptionProcess = action.payload;
  })
  .addCase(previewJobSubscription.fulfilled, (state, action) => {
    state.jobSubscriptionPreview = action.payload;
  })
  .addCase(checkCompanyJobFreeSlot.fulfilled, (state, action) => {
    state.freeSlotAvailable = action.payload.is_slot_available;
  })
  .addCase(updateJobSubscription.fulfilled, (state, action) => {
    state.jobSubscriptionProcess = action.payload;
  })
  .addCase(setGlobalJobId, (state, action) => {
    state.globalJobId = action.payload;
  })
  .addCase(clearGlobalJobId, (state, action) => {
    state.globalJobId = null;
  })
  .addCase(getJobScreeningQuestions.pending, (state, action) => {
    state.loading = true;
    state.screeningQuestions = action.payload;
  })
  .addCase(getJobScreeningQuestions.fulfilled, (state, action) => {
    state.loading = false;
    state.screeningQuestions = action.payload;
  })
  .addCase(closeJob.fulfilled, (state, action) => {
    state.jobClosed = true;
  })
  .addCase(purchasedJobSlots, (state, action) => {
    state.returnPurchasedSlots = action.payload;
  })
  .addCase(setSubscriptionCompanyId, (state, action) => {
    state.companyIdForSubscriptionRedirect = action.payload;
  })
  .addCase(clearSubscriptionCompanyId, (state, action) => {
    state.companyIdForSubscriptionRedirect = null;
  })
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    .addDefaultCase((state, action) => {});
});

export default companyJobsReducer;
