import { radioAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(radioAnatomy.keys)

const baseStyle = definePartsStyle({
    control: {
        width: '18px',
        height: '18px',
        top:'3px',
        position:'relative',
        _checked: {
            background: '#3D5A80'
        }
    },
})

export const radioTheme = defineMultiStyleConfig({ baseStyle })