import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

export const buttonTheme = definePartsStyle({
  baseStyle: {
    fontWeight: 'normal',
    textTransform: 'capitalized',
    borderRadius: 5,
    _disabled: {
      bg: 'brandGray.500',
      color: 'brandGray.700',
      _hover: {
        bg: 'brandGray.500',
        color: 'brandGray.700',
      },
    },
  },
  variants: {
    primary: {
      bg: 'brandPrimary.500',
      color: 'white',
      _hover: {
        bg: 'brandPrimary.900',
      },
      _disabled: {
        bg: 'brandGray.30',
        color: 'white',
        _hover: {
          bg: 'brandGray.500',
          color: 'brandGray.700',
        },
      },
    },
    primaryDark: {
      bg: 'brandPrimary.900',
      color: 'white',
      _hover: {
        bg: 'brandPrimary.900',
      },
      _disabled: {
        bg: 'gray.400',
        color: 'gray.300',
      },
    },
    slimPrimary: {
      bg: 'brandPrimary.500',
      color: 'white',
      padding: '0.5rem 1.5rem',
      height: 'auto',
      _hover: {
        bg: 'brandPrimary.900',
      },
      _disabled: {
        bg: 'gray.400',
        color: 'gray.700',
      },
    },
    primaryOutline: {
      borderColor: 'brandPrimary.500',
      border: '1px solid',
      color: 'brandPrimary.500',
      bg: 'transparent',
      _hover: {
        bg: 'brandPrimary.100',
      },
      _active: {
        bg: 'brandPrimary.200',
      },
      _disabled: {
        bg: 'gray.400',
        color: 'gray.300',
        borderColor: 'gray.400',
      },
    },
    secondaryOutline: {
      border: '1px solid #E4E6EB',
      color: 'black',
      bg: 'transparent',
      _hover: {
        bg: 'brandPrimary.100',
      },
      _active: {
        bg: 'brandPrimary.200',
      },
      _disabled: {
        bg: 'gray.400',
        color: 'gray.300',
        borderColor: 'gray.400',
      },
    },
    whiteOutline: {
      border: '1px solid #3D5A80',
      color: 'brandPrimary.500',
      bg: 'white',
      _hover: {
        bg: 'brandPrimary.100',
      },
      _active: {
        bg: 'brandPrimary.200',
      },
      _disabled: {
        bg: 'white',
        color: '#D9D9D9',
        borderColor: '#E4E6EB',
      },
    },
    secondary: {
      bg: 'brandGray.500',
      color: 'brandGray.700',
      _hover: {
        bg: 'brandGray.400',
        color: 'brandGray.900',
      },
      _active: {
        bg: 'brandGray.600',
      },
      _disabled: {
        bg: 'brandGray.400',
        color: 'gray.300',
      },
    },
    success: {
      bg: 'green.500',
      color: 'white',
      _hover: {
        bg: 'green.900',
        color: 'white',
      },
      _disabled: {
        bg: 'green.500',
        color: 'white',
        opacity: 0.5,
      },
    },
    error: {
      bg: 'red.500',
      color: 'white',
      _hover: {
        bg: 'red.900',
        color: 'white',
      },
      _disabled: {
        bg: 'red.500',
        color: 'white',
        opacity: 0.5,
      },
    },
    transparent: {
      bg: 'transparent',
      color: 'brandPrimary.500',
    },
    primarySub: {
      bg: 'brandPrimary.500',
      color: 'white',
      _hover: {
        bg: 'brandPrimary.900',
      },
      _disabled: {
        bg: 'brandPrimary.300',
        color: 'white',
        opacity: '1',
        _hover: {
          bg: 'brandPrimary.300 !important',
          color: 'white',
        },
      },
    },
    darkGrayButton: {
      bg: 'brandGray.700',
      color: 'white',
      _hover: {
        bg: 'brandGray.900',
      },
      _disabled: {
        bg: 'brandGray.300',
        color: 'white',
        opacity: '1',
        _hover: {
          bg: 'brandGray.300 !important',
          color: 'white',
        },
      },
    },
    homeHeaderButtons: {
      bg: 'transparent',
      color: 'brandGray.600',
      position: 'relative',
      'svg path': { fill: '#575757' },
      _hover: {
        bg: 'brandGray.500',
      },
      _active: {
        'svg path': { fill: 'brandPrimary.500' },
        '&::after': {
          content: '"a"',
          color: 'brandPrimary.500',
          borderBottom: '3px solid',
          borderColor: 'brandPrimary.500',
          position: 'absolute',
          left: 0,
          right: 0,
          textIndent: '-99999px',
          bottom: '-12px',
          overflow: 'hidden',
        },
      },
      _disabled: {
        bg: 'brandGray.300',
        color: 'white',
        opacity: '1',
        _hover: {
          bg: 'brandGray.300 !important',
          color: 'white',
        },
      },
    },
  },
  sizes: {
    md: {
      py: '8px',
      px: '18px',
    },
    lg: {
      fontSize: 'lg',
      px: 12,
      py: 22,
    },
  },
});
