/* eslint-disable @nx/enforce-module-boundaries */
import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppleIcon } from '@mybridge/icons/Apple';
import { Button, useToast } from '@mybridge/ui';
import { signIn, useSession, signOut } from 'next-auth/react';
import { useRouter } from 'next/router';
import { googleSignIn } from 'v4/store/actions/auth.actions';
export default function AppleLoginButton({ path = '/singup', ...props }) {
  const [clientID, setClientID] = useState();

  // useEffect(() => {
  //   try {
  //     setClientID(process.env.GOOGLE_APP_CLIENT_ID);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }, []);
  // return <></>;
  return <GButton {...props} />;
}

const GButton = ({ path = 'singup', ...props }) => {
  const dispatch = useDispatch();
  const { push } = useRouter();
  const { loggedIn } = useSelector((state) => state.user);
  const [user, setUser] = useState({});
  const { access } = useSelector((state) => state.user);
  const toast = useToast({
    position: 'top',
    isClosable: true,
  });

  const { data: appleSession } = useSession();

  // console.log('session', appleSession);

  useEffect(() => {
    // console.log('apple-session', appleSession);
    if (appleSession && !loggedIn && appleSession.provider === 'apple') {
      const [fn, ln] = appleSession?.user?.name?.split?.(' ') ?? [];
      const singInObj = {
        id_token: appleSession.id_token,
        email: appleSession.user.email,
        first_name: fn,
        access_token: appleSession?.access_token,
        last_name: ln,
        platform: 'apple-login',
      };
      dispatch(googleSignIn(singInObj)).then((res) => {
        if(res){
          console.log(res);
          if(appleSession) signOut();
        }
      });;
    }
  }, [appleSession]);

  const handleLogin = () => {
    try {
      console.log('logging in via Apple...');
      // login?.();
      signIn('apple');
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Button
      py={6}
      leftIcon={<AppleIcon />}
      onClick={() => handleLogin()}
      variant="outline"
      borderColor="gray.200"
      {...props}
    >
       {path==="singup"?"Sign up":"Sign in"} with Apple
    </Button>
  );
};
