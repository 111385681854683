/* eslint-disable @nx/enforce-module-boundaries */
import { EditIcon } from '@mybridge/icons/Edit';
import { GmailIcon } from '@mybridge/icons/Gmail';
import { Button } from '@mybridge/ui/button';
import { Divider } from '@mybridge/ui/divider';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormHelperText,
} from '@mybridge/ui/form-control';
import { useToast } from '@mybridge/ui/hooks';
import { IconButton } from '@mybridge/ui/icon-button';
import { Input, InputGroup, InputRightElement } from '@mybridge/ui/input';
import { Box, HStack, Stack } from '@mybridge/ui/layout';
import { Link } from '@mybridge/ui/link';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { Text } from '@mybridge/ui/text';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getUserFullName } from 'v4/lib/commons';
import {
  changeEmailRequest,
  openSignInModal,
  resendVerificationEmail,
  setLoggedIn,
  setOpenAuthModal,
} from 'v4/store/actions/auth.actions';
import API from 'v4/store/common/api';
import { APIEndPoints } from 'v4/store/common/endPoint';
import { getUserProfileInfo } from 'v4/store/actions/user-profile.actions';

export const ChangeEmailModal = ({ ...props }) => {
  const { push } = useRouter();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const toast = useToast({
    position: 'top',
    isClosable: true,
  });
  const { userProfileInfo } = useSelector((state) => state.userProfile) ?? {};
  const { email } = userProfileInfo ?? {};
  const onFormSubmit = async ({ email, password }) => {
    const payload = {
      email,
      password,
    };
    setLoading(true);
    try {
      const res = await dispatch(changeEmailRequest(payload));
      const { code, password, email } = res?.payload ?? {};
      if (password || email) {
        toast({
          title: 'Please enter correct email/password',
          status: 'error',
        });
        return;
      }
      if (code === 'VERIFIED_USER') {
        toast({
          title: "Verified users can't change email",
          status: 'error',
        });
        return;
      }
      dispatch(getUserProfileInfo());
      dispatch(resendVerificationEmail(payload))
      toast({
        title: 'Email changed successfully!',
        status: 'success',
      });
      dispatch(setOpenAuthModal('send-verify-link'));
    } catch (e) {
      toast({
        title: 'Error while sending link!',
        description: e?.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal size="lg" isCentered {...props} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Email</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <ModalBody pb={6}>
            <Stack>
              <FormControl isInvalid={errors?.currentEmail}>
                <FormLabel>Current Email*</FormLabel>
                <Input
                  type="email"
                  autoFocus
                  defaultValue={email}
                  placeholder="Current Email"
                  {...register('currentEmail', {
                    required: true,
                    pattern:
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  })}
                />
                <FormErrorMessage>Please enter a valid email</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.email}>
                <FormLabel>New Email*</FormLabel>
                <Input
                  type="email"
                  placeholder="New Email"
                  {...register('email', {
                    required: true,
                    pattern:
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  })}
                />
                <FormErrorMessage>Please enter a valid email</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.password}>
                <FormLabel>Password*</FormLabel>
                <Input
                  type="password"
                  placeholder="Password"
                  {...register('password', { required: true })}
                />
                <FormHelperText>
                  Enter the password you created when joining.
                </FormHelperText>
                <FormErrorMessage>
                  Please enter a valid password
                </FormErrorMessage>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Stack flex={1}>
              <Button
                py={6}
                fontWeight="bold"
                w="100%"
                variant="primary"
                isLoading={loading}
                type="submit"
              >
                Change Email
              </Button>
              <Button
                onClick={(e) => dispatch(setOpenAuthModal('send-verify-link'))}
                py={6}
                fontWeight="bold"
                w="100%"
                variant="ghost"
              >
                Cancel
              </Button>
            </Stack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
