/* eslint-disable @nx/enforce-module-boundaries */
import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  HStack,
  Avatar,
  Stack,
  Heading,
  Text,
  Portal,
  Button,
} from '@mybridge/ui';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserFullName,
  getUserProfilePic,
  getUserExperience,
} from 'v4/lib/commons';
import Link from 'next/link';
import MyBridgeAvatar from 'v4/components/common/MyBridgeAvatar';
import { useContext } from 'react';
import { SearchContext, SearchTabs } from 'v4/snippets/search/context';
import { setOpenAuthModal } from 'v4/store/actions/auth.actions';
import { SkeletonCircle, SkeletonText } from '@mybridge/ui';
const MobileDrawerMenu = ({ onClose, onOpen }) => {
  const { loggedIn, pageLoaded } = useSelector((state) => state.user);
  const { activeTab, setActiveTab, query, allSearch } =
    useContext(SearchContext);
  const { userProfileInfo, loading } = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();
  const userFullName = getUserFullName(userProfileInfo);
  const userProfilePic = getUserProfilePic(userProfileInfo);
  const userExperience = getUserExperience(userProfileInfo);

  const menu = SearchTabs?.()?.filter?.((menuItem, ind) =>
    query?.query?.length ? true : ind > 0
  );
  return (
    <Drawer placement="left" onClose={onClose} isOpen={onOpen}>
      <DrawerOverlay />
      <Portal>
        <DrawerContent>
          {loggedIn && (
            <DrawerHeader borderBottomWidth="1px">
              <Link onClick={(e) => onClose()} href="/profile/me">
                <HStack>
                  {loading ? <SkeletonCircle size='10' /> : <MyBridgeAvatar
                    name={userFullName}
                    src={userProfilePic}
                    size="md"
                  />}
                  
                  <Stack spacing="0">
                    {loading ? <><SkeletonText mt='4' noOfLines={2} spacing='4' skeletonHeight='3' /></> : <>
                    <Heading size="xs">{userFullName}</Heading>
                    <Text size="xs" fontWeight="400">
                      {userExperience?.title} at {userExperience?.company_name}
                    </Text>
                    </>}
                    
                  </Stack>
                </HStack>
              </Link>
            </DrawerHeader>
          )}

          <DrawerBody>
            {!loggedIn && (
              <Stack spacing={2} my={3}>
                <Heading fontSize="22px" fontWeight="600">New to myBridge?</Heading>
                <Text lineHeight="20px">Sign up now to get your own personalized timeline!</Text>

                <Button variant="primary" onClick={(e) => dispatch(setOpenAuthModal('sign-up'))}>Create Account</Button>
                <Button variant="whiteOutline" onClick={(e) => dispatch(setOpenAuthModal('sign-in'))}>Log in</Button>
              </Stack>
            )}
            <Stack>
              {menu?.map?.((mi, miIndex) => (
                <HStack
                  // className={Styles.tab}
                  _hover={{
                    bgColor: 'gray.100',
                  }}
                  borderColor="transparent"
                  bgColor={mi.name === activeTab ? 'brandGray.500' : ''}
                  // p={2}
                  borderRadius="full"
                  key={miIndex}
                  gap="2"
                  alignItems="center"
                  cursor="pointer"
                  onClick={(e) => {setActiveTab(mi?.name); onClose();}}
                  justifyContent="flex-start"
                  {...(mi.link ? { as: Link, href: mi.link } : {})}
                >
                  <HStack
                    bg={mi.name === activeTab ? 'brandPrimary.500' : '#D9D9D9'}
                    alignItems="center"
                    justifyContent="center"
                    w="10"
                    h="10"
                    borderRadius="full"
                    p={2}
                  >
                    {mi?.icon?.(
                      mi.name === activeTab ? { color: 'white' } : {}
                    )}
                  </HStack>
                  <Text pr="4">{mi.title}</Text>
                </HStack>
              ))}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Portal>
    </Drawer>
  );
};

export default MobileDrawerMenu;
