import { FormControl as FormControl_ } from "@chakra-ui/react";
import { forwardRef } from "react";
export const FormControl = forwardRef(({ ...props }, ref) => {
    return <FormControl_ ref={ref} {...props}>{props?.children}</FormControl_>
});

import { FormLabel as FormLabel_ } from "@chakra-ui/react";
export const FormLabel = forwardRef(({ ...props }, ref) => {
    return <FormLabel_ ref={ref} {...props} mb='4px' colorScheme="gray.200">{props?.children}</FormLabel_>
});

import { FormErrorMessage as FormErrorMessage_ } from "@chakra-ui/react";
export const FormErrorMessage = forwardRef(({ ...props }, ref) => {
    return <FormErrorMessage_ ref={ref} {...props}>{props?.children}</FormErrorMessage_>
});

import { FormHelperText as FormHelperText_ } from "@chakra-ui/react";
export const FormHelperText = forwardRef(({ ...props }, ref) => {
    return <FormHelperText_ ref={ref} {...props}>{props?.children}</FormHelperText_>
});