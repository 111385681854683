import { Tab as Tab_ } from "@chakra-ui/react";
import { forwardRef } from "react";
export const Tab = forwardRef(({ ...props }, ref) => {
  return (
    <Tab_ ref={ref} {...props}>
      {props?.children}
    </Tab_>
  );
});

import { Tabs as Tabs_ } from '@chakra-ui/react';
export const Tabs = forwardRef(({ ...props }, ref) => {
  return (
    <Tabs_ ref={ref} {...props}>
      {props?.children}
    </Tabs_>
  );
});

import { TabPanel as TabPanel_ } from '@chakra-ui/react';
export const TabPanel = forwardRef(({ ...props }, ref) => {
  return (
    <TabPanel_ ref={ref} {...props}>
      {props?.children}
    </TabPanel_>
  );
});

import { TabList as TabList_ } from '@chakra-ui/react';
export const TabList = forwardRef(({ ...props }, ref) => {
  return (
    <TabList_ ref={ref} {...props}>
      {props?.children}
    </TabList_>
  );
});

import { TabPanels as TabPanels_ } from '@chakra-ui/react';
export const TabPanels = forwardRef(({ ...props }, ref) => {
  return (
    <TabPanels_ ref={ref} {...props}>
      {props?.children}
    </TabPanels_>
  );
});

import { TabIndicator as TabIndicator_ } from '@chakra-ui/react';
export const TabIndicator = forwardRef(({ ...props }, ref) => {
  return (
    <TabIndicator_ ref={ref} {...props}>
      {props?.children}
    </TabIndicator_>
  );
});
