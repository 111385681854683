import { Alert as Alert_ } from "@chakra-ui/react";
import { forwardRef } from "react";
export const Alert = forwardRef(({ ...props }, ref) => {
    return <Alert_ ref={ref} {...props}>{props?.children}</Alert_>
});

import { AlertIcon as AlertIcon_ } from "@chakra-ui/react";
export const AlertIcon = forwardRef(({ ...props }, ref) => {
    return <AlertIcon_ ref={ref} {...props}>{props?.children}</AlertIcon_>
});

import { AlertDescription as AlertDescription_ } from "@chakra-ui/react";
export const AlertDescription = forwardRef(({ ...props }, ref) => {
    return <AlertDescription_ ref={ref} {...props}>{props?.children}</AlertDescription_>
});

import { AlertTitle as AlertTitle_ } from "@chakra-ui/react";
export const AlertTitle = forwardRef(({ ...props }, ref) => {
    return <AlertTitle_ ref={ref} {...props}>{props?.children}</AlertTitle_>
});