import { ChevronRightBulletIcon } from '@mybridge/icons/ChevronRightBullet';
import { Button } from '@mybridge/ui/button';
import { Box, HStack, Stack } from '@mybridge/ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { Text } from '@mybridge/ui/text';
import { useContext, useMemo } from 'react';
import { MyNetworkContext } from 'v4/components/network/context';
import { getUserFullName } from 'v4/lib/commons';

export const NetworkBlockUserModal = ({ ...props }) => {
  const { selectedUser, blockUserLoading, blockUser } =
    useContext(MyNetworkContext);
  const blockCons = useMemo(() => [
    {
      title: 'See your posts on your timeline',
    },
    {
      title: 'Tag you',
    },
    {
      title: 'Invite you to events or groups',
    },
    {
      title: 'Message you',
    },
    {
      title: 'Add you as a friend',
    },
    {
      title: `If you're friends, blocking ${getUserFullName(selectedUser)} will also unfriend her.`,
    },
    {
      title:
        `If you want to limit what you share with ${getUserFullName(selectedUser)}, or see less of them, you can choose to take a break from them.`,
    },
  ],[selectedUser]);
  const handleBlockUser = () => {
    blockUser(selectedUser);
  };

  return (
    <>
      <Modal size="xl" {...props}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
            Block {getUserFullName(selectedUser)}
          </ModalHeader>
          <ModalBody>
            <Stack>
              <Stack>
                <Text>
                  {selectedUser?.first_name} will no longer be able to:
                </Text>
              </Stack>

              <Stack spacing={4}>
                {blockCons?.map?.((brr, index) => (
                  <HStack key={index} alignItems="flex-start">
                    <Box flexShrink={0} pt={1}>
                      <ChevronRightBulletIcon />
                    </Box>
                    <Text>{brr?.title}</Text>
                  </HStack>
                ))}
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack justifyContent="flex-end">
              <Button onClick={props?.onClose} variant="secondary">
                Cancel
              </Button>
              <Button
                onClick={handleBlockUser}
                variant="primary"
                isLoading={blockUserLoading}
              >
                Block
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
