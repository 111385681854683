/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../common/api';
import { CompanyAPIEndpoints, APIEndPoints } from '../common/endPoint';
import { jsonToFormData } from 'v4/lib/utils';

export const getCompanyPageInfo = createAsyncThunk('getCompanyPageInfo', async (payload) => {
  if(typeof window==="undefined") return;
  let { id } = payload || {};
  if (!id) {
    const company = JSON.parse(localStorage.getItem('Company'));
    if (company?.id) id = company.id;
  }
  const obj = {
    url: `${CompanyAPIEndpoints.CompaniesAPI}${id}/`,
  };
  return await API(obj);
});

export const patchCompanyInfo = createAsyncThunk('patchCompanyInfo', async (payload) => {
  const formData = new FormData();
  for (const key in payload) {
    // if (payload[key] === '' || payload[key] === undefined || payload[key] === null) {
    if (payload[key] === undefined || payload[key] === null) {
      continue;
    }
    formData.append(key, payload[key]);
  }

  const obj = {
    url: `${CompanyAPIEndpoints.CompaniesAPI}${payload.companyId}/`,
    method: 'PATCH',
    body: formData,
    isFormData: true,
  };
  const response = await API(obj);
  return response;
});

export const getCompanyServicesInfo = createAsyncThunk('getCompanyServicesInfo', async (id) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompaniesAPI}${id}/services/`,
  };
  return await API(obj);
});

export const getAllCompanyPages = createAsyncThunk('getAllCompanyPages', async () => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompaniesAPI}`,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getAllCompanyTypePages = createAsyncThunk('getAllCompanyTypePages', async () => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompaniesTypeAPI}`,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const createCompanyPage = createAsyncThunk('createCompanyPage', async (payload) => {
  const formData = new FormData();
  for (const key in payload) {
    if (payload[key] === '' || payload[key] === undefined || payload[key] === null) {
      continue;
    }
    formData.append(key, payload[key]);
  }

  const obj = {
    url: `${CompanyAPIEndpoints.CompaniesAPI}`,
    method: 'POST',
    body: formData,
    isFormData: true,
  };
  return await API(obj);
});

export const deleteCompanyPage = createAsyncThunk('deleteCompanyPage', async (payload) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompanyDelete(payload.id)}`,
    method: 'PUT',
  };
  return await API(obj);
});

export const getCompanyPublicData = createAsyncThunk('getCompanyPublicData', async (slug) => {
  const obj = {
    url: `${CompanyAPIEndpoints.PublicCompaniesAPI(slug)}`,
    isNoToken:true
  };
  return await API(obj);
});

export const getCompanyAnalytics = createAsyncThunk('getCompanyAnalytics', async (id) => {
  const obj = {
    url: `${CompanyAPIEndpoints.AnalyticsApi(id)}`,
  };
  return await API(obj);
});

export const getCompanyPageData = createAsyncThunk('getCompanyPageData', async (payload) => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompaniesAPI}${payload.id}/`,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const companyAddInfo = createAsyncThunk('companyAddInfo', async (payload) => {
  // const formData = new FormData();
  // for (const key in payload) {
  //   if (payload[key] === '' || payload[key] === undefined || payload[key] === null) {
  //     continue;
  //   }
  //   formData.append(key, payload[key]);
  // }
  const formData = jsonToFormData(payload);

  const obj = {
    url: `${CompanyAPIEndpoints.CompaniesAPI}${payload.id}/`,
    method: 'PATCH',
    body: formData,
    isFormData: true,
  };
  const response = await API(obj);
  return response;
});

// Company Followers API
export const getCompanyPageFollowers = createAsyncThunk('getCompanyPageFollowers', async ({id, page = 1}) => {
  const obj = {
    url: `${CompanyAPIEndpoints.FollowersApi(id)}`,
  };
  return await API(obj);
});

export  const inviteCompanyConnection = createAsyncThunk('inviteCompanyConnection', async (payload) => {
  const obj = {
    url: `${CompanyAPIEndpoints.InviteConnectionsToFollowCompany}`,
    method: 'POST',
    body: JSON.stringify(payload),
    isFormData:false
  };
  return await API(obj);
})

export const clear = createAction('clear');

export const createService = createAsyncThunk('createService', async (payload) => {
  const formData = new FormData();
  for (const key in payload) {
    if (payload[key] === '' || payload[key] === undefined || payload[key] === null) {
      continue;
    }

    formData.append(key, payload[key]);
  }

  const obj = {
    url: `${CompanyAPIEndpoints.CompaniesAPI}${payload.pageId}/services/`,
    method: 'POST',
    body: formData,
    isFormData: true,
  };

  return await API(obj);
});

export const editCompanyService = createAsyncThunk('editCompanyService', async (payload) => {
  const formData = new FormData();
  formData.append('name', payload?.name);
  formData.append('desc', payload?.desc);
  formData.append('company_page', payload?.pageId);
  let imageURL = payload?.image;
  if (imageURL instanceof File) {
    formData.append('image', payload?.image);
  }

  const obj = {
    url: `${CompanyAPIEndpoints.CompaniesAPI}${payload.pageId}/services/${payload.id}/`,
    method: 'PUT',
    body: formData,
    isFormData: true,
  };
  return await API(obj);
});

export const deleteCompanyService = createAsyncThunk('deleteCompanyService', async (payload) => {
  const obj = {
    url: `${CompanyAPIEndpoints.CompaniesAPI}${payload.pageId}/services/${payload.id}/`,
    method: 'DELETE',
  };
  return await API(obj);
});

export const uploadBrochure = createAsyncThunk('uploadBrochure', async (payload) => {
  const formData = new FormData();
  for (const key in payload) {
    if (payload[key] === '' || payload[key] === undefined || payload[key] === null) {
      continue;
    }
    formData.append(key, payload[key]);
  }
  const obj = {
    url: `${CompanyAPIEndpoints.CompanyBrochure}upload/`,
    method: 'POST',
    body: formData,
    isFormData: true,
  };
  return await API(obj);
});
export const deleteBrochure = createAsyncThunk('deleteBrochure', async (payload) => {
  const formData = new FormData();
  for (const key in payload) {
    if (payload[key] === '' || payload[key] === undefined || payload[key] === null) {
      continue;
    }
    formData.append(key, payload[key]);
  }
  const obj = {
    url: `${CompanyAPIEndpoints.CompanyBrochure}remove/`,
    method: 'POST',
    body: formData,
    isFormData: true,
  };
  return await API(obj);
});

export const followCompanyPage = createAsyncThunk('followCompanyPage', async (id) => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.FollowCompanyApi(id)}`,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

// Define and export clearCompanyPageDetail action
export const clearCompanyPageDetail = createAction('clearCompanyPageDetail');

export const unFollowCompanyPage = createAsyncThunk('unFollowCompanyPage', async (id) => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.UnFollowCompanyApi(id)}`,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const setInfoPermissions = createAsyncThunk(
  'setInfoPermissions ',
  async (body) => {
    console.log('setInfoPermissions', body);
    const obj = {
      url:`${CompanyAPIEndpoints.AudiencePermissions}/company/update`,
      method: 'PUT',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const companyPageActivity = createAsyncThunk('companyPageActivity', async (id) => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompanyActivity(id)}`,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const checkSlugAvalability = createAsyncThunk('checkSlugAvalability', async (slug) => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.SlugCheck}?slug=${slug}`,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export  const reportPublicCompany = createAsyncThunk('reportPublicCompany', async (payload) => {
  const obj = {
    url: `${CompanyAPIEndpoints.ReportCompanyAPI}`,
    method: 'POST',
    body: jsonToFormData(payload),
    isFormData:true
  };
  return await API(obj);
})


export const aggregatedPhotosCount = createAsyncThunk('aggregatedPhotosData', async (body) => {
  const obj = {
    url: `${CompanyAPIEndpoints.getAggregatedPhotosCount(body)}`,
    method: 'GET',
  };
  return await API(obj);
});

export const getPhotosOfYou = createAsyncThunk('getPhotosOfYou', async (body) => {
  const obj = {
    url: `${CompanyAPIEndpoints.getPhotosOfYou(body)}`,
    method: 'GET',
  };
  return await API(obj);
})

export const getYourPhotos = createAsyncThunk('getYourPhotos', async (body) => {
  const obj = {
    url: `${CompanyAPIEndpoints.getYourPhotos(body)}`,
    method: 'GET',
  };
  return await API(obj);
})

export const getCoverPhotos = createAsyncThunk('getCoverPhotos', async (body) => {
  const obj = {
    url: `${CompanyAPIEndpoints.getCoverPhotos(body)}`,
    method: 'GET',
  };
  return await API(obj);
})

export const getProfilePhotos = createAsyncThunk('getProfilePhotos', async (body) => {
  const obj = {
    url: `${CompanyAPIEndpoints.getProfilePhotos(body)}`,
    method: 'GET',
  };
  return await API(obj);
})

export const getYourVideos = createAsyncThunk('getYourVideos', async (body) => {
  const obj = {
    url: `${CompanyAPIEndpoints.getYourVideos(body)}`,
    method: 'GET',
  };
  return await API(obj);
})

export const getVideosOfYour = createAsyncThunk('getVideosOfYour', async (body) => {
  const obj = {
    url: `${APIEndPoints.getVideosOfYour(body)}`,
    method: 'GET',
  };
  return await API(obj);
})
export const setCompanyPageDetail = createAction('setCompanyPageDetail');
