import { createContext, useEffect, useRef, useState } from 'react';

export const MultiStepFormContext = createContext({});

export const useMultiStepsFormContext = (props) => {
  const { active: active_ } = props ?? {};
  const tid = useRef(-1);
  const [steps, setSteps] = useState([]);
  const steps_ = useRef([]);
  const [active, setActive] = useState(active_);
  const [activeIndex, setActiveIndex] = useState(active_);

  const [formData, setFormData] = useState({});

  useEffect(() => {
    clearTimeout(tid.current);
    tid.current = setTimeout(() => {
      console.log(steps_.current);
      setSteps(steps_.current);
    }, 0);
  }, [steps_.current]);

  useEffect(() => {
    if (!active) {
      setActive(steps?.[0]);
    }
  }, [steps]);

  useEffect(() => {
    if (steps.length > 0) {
      setActiveIndex(steps.indexOf(active));
    }
  }, [active, steps]);

  const register = (name) => {
    if (!steps_.current?.includes(name)) {
      steps_.current.push(name);
    }
  };
  const unregister = (name) => {
    steps_.current = steps_.current.filter((s) => s !== name);
  };

  const next = () => {
    const current = steps.indexOf(active);
    if (current <= steps.length - 2) {
      setActive(steps[current + 1]);
    }
  };

  const back = () => {
    const current = steps.indexOf(active);
    if (current >= 1) {
      setActive(steps[current - 1]);
    }
  };

  const setFields = (fields) => {
    const fd = { ...(formData ?? {}), ...(fields ?? {}) };
    setFormData({ ...fd });
  };

  return {
    ...(props ?? {}),
    active,
    setActive,
    steps,
    register,
    unregister,
    next,
    back,
    activeIndex,
    setFields,
    formData,
  };
};
