/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../common/api';
import { APIEndPoints, UserAPIEndpoints } from '../common/endPoint';
import { jsonToFormData } from 'v4/lib/utils';
import axios from 'axios';
import axiosInstance from '../common/axios';

export const addExperience = createAsyncThunk('addExperience', async (body) => {
  const obj = {
    url: `${APIEndPoints.ProfileExperience}/`,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const updateExperience = createAsyncThunk(
  'updateExperience',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.ProfileExperience}/${body.id}/`,
      method: 'PUT',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const getExperience = createAsyncThunk('getExperience', async (body) => {
  const obj = {
    url: `${APIEndPoints.ProfileExperience}/`,
    method: 'GET',
  };
  return await API(obj);
});

export const getMyActivity = createAsyncThunk(
  'getMyActivity',
  async (payload) => {
    const { page = 1, pageSize = 1 } = payload ?? {};
    const obj = {
      url: `${APIEndPoints.ProfileActivity({ page, pageSize })}`,
      method: 'GET',
    };
    return await API(obj);
  }
);

export const getPublicActivity = createAsyncThunk(
  'getMyActivity',
  async (payload) => {
    const { page = 1, pageSize = 1, userId } = payload ?? {};
    const obj = {
      url: `${APIEndPoints.PublicProfileActivity({ page, pageSize, userId })}`,
      method: 'GET',
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const deleteExperience = createAsyncThunk(
  'deleteExperience',
  async (id) => {
    const obj = {
      url: `${APIEndPoints.ProfileExperience}/${id}/`,
      method: 'DELETE',
    };
    return await API(obj);
  }
);

export const userProfileAddInfo = createAsyncThunk(
  'userProfileAddInfo',
  async (body, isFormData = false) => {
    console.log('body', body);
    const obj = {
      url: `${APIEndPoints.UserProfileInfo}/`,
      method: 'PUT',
      // body: jsonToFormData(body),
      body: isFormData ? jsonToFormData(body) : body,
      isFormData,
    };
    return await API(obj);
  }
);

export const getUserProfileInfo = createAsyncThunk(
  'getUserProfileInfo',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.UserProfileInfo}/`,
      method: 'GET',
    };
    return await API(obj);
  }
);

export const addMilestone = createAsyncThunk('addMilestone', async (body) => {
  const obj = {
    url: `${APIEndPoints.Milestone}/`,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const unTagPost = createAsyncThunk('unTagPost', async (body) => {
  const obj = {
    url: `${APIEndPoints.PostUntag}/${body}`,
    method: 'PUT',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const updateMilestone = createAsyncThunk(
  'updateMilestone',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.Milestone}/${body.id}/`,
      method: 'PUT',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const getMilestone = createAsyncThunk('getMilestone', async (body) => {
  const obj = {
    url: `${APIEndPoints.Milestone}/`,
    method: 'GET',
  };
  return await API(obj);
});

export const deleteMilestone = createAsyncThunk(
  'deleteMilestone',
  async (id) => {
    const obj = {
      url: `${APIEndPoints.Milestone}/${id}/`,
      method: 'DELETE',
    };
    return await API(obj);
  }
);
//analytics
export const getUserAnalytics = createAsyncThunk(
  'getUserAnalytics',
  async (id) => {
    const obj = {
      url: `${APIEndPoints.UserAnalytics}`,
      method: 'GET',
    };
    return await API(obj);
  }
);
// Skills
export const getProfileSkills = createAsyncThunk(
  'getProfileSkills',
  async () => {
    const obj = {
      url: `${APIEndPoints.ProfileSkills}`,
      method: 'GET',
    };
    return await API(obj);
  }
);

export const addSkill = createAsyncThunk('addSkill', async (body) => {
  const obj = {
    url: `${APIEndPoints.ProfileSkills}`,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const deleteSkill = createAsyncThunk('deleteSkill', async (id) => {
  const obj = {
    url: `${APIEndPoints.ProfileSkills}${id}/`,
    method: 'DELETE',
  };
  return await API(obj);
});

export const deleteManySkills = createAsyncThunk(
  'deleteManySkills',
  async (payload) => {
    const obj = {
      url: `${APIEndPoints.ProfileSkills}`,
      method: 'DELETE',
      body: JSON.stringify({ skill_ids: payload }),
    };
    return await API(obj);
  }
);

export const endorseSkill = createAsyncThunk('endorseSkill', async (id) => {
  const obj = {
    url: `${APIEndPoints.ProfileSkills}${id}/endorse`,
    method: 'POST',
  };
  return await API(obj);
});

export const endorseTestimonial = createAsyncThunk(
  'endorseTestimonial',
  async (id) => {
    const obj = {
      url: `${APIEndPoints.Testimonial}${id}/endorse`,
      method: 'POST',
    };
    return await API(obj);
  }
);

/* certificate */

export const addCertificate = createAsyncThunk(
  'addCertificate',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.Certificate}/`,
      method: 'POST',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const updateCertificate = createAsyncThunk(
  'updateCertificate',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.Certificate}/${body.id}/`,
      method: 'PUT',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const getCertificate = createAsyncThunk(
  'getCertificate',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.Certificate}/`,
      method: 'GET',
    };
    return await API(obj);
  }
);

export const deleteCertificate = createAsyncThunk(
  'deleteCertificate',
  async (id) => {
    const obj = {
      url: `${APIEndPoints.Certificate}/${id}/`,
      method: 'DELETE',
    };
    return await API(obj);
  }
);

/* Education */

export const getEducation = createAsyncThunk('getEducation', async () => {
  const obj = {
    url: `${APIEndPoints.Education}/`,
    method: 'GET',
  };
  return await API(obj);
});

export const deleteEduction = createAsyncThunk('deleteEduction', async (id) => {
  const obj = {
    url: `${APIEndPoints.Education}/${id}/`,
    method: 'DELETE',
  };
  return await API(obj);
});

export const addEducation = createAsyncThunk('addEducation', async (body) => {
  const obj = {
    url: `${APIEndPoints.Education}/`,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});

export const updateEducation = createAsyncThunk(
  'updateEducation',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.Education}/${body.id}/`,
      method: 'PUT',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

// All Search API's
export const searchByTerm = createAsyncThunk(
  'searchByTerm',
  async ({ searchBy, term }) => {
    const obj = {
      url: `${APIEndPoints[searchBy]}/?search=${term}`,
      method: 'GET',
    };
    return await API(obj);
  }
);

export const getIndustries = createAsyncThunk('getIndustries', async () => {
  const obj = {
    url: `${APIEndPoints.Industries}/`,
    method: 'GET',
  };
  return await API(obj);
});

// Get Slug
export const getUserSlug = createAsyncThunk('getUserSlug', async (slug) => {
  const obj = {
    url: `${APIEndPoints.Slug}?search=${slug}`,
  };
  return await API(obj);
});

/* User Details Update Settings */
export const updateUserDetails = createAsyncThunk(
  'updateUserDetails',
  async (body) => {
    const obj = {
      url: `${UserAPIEndpoints.UpdateUser}`,
      method: 'PUT',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

/* User Details Update General */
export const updateUserInfo = createAsyncThunk(
  'updateUserInfo',
  async (body) => {
    const obj = {
      url: `${UserAPIEndpoints.UpdateUserInfo}`,
      method: 'PUT',
      body: jsonToFormData(body),
      isFormData: true,
    };
    return await API(obj);
  }
);

// Public Profile
export const getPublicProfile = createAsyncThunk(
  'getPublicProfile',
  async (slug) => {
    const token = localStorage.getItem('access');
    const obj = {
      url: `${APIEndPoints.PublicProfile}?slug=${slug}`,
      isNoToken: token ? false : true,
    };
    return await API(obj);
  }
);

export const getProfileViewers = createAsyncThunk(
  'getProfileViewers',
  async (body) => {
    const obj = {
      url: `${UserAPIEndpoints.GetProfileViews(body)}`,
      method: 'GET',
    };
    return await API(obj);
  }
);

/* User Connection APIs */
export const getUserConnectionCount = createAsyncThunk(
  'getUserConnectionCount',
  async (degree) => {
    const obj = {
      url: `${UserAPIEndpoints.GetDegreeCount(degree)}`,
    };
    return await API(obj);
  }
);

/* User Chat Details by Matrix ID */
export const getUserDetailsByChatId = createAsyncThunk(
  'getUserDetailsByChatId ',
  async (userId) => {
    const obj = {
      url: `${UserAPIEndpoints.UserByChatId}?matrix_user_id=${userId}`,
    };
    return await API(obj);
  }
);

/* Get user status information */
export const getUserPresenceStatus = createAsyncThunk(
  'getUserPresenceStatus ',
  async () => {
    const obj = {
      url: `${UserAPIEndpoints.UserPresenceStatus}`,
    };
    return await API(obj);
  }
);

/* Get user pdf file for profile information */
export const getUserInfoPDF = createAsyncThunk('getUserInfoPDF ', async () => {
  const token = localStorage.getItem('access');
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(
      `${process.env.BASE_API_URL}${UserAPIEndpoints.DownloadProfilePDF}`,
      { headers, responseType: 'blob' },
    );
    console.log('response', response);
    // Create a Blob from the response data
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(pdfBlob);
    return url;
  } catch (e) {
    console.error(e);
  }
});

/* Update user status information */
export const updateUserPresenceStatus = createAsyncThunk(
  'updateUserPresenceStatus ',
  async (body) => {
    const obj = {
      url: `${UserAPIEndpoints.UserPresenceStatus}`,
      method: 'PUT',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

/* Get AI Suggestions for About Me */
export const getAiSuggestedIntro = createAsyncThunk(
  'getAiSuggestedIntro ',
  async (body) => {
    const obj = {
      url: `${UserAPIEndpoints.AboutMeAISuggest}`,
      method: 'PUT',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

/* Get AI Suggestions for About Me */
export const setInfoPermissions = createAsyncThunk(
  'setInfoPermissions ',
  async (body) => {
    const obj = {
      url:
        body?.attribute === 'experience' ||
        body?.attribute === 'education' ||
        body?.attribute === 'certs' ||
        body?.attribute === 'milestone'
          ? `${UserAPIEndpoints.AudiencePermissions}/${body?.attribute}/update`
          : `${UserAPIEndpoints.AudiencePermissions}/profile/update`,
      method: 'PUT',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);


/* Get AI Suggestions for About Me */
export const setAccountPermissions = createAsyncThunk(
  'setAccountPermissions ',
  async (body) => {
    const obj = {
      url:`${UserAPIEndpoints.AudienceVisibilityPermissions}`,
      method: 'PUT',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);


export const aggregatedPhotosCount = createAsyncThunk('aggregatedPhotosData', async (body) => {
  const obj = {
    url: `${APIEndPoints.getAggregatedPhotosCount(body)}`,
    method: 'GET',
  };
  return await API(obj);
});

export const getPhotosOfYou = createAsyncThunk('getPhotosOfYou', async (body) => {
  const obj = {
    url: `${APIEndPoints.getPhotosOfYou(body)}`,
    method: 'GET',
  };
  return await API(obj);
})

export const getYourPhotos = createAsyncThunk('getYourPhotos', async (body) => {
  const obj = {
    url: `${APIEndPoints.getYourPhotos(body)}`,
    method: 'GET',
  };
  return await API(obj);
})

export const getCoverPhotos = createAsyncThunk('getCoverPhotos', async (body) => {
  const obj = {
    url: `${APIEndPoints.getCoverPhotos(body)}`,
    method: 'GET',
  };
  return await API(obj);
})

export const getProfilePhotos = createAsyncThunk('getProfilePhotos', async (body) => {
  const obj = {
    url: `${APIEndPoints.getProfilePhotos(body)}`,
    method: 'GET',
  };
  return await API(obj);
})

export const getYourVideos = createAsyncThunk('getYourVideos', async (body) => {
  const obj = {
    url: `${APIEndPoints.getYourVideos(body)}`,
    method: 'GET',
  };
  return await API(obj);
})

export const getVideosOfYour = createAsyncThunk('getVideosOfYour', async (body) => {
  const obj = {
    url: `${APIEndPoints.getVideosOfYour(body)}`,
    method: 'GET',
  };
  return await API(obj);
})


export const clear = createAction('clear');
export const clearSuggestion = createAction('clearSuggestion');
export const currentProfile = createAction('currentProfile');
export const currentProfileComment = createAction('currentProfileComment');
