import { forwardRef } from 'react';
import { Box } from '../layout';
import ScrollContainer from 'react-custom-scroll';
export const Scrollable = forwardRef(({ children, ...props }, ref) => {
  const { maxH, maxHeight, height } = props ?? {};
  return (
    <>
      <Box {...props}>
        <ScrollContainer width="100%" allowOuterScroll={true} heightRelativeToParent={height ?? maxHeight ?? maxH}>
          {children}
        </ScrollContainer>
      </Box>
    </>
  );
});
