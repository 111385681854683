/* eslint-disable @nx/enforce-module-boundaries */
import { Button } from '@mybridge/ui/button';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@mybridge/ui/form-control';
import { useToast } from '@mybridge/ui/hooks';
import { Input, InputGroup, InputRightElement } from '@mybridge/ui/input';
import { IconButton } from '@mybridge/ui';
import { HStack, Stack } from '@mybridge/ui/layout';
import { Link } from '@mybridge/ui/link';
import { ListItem, UnorderedList } from '@mybridge/ui/list';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { Text } from '@mybridge/ui/text';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { DividerWithText } from 'v4/components/divider-with-text';
import {
  getMatrixToken,
  resendVerificationEmail,
  setLoggedIn,
  setOpenAuthModal,
} from 'v4/store/actions/auth.actions';
import API from 'v4/store/common/api';
import { APIEndPoints } from 'v4/store/common/endPoint';
import GoogleLoginButton from '../google-login-btn';
import AppleLoginButton from '../apple-login-btn';
import { EyeViewIcon,EyeViewFilledIcon } from '@mybridge/icons';
export const SignupModal = ({ ...props }) => {
  const { push } = useRouter();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const toast = useToast({
    position: 'top',
    isClosable: true,
  });

  const onFormSubmit = async (form) => {

    const payload = {
      first_name: form?.firstName,
      last_name: form?.lastName,
      email: form?.email,
      password: form?.password,
      password2: form?.confirmPassword,
    };
    setLoading(true);
    try {
      const obj = {
        url: `${APIEndPoints.Register}`,
        method: 'POST',
        body: JSON.stringify(payload),
        isNoToken: true,
      };

      const res = await API(obj);
      const { status_code } = res ?? {};
      if (status_code >= 400) {
        let title = 'Error while registering!';
        const { email } = res ?? {};
        if (email.length) {
          title = email?.[0];
        }
        toast({
          title,
          status: 'error',
        });
        return;
      }
      toast({
        title: 'Registration was successful',
        status: 'success',
      });

      // send verification mail
      dispatch(resendVerificationEmail({ email: form?.email }));

      if (typeof window !== 'undefined') {
        localStorage.setItem('access', res.access);
        localStorage.setItem('refresh', res.refresh);
        dispatch(setLoggedIn(true));
      }
      props?.onClose?.();
    } catch (e) {
      toast({
        title: 'Error while registering!',
        description: e?.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
      dispatch(getMatrixToken());
    }
  };

  return (
    <Modal size="lg" isCentered {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sign Up</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <ModalBody pb={6}>
            <Stack>
              <HStack alignItems="flex-start">
                <FormControl isInvalid={errors?.firstName}>
                  <FormLabel>First name</FormLabel>
                  <Input
                    autoFocus
                    placeholder="First name"
                    {...register('firstName', { required: true })}
                  />
                  {errors?.firstName && (
                    <FormErrorMessage>First name is required</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl isInvalid={errors?.lastName}>
                  <FormLabel>Last name</FormLabel>
                  <Input
                    placeholder="Last name"
                    {...register('lastName', { required: true })}
                  />
                  {errors?.lastName ? (
                    <FormErrorMessage>Last name is required</FormErrorMessage>
                  ) : (
                    ''
                  )}
                </FormControl>
              </HStack>
              <FormControl isInvalid={errors?.email}>
                <FormLabel>Email</FormLabel>
                <Input
                  placeholder="Email"
                  type="email"
                  {...register('email', {
                    required: true,
                    pattern:
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  })}
                />
                {errors?.email ? (
                  <FormErrorMessage>
                    Please enter a valid Email ID
                  </FormErrorMessage>
                ) : (
                  ''
                )}
                {error?.email?.length ? (
                  <FormErrorMessage>{error?.email?.[0]}</FormErrorMessage>
                ) : (
                  ''
                )}
              </FormControl>
              <FormControl isInvalid={errors?.password}>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    {...register('password', {
                      required: true,
                      minLength: 8,
                      pattern:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    })}
                  />
                  <InputRightElement>
                    <IconButton
                      aria-label={showPassword ? 'Hide password' : 'Show password'}
                      icon={showPassword ? <EyeViewFilledIcon /> : <EyeViewIcon />}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </InputRightElement>
                </InputGroup>
                {errors?.password ? (
                  <FormErrorMessage>
                    <Stack>
                      <Text color="red.500">Please enter a valid password</Text>
                      <UnorderedList>
                        <ListItem>Min 8 characters</ListItem>
                        <ListItem>
                          At least 1 uppercase, 1 lowercase & 1 special
                          character
                        </ListItem>
                        <ListItem>At least 1 numeric</ListItem>
                      </UnorderedList>
                    </Stack>
                  </FormErrorMessage>
                ) : (
                  ''
                )}
              </FormControl>
              <FormControl isInvalid={errors?.confirmPassword}>
                <FormLabel>Confirm Password</FormLabel>
                <InputGroup>
                  <Input
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="Confirm Password"
                    {...register('confirmPassword', {
                      required: true,
                      deps: 'password',
                      validate: (val) => val === watch('password'),
                    })}
                  />
                  <InputRightElement>
                    <IconButton
                      aria-label={showConfirmPassword ? 'Hide password' : 'Show password'}
                      icon={showConfirmPassword ? <EyeViewFilledIcon /> : <EyeViewIcon />}
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    />
                  </InputRightElement>
                </InputGroup>
                {errors?.confirmPassword ? (
                  <FormErrorMessage>
                    Please confirm your password
                  </FormErrorMessage>
                ) : (
                  ''
                )}
              </FormControl>
            </Stack>
            <Stack mt={4} spacing={4} flex={1}>
              <Button
                // py={6}
                fontWeight="bold"
                w="100%"
                type="submit"
                variant="primary"
                isLoading={loading}
              >
                Sign Up
              </Button>
              <HStack justifyContent="center">
                <Text
                  color="brandGray.700"
                  fontWeight="medium"
                  textAlign="center"
                >
                  Already on myBridge? &nbsp;
                  <Link
                    color="brandPrimary.500"
                    display="inline"
                    onClick={() => {
                      dispatch(setOpenAuthModal('sign-in'));
                    }}
                  >
                    Sign In
                  </Link>
                </Text>
              </HStack>
              <DividerWithText>
                <Text fontWeight="bold" p={2}>Or</Text>
              </DividerWithText>
              <GoogleLoginButton path="signup" />
              <AppleLoginButton path="signin" />
            </Stack>
          </ModalBody>

          {/* <ModalFooter justifyContent="center"></ModalFooter> */}
        </form>
      </ModalContent>
    </Modal>
  );
};
