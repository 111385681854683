import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const defaultInputFile = definePartsStyle({
  // define the part you're going to style
  field: {
    paddingLeft: "0px",
  },
})

export const fileUploadTheme = defineMultiStyleConfig({ defaultProps: {
  variant: 'defaultInputFile',
}, variants: { defaultInputFile }, })