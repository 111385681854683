import { useEffect, useState } from 'react';
import { Link } from '../link';
import { Text } from '../text';
import { Box } from '../layout';
import classNames from 'classnames';

export const SeeMore = ({
  maxLength = 150,
  text,
  html,
  maxHeight = 20,
  linkProps,
  toViewDetails,
  toRedirect = false,
  isSeeMoreLinkVisible= true,
  fontFamily,
  className,
  color,
  isInline = false,
  ...props
}) => {
  const [text_, setText] = useState();
  const [html_, setHtml] = useState();


  const [show, setShow] = useState(false);
  const handleSeeMore = (event) => {
    if (linkProps?.href?.length) return;
    event.preventDefault();
    if (toRedirect) {
      toViewDetails();
    } else {
      setShow((ps) => !ps);
    }
  };
  useEffect(() => {
    if (show) {
      setText(text);
      setHtml(html);
      return;
    }
    if (maxLength && text?.length > maxLength) {
      setText(text?.substr(0, maxLength ?? text?.length - 1));
    } else {
      setText(text);
    }
    if (maxLength && html?.length > maxLength) {
      setHtml(html?.substr(0, maxLength ?? html?.length - 1));
    } else {
      setHtml(html);
    }
  }, [text, maxLength, show, html]);



  
  if (html?.length > 0) {
    return (
      <>
        <Box
         className={className}
         color={color}
         fontFamily={fontFamily}
          overflowY="hidden"
          maxH={show ? 'auto' : maxHeight}
          overflowWrap="anywhere"
          dangerouslySetInnerHTML={{ __html: html_}}
          {...props}
        />
        {html?.length > maxLength  && isSeeMoreLinkVisible? (
          <Text
            display="inline"
            style={{cursor:'pointer'}}
            color="brandPrimary.500"
            onClick={(event) => handleSeeMore(event)}
            {...(linkProps ?? {})}
          >
            {show ? '  see less' : '  ...see more'}
          </Text>
        ) : (
          ''
        )}
      </>
    );
  }

  if (isInline) {
    return (
      <Text overflowWrap="anywhere" {...props}>
        <Text
          style={{cursor:'pointer'}}
          color="brandPrimary.500"
          // cursor={}
          onClick={(event) => handleSeeMore(event)}
          {...(linkProps ?? {})}
        >
          {text_} {text?.length > maxLength ? (<>{show ? '  see less' : '  ...see more'}</>) : ''}
        </Text>
     
    </Text>
    );
  }
  return (
    <Text overflowWrap="anywhere" {...props}>
      {text_}
      {text?.length > maxLength ? (
        <Text
          style={{cursor:'pointer'}}
          color="brandPrimary.500"
          // cursor={}
          onClick={(event) => handleSeeMore(event)}
          {...(linkProps ?? {})}
        >
          {show ? '  see less' : '  ...see more'}
        </Text>
      ) : (
        ''
      )}
    </Text>
  );
};
