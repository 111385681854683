/* eslint-disable @nx/enforce-module-boundaries */
import { APIEndPoints } from './../common/endPoint';
import axiosInstance from 'v4/store/common/axiosApi';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../common/api';
import { jsonToFormData } from 'v4/lib/utils';

export const setOpenFolderItem = createAction('Open_Folder');
export const setSelectedFile = createAction('selectedFile');
export const setSelectedUser = createAction('setSelectedUser');
export const addFilesToUpload = createAction('addFilesToUpload');
export const removeFilesToUpload = createAction('removeFilesToUpload');
export const setUploadSuccess = createAction('setUploadSuccess');
export const setFilesToUpload = createAction('setFilesToUpload');
export const updateFileToUpload = createAction('updateFileToUpload');
export const showFilesUploadModal = createAction('showFilesUploadModal');
export const hideFilesUploadModal = createAction('hideFilesUploadModal');
export const cleanDownloadedFile = createAction('cleanDownloadFile');
export const startFileDownload = createAction('startFileDownload');
export const finishFileDownload = createAction('finishFileDownload');
export const setDeleteFolderSeccess = createAction('setDeleteFolderSeccess');
export const setDeleteSeccess = createAction('setDeleteSeccess');
export const setTeamDiskSubscriberChanged = createAction(
  'setTeamDiskSubscriberChanged'
);

export const getRootDir = createAsyncThunk('GET_ROOT_DIR', async (opts) => {
  const { dirName } = opts ?? {};
  const obj = {
    url: `${APIEndPoints.MyDisk_Root}`,
    method: 'GET',
  };
  const response = await API(obj);
  return response;
});

export const initRootDirs = createAsyncThunk('INIT_ROOT_DIR', async () => {
  const obj = {
    url: `${APIEndPoints.MyDisk_Root}?dir_name=root`,
    method: 'GET',
  };
  const response = await API(obj);
  return response;
});

export const getTeamDiskDetails = createAsyncThunk(
  'GET_TEAM_DISK_DETAILS',
  async (id) => {
    const obj = {
      url: `${APIEndPoints.TeamDisk}?for_team_community_id=${id}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  }
);

export const getfileNFolder = createAsyncThunk(
  'GET_FILE_FOLDERS',
  async (payload) => {
    try {
      const { parent, team_id } = payload ?? {};
      if (payload.parent) {
        const obj = {
          url: `${APIEndPoints.MyDisk}?parent=${parent}&${
            team_id ? `team_id=${team_id}` : ''
          }`,
          method: 'GET',
        };
        const response = await API(obj);
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const getTeamFileNFolder = createAsyncThunk(
  'getTeamFileNFolder',
  async (payload) => {
    try {
      if (payload.parent) {
        const obj = {
          url: `${APIEndPoints.MyDisk}?parent=${payload.parent}`,
          method: 'GET',
        };
        const response = await API(obj);
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const getSubfileNFolder = createAsyncThunk(
  'GET_FILE_FOLDERS_SUB',
  async (payload) => {
    try {
      const obj = {
        url: `${APIEndPoints.MyDisk}?parent=${payload.parent}`,
        method: 'GET',
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getfileNFolderCopyMove = createAsyncThunk(
  'COPY_MOVE_FOLDER-LIST',
  async (payload) => {
    try {
      const obj = {
        url: `${APIEndPoints.MyDisk}?parent=${payload.parent}`,
        method: 'GET',
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getDeleteFiles = createAsyncThunk(
  'getDeleteFiles',
  async (payload) => {
    try {
      const obj = {
        url: `${APIEndPoints.MyDisk}?is_deleted=${payload.is_deleted}`,
        method: 'GET',
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);
// File Actions
export const uploadFile = createAsyncThunk('UploadFile', async (payload) => {
  try {
    const token = localStorage.getItem('access');
    const headers = {
      'Content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    };
    const response = await axiosInstance.post(
      `${APIEndPoints.MyDisk_Files}`,
      payload,
      { headers }
    );
    return response;
  } catch (error) {
    return error;
  }
});
export const uploadFileByIndex = createAsyncThunk(
  'UploadFileByIndex',
  async ({ payload, index }) => {
    try {
      const token = localStorage.getItem('access');
      const headers = {
        'Content-type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      };
      const response = await axiosInstance.post(
        `${APIEndPoints.MyDisk_Files}`,
        payload,
        { headers }
      );
      return { response, index };
    } catch (error) {
      return { error, index };
    }
  }
);
export const deleteFile = createAsyncThunk('deleteFile', async (id) => {
  try {
    const obj = {
      url: `${APIEndPoints.MyDisk_Files}${id}/`,
      method: 'DELETE',
    };
    const response = await API(obj);
    return { id, response };
  } catch (error) {
    return error;
  }
});
export const deleteMultipleFiles = createAsyncThunk(
  'deleteMultipleFiles',
  async (payload) => {
    try {
      const obj = {
        url: `${APIEndPoints.MyDiskRoot}multifiles/?id_list=[${payload?.join?.(
          ','
        )}]`,
        method: 'DELETE',
      };
      const response = await API(obj);
      return { id, response };
    } catch (error) {
      return error;
    }
  }
);
export const copyMultipleFiles = createAsyncThunk(
  'copyMultipleFiles',
  async (payload) => {
    const { parent, ids } = payload ?? {};
    try {
      const obj = {
        url: `${
          APIEndPoints.MyDiskRoot
        }multifiles/?act=copy&parent=${parent}&id_list=[${ids?.join?.(',')}]`,
        method: 'GET',
      };
      const response = await API(obj);
      return { id, response };
    } catch (error) {
      return error;
    }
  }
);
export const restoreMultipleFiles = createAsyncThunk(
  'restoreMultipleFiles',
  async (payload) => {
    try {
      const obj = {
        url: `${
          APIEndPoints.MyDiskRoot
        }multifiles/?is_deleted=false&id_list=[${payload?.join?.(',')}]`,
        method: 'PUT',
      };
      const response = await API(obj);
      return { id, response };
    } catch (error) {
      return error;
    }
  }
);
export const moveMultipleFiles = createAsyncThunk(
  'moveMultipleFiles',
  async (payload) => {
    const { parent, ids } = payload ?? {};
    console.log('parent', parent);  
    try {
      const obj = {
        url: `${APIEndPoints.MyDiskRoot}multidir/?id_list=[${ids?.join?.(',')}]`,
        method: 'PUT',
        // body: jsonToFormData({parent:parent}),
        body: JSON.stringify({parent:parent}),
      };
      const response = await API(obj);
      return { id, response };
    } catch (error) {
      return error;
    }
  }
);
export const deleteMultipleDirs = createAsyncThunk(
  'deleteMultipleDirs',
  async (payload) => {
    try {
      const obj = {
        url: `${APIEndPoints.MyDiskRoot}multidir/?id_list=[${payload?.join?.(
          ','
        )}]`,
        method: 'DELETE',
      };
      const response = await API(obj);
      return { id, response };
    } catch (error) {
      return error;
    }
  }
);
export const copyMultipleDirs = createAsyncThunk(
  'copyMultipleDirs',
  async (payload) => {
    const { parent, ids } = payload ?? {};
    try {
      const obj = {
        url: `${
          APIEndPoints.MyDiskRoot
        }multidir/?act=copy&parent=${parent}&id_list=[${ids?.join?.(',')}]`,
        method: 'GET',
      };
      const response = await API(obj);
      return { id, response };
    } catch (error) {
      return error;
    }
  }
);
export const restoreMultipleDirs = createAsyncThunk(
  'restoreMultipleDirs',
  async (payload) => {
    try {
      const obj = {
        url: `${
          APIEndPoints.MyDiskRoot
        }multidir/?is_deleted=false&id_list=[${payload?.join?.(',')}]`,
        method: 'PUT',
      };
      const response = await API(obj);
      return { id, response };
    } catch (error) {
      return error;
    }
  }
);
export const moveMultipleDirs = createAsyncThunk(
  'moveMultipleDirs',
  async (payload) => {
    const { parent, ids } = payload ?? {};
    try {
      const obj = {
        url: `${APIEndPoints.MyDiskRoot}multidir/`,
        method: 'PUT',
        body: { parent, id_list: ids },
      };
      const response = await API(obj);
      return { id, response };
    } catch (error) {
      return error;
    }
  }
);
export const renameFiles = createAsyncThunk('renameFile', async (payload) => {
  try {
    const { id, name } = payload;
    let formdata = new FormData();
    formdata.append('name', name);
    // const finalData = {'name': name};

    // const response = await axiosInstance.put(`${APIEndPoints.MyDisk_Files}${id}/`, JSON.stringify(finalData), headers);
    const obj = {
      url: `${APIEndPoints.MyDisk_Files}${payload.id}/`,
      method: 'PUT',
      body: formdata,
      isFormData: true,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});
export const restoreFile = createAsyncThunk('restoreFile', async (id) => {
  try {
    let payload = new FormData();
    payload.append('is_deleted', false);

    const obj = {
      url: `${APIEndPoints.MyDisk_Files}${id}/`,
      method: 'PUT',
      body: payload,
      isFormData: true,
    };
    const response = await API(obj);

    return response;
  } catch (error) {
    return error;
  }
});
export const moveFiles = createAsyncThunk('moveFile', async (payload) => {
  try {
    const { id, parent } = payload;
    let formData = new FormData();
    formData.append('id', id);
    formData.append('parent', parent);
    const obj = {
      url: `${APIEndPoints.MyDisk_Files}${payload.id}/`,
      method: 'PUT',
      body: formData,
      isFormData: true,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
});
export const copyFiles = createAsyncThunk('copyFile', async (payload) => {
  try {
    const obj = {
      url: `${APIEndPoints.MyDisk_Files}${payload.id}/?act=copy&parent=${payload.parent}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

// Folder Actions
export const createFolder = createAsyncThunk(
  'createFolder',
  async (payload) => {
    try {
      const obj = {
        url: `${APIEndPoints.MyDisk}`,
        method: 'POST',
        body: JSON.stringify(payload),
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Team Folder Actions
export const teamDiskCreateFolder = createAsyncThunk(
  'teamDiskCreateFolder',
  async (payload) => {
    try {
      const obj = {
        url: `${APIEndPoints.TeamDisk}`,
        method: 'POST',
        body: JSON.stringify(payload),
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const deleteFolder = createAsyncThunk('deleteFolder', async (id) => {
  const obj = {
    url: `${APIEndPoints.MyDisk_Folder}${id}/`,
    method: 'DELETE',
  };
  const response = await API(obj);
  return { id, response };
});
export const renameFolder = createAsyncThunk(
  'renameFolder',
  async (payload) => {
    try {
      const obj = {
        url: `${APIEndPoints.MyDisk_Folder}${payload.id}/`,
        method: 'PUT',
        body: JSON.stringify(payload),
      };
      // const response = await axiosInstance.put(`${APIEndPoints.MyDisk_Folder}${payload.id}/`, payload, headers);
      const response = await API(obj);

      return response;
    } catch (error) {
      return error;
    }
  }
);
export const restoreFolder = createAsyncThunk(
  'restoreFolder',
  async (payload) => {
    try {
      const obj = {
        url: `${APIEndPoints.MyDisk_Folder}${payload.id}/`,
        method: 'PUT',
        body: JSON.stringify(payload),
      };
      const response = await API(obj);

      // const headers = {
      //   'Content-type': 'multipart/form-data',
      //   Authorization: `Bearer ${token}`,
      // };
      // const response = await axiosInstance.put(`${APIEndPoints.MyDisk_Folder}${payload.id}/`, payload, headers);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const restoreMultiFilesFolder = createAsyncThunk(
  'restoreFolder',
  async (payload) => {
    let formdata = new FormData();
    formdata.append('is_deleted', false);
    try {
      const obj = {
        url: `${APIEndPoints.MyDisk_MuiltiFolder}?id_list=[${payload?.ids}]&is_deleted=false`,
        method: 'PUT',
        body: formdata,
        isFormData:true
      };
      const response = await API(obj);

      // const headers = {
      //   'Content-type': 'multipart/form-data',
      //   Authorization: `Bearer ${token}`,
      // };
      // const response = await axiosInstance.put(`${APIEndPoints.MyDisk_Folder}${payload.id}/`, payload, headers);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const permenantMultiFilesFolderDelete = createAsyncThunk(
  'permenantMultiFilesFolderDelete',
  async (payload) => {
    // let formdata = new FormData();
    // formdata.append('is_deleted', false);
    try {
      const obj = {
        url: `${APIEndPoints.MyDisk_MuiltiFiles}?id_list=[${payload?.ids}]&permanent=yes`,
        method: 'DELETE',
        // body: formdata,
        // isFormData:true
      };
      const response = await API(obj);

      // const headers = {
      //   'Content-type': 'multipart/form-data',
      //   Authorization: `Bearer ${token}`,
      // };
      // const response = await axiosInstance.put(`${APIEndPoints.MyDisk_Folder}${payload.id}/`, payload, headers);
      return response;
    } catch (error) {
      return error;
    }
  }
);



export const moveFolder = createAsyncThunk('moveFolder', async (payload) => {
  try {
    const obj = {
      url: `${APIEndPoints.MyDisk_Folder}${payload.id}/`,
      method: 'PUT',
      body: JSON.stringify(payload),
    };
    const response = await API(obj);

    // const headers = {
    //   'Content-type': 'multipart/form-data',
    //   Authorization: `Bearer ${token}`,
    // };
    // const response = await axiosInstance.put(`${APIEndPoints.MyDisk_Folder}${payload.id}/`, payload, headers);
    return response;
  } catch (error) {
    return error;
  }
});

export const searchFileFolder = createAsyncThunk(
  'searchFileFolder',
  async (payload) => {
    try {
      const { filters, str } = payload;

      const obj = {
        url: `${APIEndPoints.MyDisk_Search}?${str}`,
        method: 'GET',
      };
      const response = await API(obj);

      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  }
);

export const searchSharedFileFolder = createAsyncThunk(
  'searchFileFolder',
  async (payload) => {
    try {
      const { filters, str } = payload;

      const obj = {
        url: `${APIEndPoints.SharedDisk_Search}?${str}`,
        method: 'GET',
      };
      const response = await API(obj);

      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  }
);

export const downloadFile = createAsyncThunk(
  'downloadFile',
  async (file, { dispatch }) => {
    try {
      dispatch(startFileDownload());
      const obj = {
        // url: `${id}`,
        url: `${APIEndPoints.MyDisk_Files}${file?.id}/?act=download`,
        method: 'GET',
        isResponseJSON: false,
        // external:true
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      dispatch(finishFileDownload());
      return error;
    }
  }
);
// Share Disk
// Share Item
export const sharedDisk = createAsyncThunk('sharedDisk', async (payload) => {
  try {
    const obj = {
      url: `${APIEndPoints.shareDisk}${payload.id}/share`,
      method: 'POST',
      body: JSON.stringify(payload),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const removeFileShare = createAsyncThunk(
  'removeFileShare',
  async (payload) => {
    const { id, shared_with_id } = payload ?? {};
    try {
      const obj = {
        url: `${APIEndPoints.shareDisk}${id}/share`,
        method: 'DELETE',
        body: JSON.stringify({ shared_with_id }),
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const removeShare = createAsyncThunk('removeShare', async (id) => {
  try {
    const obj = {
      url: `${APIEndPoints.shareDisk}sharee/${id}`,
      method: 'DELETE',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getSharedDiskUsers = createAsyncThunk(
  'getSharedDiskUsers',
  async (payload) => {
    try {
      const obj = {
        url: `${APIEndPoints.shareDisk}users`,
        method: 'GET',
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getSharedDiskUsersByFile = createAsyncThunk(
  'getSharedDiskUsersByFile',
  async (payload) => {
    const { id } = payload ?? {};
    try {
      const obj = {
        url: `${APIEndPoints.shareDisk}${id}/share`,
        method: 'GET',
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getSharedItemUsers = createAsyncThunk(
  'getSharedItemUsers',
  async (id) => {
    try {
      const obj = {
        url: `${APIEndPoints.shareDisk}sharee/${id}`,
        method: 'GET',
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const createShareLink = createAsyncThunk(
  'createShareLink',
  async (payload) => {
    try {
      const obj = {
        url: `${APIEndPoints.shareDisk}${payload.id}/link`,
        method: 'POST',
        body: JSON.stringify(payload),
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const deleteShareLink = createAsyncThunk(
  'deleteShareLink',
  async (payload) => {
    try {
      const obj = {
        url: `${APIEndPoints.shareDisk}${payload.id}/link`,
        method: 'DELETE',
        body: JSON.stringify(payload),
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const acceptShareLink = createAsyncThunk(
  'acceptShareLink',
  async (payload) => {
    try {
      const obj = {
        url: `${APIEndPoints.shareDisk}${payload.id}/link/accept`,
        method: 'POST',
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getShareLink = createAsyncThunk(
  'getShareLink',
  async (payload) => {
    try {
      const obj = {
        url: `${APIEndPoints.shareDisk}${payload.id}/link`,
        method: 'GET',
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getDiskSubscriptionPlan = createAsyncThunk(
  'getDiskSubscriptionPlan',
  async () => {
    try {
      const obj = {
        url: `${APIEndPoints.diskSubscription}/plan/stripe/`,
        method: 'GET',
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const processDiskSubscription = createAsyncThunk(
  'processDiskSubscription',
  async (payload) => {
    try {
      const obj = {
        url: `${APIEndPoints.diskSubscription}/change/`,
        method: 'POST',
        body: JSON.stringify(payload),
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const diskSubscriptionInit = createAsyncThunk(
  'diskSubscriptionInit',
  async (payload) => {
    try {
      const obj = {
        url: `${APIEndPoints.diskSubscription}/init/`,
        method: 'GET',
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getUserSubscriptionDetails = createAsyncThunk(
  'getUserSubscriptionDetails',
  async () => {
    try {
      const obj = {
        url: `${APIEndPoints.diskSubscription}/`,
        method: 'GET',
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const getUserSubscriptionDetailsbyUser = createAsyncThunk(
  'getUserSubscriptionDetailsbyUser',
  async (user) => {
    try {
      const obj = {
        url: `${APIEndPoints.diskSubscription}/?user=${user}`,
        method: 'GET',
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getOccupiedDiskSize = createAsyncThunk(
  'getOccupiedDiskSize',
  async () => {
    try {
      const obj = {
        url: `${APIEndPoints.diskSize}`,
        method: 'GET',
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const changeTeamDiskSubscriber = createAsyncThunk(
  'changeTeamDiskSubscriber',
  async (payload) => {
    const obj = {
      url: `${APIEndPoints.TeamCommunityDisk}change/request/`,
      method: 'POST',
      body: JSON.stringify(payload),
    };
    const response = await API(obj);
    return response;
  }
);
export const cancelDiskSubscriptionChangeRequest = createAsyncThunk(
  'cancelDiskSubscriptionChangeRequest',
  async (payload) => {
    const obj = {
      url: `${APIEndPoints.TeamCommunityDisk}change/request/cancel/`,
      method: 'POST',
      body: JSON.stringify(payload),
    };
    const response = await API(obj);
    return response;
  }
);
export const acceptDiskSubscriptionChangeRequest = createAsyncThunk(
  'acceptDiskSubscriptionChangeRequest',
  async (payload) => {
    const obj = {
      url: `${APIEndPoints.TeamCommunityDisk}change/update/`,
      method: 'PUT',
      body: JSON.stringify(payload),
    };
    const response = await API(obj);
    return response;
  }
);
export const getDiskSubscriptionChangeRequests = createAsyncThunk(
  'getDiskSubscriptionChangeRequests',
  async () => {
    const obj = {
      url: `${APIEndPoints.TeamCommunityDisk}change/request/list/`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  }
);

export const getPremiumSubscriptionPlans = createAsyncThunk(
  'getPremiumSubscriptionPlans',
  async () => {
    const obj = {
      url: `${APIEndPoints.PremiumSubscription}/plan`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  }
);
export const getPremiumSubscription = createAsyncThunk(
  'getPremiumSubscription',
  async () => {
    const obj = {
      url: `${APIEndPoints.PremiumSubscription}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  }
);

export const createPremiumSubscription = createAsyncThunk(
  'createPremiumSubscription',
  async (payload) => {
    const obj = {
      url: `${APIEndPoints.PremiumSubscription}`,
      method: 'POST',
      body: JSON.stringify(payload),
    };
    const response = await API(obj);
    return response;
  }
);

export const updatePremiumSubscription = createAsyncThunk(
  'updatePremiumSubscription',
  async (payload) => {
    const obj = {
      url: `${APIEndPoints.PremiumSubscription}`,
      method: 'PUT',
      body: JSON.stringify(payload),
    };
    const response = await API(obj);
    return response;
  }
);

export const deletePremiumSubscription = createAsyncThunk(
  'deletePremiumSubscription',
  async (payload) => {
    const obj = {
      url: `${APIEndPoints.PremiumSubscription}`,
      method: 'DELETE',
    };
    const response = await API(obj);
    return response;
  }
);


export const downloadDiskFile = createAsyncThunk(
  'downloadDiskFile',
  async (payload) => {
    const obj = {
      url: `${APIEndPoints.DownloadDiskFile({fileId:payload?.fileId, parentId:payload?.parentId})}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  }
);

export const clearFile = createAction('CLEAR');
