/* eslint-disable @nx/enforce-module-boundaries */
import { Box } from '@mybridge/ui/layout';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './footer.module.scss';
import dynamic from 'next/dynamic';
import { Portal } from '@mybridge/ui';
const ChatInit = dynamic(() => import('v4/components/chat/chat-init'), {
  ssr: false,
});
const MeetingInit = dynamic(() => import('v4/components/meeting/meetingInit'), {
  ssr: false,
});
export const Footer = () => {
  const { loggedIn } = useSelector((state) => state.user);
  return (
    <Box className={styles.siteFooterContainer}>
      {loggedIn && ChatInit ? (
        <Portal>
          <ChatInit />
        </Portal>
      ) : (
        ''
      )}
      {loggedIn && MeetingInit ? (
        <Portal>
          <MeetingInit />
        </Portal>
      ) : (
        ''
      )}
    </Box>
  );
};

export default Footer;
