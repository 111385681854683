import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import serverAdapter from '../../lib/serverAdapter';
import API from '../common/api';
import { APIEndPoints } from '../common/endPoint';

export const signUpUser = createAsyncThunk('singupuser', async (body) => {
  const obj = {
    url: `${APIEndPoints.Register}`,
    method: 'POST',
    body: JSON.stringify(body),
    isNoToken: true,
  };
  return await API(obj);
});

export const signInUser = createAsyncThunk('signinuser', async (body) => {
  const obj = {
    url: `${APIEndPoints.SignIn}/`,
    method: 'POST',
    body: JSON.stringify(body),
    isNoToken: true,
  };
  return await API(obj);
});

export const requestTokenRefresh = createAsyncThunk(
  'requestTokenRefresh',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.SignIn}/refresh/`,
      method: 'POST',
      body: JSON.stringify(body),
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const googleSignIn = createAsyncThunk('googleSignIn', async (body) => {
  const obj = {
    url: `${APIEndPoints.GoogleSign}`,
    method: 'POST',
    body: JSON.stringify(body),
    isNoToken: true,
  };
  return await API(obj);
});

export const forgetPassword = createAsyncThunk(
  'forgotpassword',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.Forgotpassword}`,
      method: 'POST',
      body: JSON.stringify(body),
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const resetPassword = createAsyncThunk('resetPassword', async (body) => {
  const obj = {
    url: `${APIEndPoints.ResetPassword}/?token=${body?.token}`,
    method: 'POST',
    body: JSON.stringify(body),
    isNoToken: true,
  };
  return await API(obj);
});
export const activateYourAccount = createAsyncThunk(
  'activateYourAccount',
  async (otp) => {
    const obj = {
      url: `${APIEndPoints.Activate}/${otp}`,
      method: 'GET',
    };
    return await API(obj);
  }
);

export const getMatrixToken = createAsyncThunk(
  'getMatrixToken',
  async () => {
    const obj = {
      url: `${APIEndPoints.MatrixChatToken}`,
      method: 'GET',
    };
    return await API(obj);
  }
);

export const getMatrixEncryption = createAsyncThunk(
  'getMatrixEncryption',
  async () => {
    const obj = {
      url: `${APIEndPoints.MatrixEncryptDetails}`,
      method: 'GET',
    };
    return await API(obj);
  }
);

export const resendVerificationEmail = createAsyncThunk(
  'resendVerificationEmail',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.Register}/verification/send`,
      method: 'PUT',
      isNoToken: true,
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const changeEmailRequest = createAsyncThunk(
  'changeEmailRequest',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.InactiveUserChangeEmail}`,
      method: 'POST',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const setInactiveEmail = createAction('setInactiveEmail');
export const setUserFirstLogin = createAction('setUserFirstLogin');
export const addToken = createAction('addToken');
export const addUser = createAction('addUser');
export const setOpenAuthModal = createAction('setOpenAuthModal');;
export const logout = createAction('logout');
export const setLoggedIn = createAction('setLoggedIn');
export const setChatLoggedIn = createAction('setChatLoggedIn');
export const setPageLoaded = createAction('setPageLoaded');