import { NumberInput as NumberInput_ } from "@chakra-ui/react";
import { forwardRef } from "react";
export const NumberInput = forwardRef(({ ...props }, ref) => {
    return <NumberInput_ ref={ref} {...props}>{props?.children}</NumberInput_>
});

import { NumberInputField as NumberInputField_ } from "@chakra-ui/react";
export const NumberInputField = forwardRef(({ ...props }, ref) => {
    return <NumberInputField_ ref={ref} {...props}>{props?.children}</NumberInputField_>
});

import { NumberInputStepper as NumberInputStepper_ } from "@chakra-ui/react";
export const NumberInputStepper = forwardRef(({ ...props }, ref) => {
    return <NumberInputStepper_ ref={ref} {...props}>{props?.children}</NumberInputStepper_>
});

import { NumberIncrementStepper as NumberIncrementStepper_ } from "@chakra-ui/react";
export const NumberIncrementStepper = forwardRef(({ ...props }, ref) => {
    return <NumberIncrementStepper_ ref={ref} {...props}>{props?.children}</NumberIncrementStepper_>
});

import { NumberDecrementStepper as NumberDecrementStepper_ } from "@chakra-ui/react";
export const NumberDecrementStepper = forwardRef(({ ...props }, ref) => {
    return <NumberDecrementStepper_ ref={ref} {...props}>{props?.children}</NumberDecrementStepper_>
});