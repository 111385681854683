import { Avatar as Avatar_ } from '@chakra-ui/react';
import { forwardRef, useEffect, useState } from 'react';
export const Avatar = forwardRef(({ ...props }, ref) => {
  // const [src, setSrc] = useState(props?.src);
  // useEffect(() => {
  //   if (imgSrc?.length && src !== imgSrc) {
  //     setSrc(imgSrc);
  //   }
  // }, [imgSrc]);
  return (
    <Avatar_ ref={ref} {...props}>
      {props?.children}
    </Avatar_>
  );
});

import { AvatarBadge as AvatarBadge_ } from '@chakra-ui/react';
export const AvatarBadge = forwardRef(({ ...props }, ref) => {
  return (
    <AvatarBadge_ ref={ref} {...props}>
      {props?.children}
    </AvatarBadge_>
  );
});

import { AvatarGroup as AvatarGroup_ } from '@chakra-ui/react';
export const AvatarGroup = forwardRef(({ ...props }, ref) => {
  return (
    <AvatarGroup_ ref={ref} {...props}>
      {props?.children}
    </AvatarGroup_>
  );
});
