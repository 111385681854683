/* eslint-disable @nx/enforce-module-boundaries */
import { Box, HStack } from '@mybridge/ui/layout';
import React, { useEffect, useState, useContext } from 'react';
import styles from './statusdrop.module.scss';
import { TriangleDownIcon, CheckIcon } from '@chakra-ui/icons';
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@mybridge/ui/popover';
import { List, ListItem } from '@mybridge/ui/list';
import { Text } from '@mybridge/ui/text';
import {
  getUserPresence,
  updateUserPresence,
} from 'v4/store/actions/chat.actions';
import { useDispatch, useSelector } from 'react-redux';
import { statusColor, userStatus, userStatusMsg } from './constant';
import { MatrixContext } from 'packages/mybridgev4/context/chat-context';
import { Image, useDisclosure } from '@mybridge/ui';
import classNames from 'classnames';
import {
  userProfileAddInfo,
  getUserProfileInfo,
  getUserPresenceStatus,
  updateUserPresenceStatus,
} from 'v4/store/actions/user-profile.actions';
import _ from 'lodash';
import { setMeetingWindowed } from 'v4/store/actions/meeting.actions';

const StatusDropdown = () => {
  const dispatch = useDispatch();
  const statusDisc = useDisclosure();
  const { currentMeetingState } = useSelector((state) => state.meeting);
  const { matrixClient, loggedInUser } = useContext(MatrixContext);
  const [selected, setSelected] = useState(userStatus[0]);
  const { userPresence } = useSelector((state) => state.chatReducer);
  const { userProfileInfo, userPresenceStatus } = useSelector(
    (state) => state.userProfile
  );
  const [isWindowActive, setIsWindowActive] = useState(true);

  useEffect(() => {
    if (loggedInUser) {
      dispatch(
        getUserPresence(
          loggedInUser ? loggedInUser : userProfileInfo?.matrix_user_id
        )
      );
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (currentMeetingState) {
      dispatch(setMeetingWindowed());
    }
  }, [statusDisc.isOpen]);

  const presenceState = (presence, status) => {
    switch (presence) {
      case 'online':
        return setSelected({
          id: 1,
          title: status ? status : 'Available',
          subTitle: 'Based on activity',
          color: '#0AB33E',
          statusObj: _.find(userStatusMsg, ['title', status]),
        });
      case 'offline':
        return setSelected({
          id: 3,
          title: status ? status : 'Away',
          color: '#5B5B5B',
          statusObj: _.find(userStatusMsg, ['title', status]),
        });
      case 'busy':
        return setSelected({
          id: 2,
          title: status ? status : 'Busy',
          subTitle: 'Mute chat notifications',
          color: '#FF0600',
          statusObj: _.find(userStatusMsg, ['title', status]),
        });
      default:
        return setSelected({
          id: 1,
          title: 'Available',
          subTitle: 'Based on activity',
          color: '#0AB33E',
        });
    }
  };

  const handleSelect = (param) => {
    setSelected(param);
    let state = '';
    let statusMsg = '';
    if (param.id === 1) {
      state = 'online';
      statusMsg = 'Available';
    } else if (param.id === 3) {
      state = 'offline';
      statusMsg = 'Away';
    } else if (param.id === 2) {
      state = 'busy';
      statusMsg = 'Busy';
    }
    const body = {
      matrix_presence: state,
      matrix_status: statusMsg,
      is_presence_manual: true,
    };
    dispatch(updateUserPresenceStatus(body)).then((res) => {
      dispatch(getUserPresenceStatus());
    });
  };

  const handleMsgSelect = (message) => {
    let state = null;
    if (message === 'In a meeting') {
      state = 'busy';
    } else {
      state = userProfileInfo?.matrix_presence;
    }
    const body = {
      matrix_presence: state,
      matrix_status: message,
      is_presence_manual: true,
    };
    dispatch(updateUserPresenceStatus(body)).then((res) => {
      dispatch(getUserPresenceStatus());
    });
  };

  useEffect(() => {
    if (userProfileInfo || userPresenceStatus) {
      // dispatch(getUserPresenceStatus());
      presenceState(
        userPresenceStatus?.matrix_presence,
        userPresenceStatus?.matrix_status
      );
    }
  }, [userProfileInfo, userPresenceStatus]);

  // console.log('userPresenceStatus', userPresenceStatus);

  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     // The document visibility has changed
  //     setIsWindowActive(!document.hidden);
  //   };

  //   // Add event listener for visibility change
  //   document.addEventListener('visibilitychange', handleVisibilityChange);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   };
  // }, []);

  // useEffect(() => {
  //   let timeoutId;
  //     if(isWindowActive){

  //       const body = {
  //         matrix_presence: 'online',
  //         matrix_status:'Available'
  //       };
  //       dispatch(userProfileAddInfo(body)).then((res) => {
  //         dispatch(getUserProfileInfo());
  //       });
  //     } else {
  //       const body = {
  //         matrix_presence: 'offline',
  //         matrix_status:'Away'
  //       };
  //       // The window is inactive, set a timeout to check after 5 minutes
  //       timeoutId = setTimeout(() => {
  //         dispatch(userProfileAddInfo(body)).then((res) => {
  //           dispatch(getUserProfileInfo());
  //         });
  //       }, 5 * 60 * 1000); // 2 minutes in milliseconds
  //     }
  //     return () => {
  //       // Clear the timeout if the component is unmounted
  //       clearTimeout(timeoutId);
  //     };
  // }, [isWindowActive])

  return (
    <Box pr={'40px'}>
    <Popover placement="bottom-start" isLazy {...statusDisc}>
      <PopoverTrigger>
        <Box _hover={{ bg: 'gray.100' }} className={styles.statusContainer}>
          {selected?.statusObj ? (
            <Box display={['block', 'block', 'block']} width={['18px', '18px', 'auto']}>
              <Image src={selected?.statusObj?.icon} />
            </Box>
          ) : (
            <Box
              as="span"
              className={styles.statusIcon}
              style={{ backgroundColor: `${selected.color}` }}
            >
              Dot
            </Box>
          )}
          <Box flex="1" display={['block', 'block', 'block']}>{selected.title}</Box>
          <Box display={['block', 'block', 'block']}>
            {/* <TriangleDownIcon /> */}
          </Box>
        </Box>
      </PopoverTrigger>
      <PopoverContent className={styles.statusDropdown}>
        <PopoverBody style={{ outline: 'none',   borderRadius: '80px'}}>
        <Box className={styles.statusContainerTest}>
          {selected?.statusObj ? (
            <Box display={['block', 'block', 'block']} width={['18px', '18px', 'auto']}>
              <Image src={selected?.statusObj?.icon} />
            </Box>
          ) : (
            <Box
              as="span"
              className={styles.statusIcon}
              style={{ backgroundColor: `${selected.color}` }}
            >
              Dot
            </Box>
          )}
          <Box flex="1" display={['block', 'block', 'block']}>{selected.title}</Box>
          <Box display={['block', 'block', 'block']}>
            {/* <TriangleDownIcon /> */}
          </Box>
        </Box>
          <List>
            {userStatus.map((status, i) => (
              <ListItem
                cursor="pointer"
                className={styles.dropItem}
                key={i}
                as={HStack}
                alignItems="flex-start"
                _hover={{ bg: 'gray.100' }}
                borderRadius="5"
                onClick={() => handleSelect(status)}
              >
                <HStack flex={1} alignItems="flex-start">
                  <Box
                    as="span"
                    className={styles.statusIcon}
                    style={{ backgroundColor: `${status.color}` }}
                  >
                    Dot
                  </Box>
                  <Box>
                    <Text>{status.title}</Text>{' '}
                    {status.subTitle && <Box as="span">{status.subTitle}</Box>}
                  </Box>
                </HStack>
                <Box>{selected.id === status.id ? <CheckIcon /> : ''}</Box>
              </ListItem>
            ))}
            <hr style={{ marginBottom: '10px' }} />
            {userStatusMsg.map((stateMessage, i) => (
              <ListItem
                cursor="pointer"
                className={classNames(styles.dropItem, styles.small)}
                key={i}
                as={HStack}
                alignItems="flex-start"
                _hover={{ bg: 'gray.100' }}
                borderRadius="5"
                onClick={() => handleMsgSelect(stateMessage.title)}
              >
                <HStack flex={1} alignItems="flex-start">
                  <Image src={stateMessage.icon} />{' '}
                  <Text>{stateMessage.title}</Text>
                </HStack>
                <Box height="14px">
                  {selected?.statusObj?.id === stateMessage.id ? (
                    <CheckIcon />
                  ) : (
                    ''
                  )}
                </Box>
              </ListItem>
            ))}
          </List>
        </PopoverBody>
      </PopoverContent>
    </Popover>
    </Box>

  );
};

export default StatusDropdown;
