import { Stat as Stat_ } from "@chakra-ui/react";
import { forwardRef } from "react";
export const Stat = forwardRef(({ ...props }, ref) => {
    return <Stat_ ref={ref} {...props}>{props?.children}</Stat_>
});

import { StatLabel as StatLabel_ } from "@chakra-ui/react";
export const StatLabel = forwardRef(({ ...props }, ref) => {
    return <StatLabel_ ref={ref} {...props}>{props?.children}</StatLabel_>
});

import { StatNumber as StatNumber_ } from "@chakra-ui/react";
export const StatNumber = forwardRef(({ ...props }, ref) => {
    return <StatNumber_ ref={ref} {...props}>{props?.children}</StatNumber_>
});

import { StatHelpText as StatHelpText_ } from "@chakra-ui/react";
export const StatHelpText = forwardRef(({ ...props }, ref) => {
    return <StatHelpText_ ref={ref} {...props}>{props?.children}</StatHelpText_>
});

import { StatArrow as StatArrow_ } from "@chakra-ui/react";
export const StatArrow = forwardRef(({ ...props }, ref) => {
    return <StatArrow_ ref={ref} {...props}>{props?.children}</StatArrow_>
});

import { StatGroup as StatGroup_ } from "@chakra-ui/react";
export const StatGroup = forwardRef(({ ...props }, ref) => {
    return <StatGroup_ ref={ref} {...props}>{props?.children}</StatGroup_>
});