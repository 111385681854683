import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../common/api';
import { APIEndPoints } from '../common/endPoint';

export const newMeeting = createAsyncThunk('newMeeting', async (body) => {
  const obj = {
    url: APIEndPoints.newMeeting,
    method: 'POST',
    body: JSON.stringify(body),
  };
  return await API(obj);
});
export const newGoogleMeeting = createAsyncThunk(
  'newGoogleMeeting',
  async (body) => {
    const obj = {
      url: APIEndPoints.GOOGLE_EVENT,
      method: 'POST',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);
export const newOutlookMeeting = createAsyncThunk(
  'newOutlookMeeting',
  async (body) => {
    const obj = {
      url: APIEndPoints.OUTLOOK_EVENT,
      method: 'POST',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const updateMeeting = createAsyncThunk('updateMeeting', async (body) => {
  const obj = {
    url: `${APIEndPoints.newMeeting}/${body.id}/update`,
    method: 'PUT',
    body: JSON.stringify(body),
  };
  return await API(obj);
});
export const updateGoogleMeeting = createAsyncThunk(
  'updateGoogleMeeting',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.GOOGLE_EVENT}bridge${body.id}/`,
      method: 'PUT',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);
export const updateOutlookMeeting = createAsyncThunk(
  'updateOutlookMeeting',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.OUTLOOK_EVENT}/${body.id}/`,
      method: 'PATCH',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const updateAttendeeStatus = createAsyncThunk(
  'updateAttendeeStatus',
  async (payload) => {
    const obj = {
      url: `${APIEndPoints.newMeeting}/${payload.meetingId}/attendee/${payload.id}/update`,
      method: 'PUT',
      body: JSON.stringify(payload),
    };
    return await API(obj);
  }
);

export const acceptProposedNewTime = createAsyncThunk(
  'acceptProposedNewTime',
  async (payload) => {
    const obj = {
      url: `${APIEndPoints.newMeeting}/${payload.meetingId}/proposal/${payload.attendeeId}/accept`,
      method: 'PUT',
      // body: JSON.stringify(payload.proposeNewTime),
    };
    return await API(obj);
  }
);

export const getEventDetail = createAsyncThunk(
  'getEventDetail',
  async (meetingId) => {
    const obj = {
      url: `${APIEndPoints.newMeeting}/${meetingId}`,
    };
    return await API(obj);
  }
);
export const getGoogleEventDetail = createAsyncThunk(
  'getGoogleEventDetail',
  async (meetingId) => {
    const obj = {
      url: `${APIEndPoints.GOOGLE_EVENT}/${meetingId}`,
    };
    return await API(obj);
  }
);
export const getOutlookEventDetail = createAsyncThunk(
  'getOutlookEventDetail',
  async (meetingId) => {
    const obj = {
      url: `${APIEndPoints.OUTLOOK_EVENT}/${meetingId}`,
    };
    return await API(obj);
  }
);

export const proposeNewTime = createAsyncThunk(
  'propseNewTime',
  async ({ meetingId, payload }) => {
    const obj = {
      url: `${APIEndPoints.proposeNewTime(meetingId)}`,
      method: 'PUT',
      body: JSON.stringify(payload),
    };
    return await API(obj);
  }
);

export const deleteMeeting = createAsyncThunk(
  'deleteMeeting',
  async (meetingId) => {
    const obj = {
      url: `${APIEndPoints.newMeeting}/${meetingId}`,
      method: 'DELETE',
    };
    return await API(obj);
  }
);
export const deleteGoogleMeeting = createAsyncThunk(
  'deleteGoogleMeeting',
  async (meetingId) => {
    const obj = {
      url: `${APIEndPoints.GOOGLE_EVENT}${meetingId}/`,
      method: 'DELETE',
    };
    return await API(obj);
  }
);
export const deleteOutlookMeeting = createAsyncThunk(
  'deleteOutlookMeeting',
  async (meetingId) => {
    const obj = {
      url: `${APIEndPoints.OUTLOOK_EVENT}/${meetingId}`,
      method: 'DELETE',
    };
    return await API(obj);
  }
);

export const generateMeetingId = createAsyncThunk(
  'generateMeetingId',
  async (meetingId) => {
    const obj = {
      url: `${APIEndPoints.meetingIDAPI}/generate_meeting_id`,
      method: 'GET',
    };
    return await API(obj);
  }
);
export const updateMeetingSecurity = createAsyncThunk(
  'updateMeetingSecurity',
  async (payload) => {
    const obj = {
      url: `${APIEndPoints.meetingIDAPI}/dynamic_meeting`,
      method: 'PUT',
      body: JSON.stringify(payload),
    };
    return await API(obj);
  }
);
export const checkMeetingSecurity = createAsyncThunk(
  'checkMeetingSecurity',
  async (meetingId) => {
    const obj = {
      url: `${APIEndPoints.meetingIDAPI}/dynamic_meeting?meeting_id=${meetingId}`,
      method: 'GET',
    };
    return await API(obj);
  }
);

export const checkMeetingValidity = createAsyncThunk(
  'checkMeetingValidity',
  async (meetingId) => {
    const obj = {
      url: `${APIEndPoints.meetingIDAPI}/get_meetingid_info?meeting_id=${meetingId}`,
      method: 'GET',
    };
    return await API(obj);
  }
);
export const deleteMeetingID = createAsyncThunk(
  'deleteMeetingID',
  async (payload) => {
    const obj = {
      url: `${APIEndPoints.meetingIDAPI}/dynamic_meeting`,
      method: 'DELETE',
      body: JSON.stringify(payload),
    };
    return await API(obj);
  }
);

export const inviteUserToChat = createAsyncThunk(
  'inviteUserToChat',
  async (body) => {
    const obj = {
      url: APIEndPoints.InviteUser,
      method: 'POST',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const sendMeetingRecordInformation = createAsyncThunk(
  'sendMeetingRecordInformation',
  async (body) => {
    const obj = {
      url: APIEndPoints.RecordingAPI,
      method: 'POST',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const getMeetingRecordings = createAsyncThunk(
  'getMeetingRecordings',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.RecordingAPI}?page=1&page_size=10`,
    };
    return await API(obj);
  }
);

export const deleteRecordings = createAsyncThunk(
  'deleteRecordings',
  async (id) => {
    const obj = {
      url: `${APIEndPoints.RecordingAPI}/${id}`,
      method: 'DELETE',
    };
    return await API(obj);
  }
);

export const getGoogleMeetingDetails = createAction('getGoogleMeetingDetails');
export const updateUserNavStatus = createAction('updateUserNavStatus');
export const setMeetNow = createAction('setMeetNow');
export const setJoinMeetNow = createAction('setJoinMeetNow');
export const joinedUsersMeeting = createAction('joinedUsersMeeting');
export const joinMeeting = createAction('joinMeeting');
export const joinAMeeting = createAction('joinAMeeting');
export const joinEventMeeting = createAction('joinEventMeeting');
export const clearJoinedMeetingData = createAction('clearJoinedMeetingData');
export const startDefaultMeeting = createAction('startDefaultMeeting');
export const setShowChat = createAction('setShowChat');
export const clearShowChat = createAction('clearShowChat');
export const setShowMeet = createAction('setShowMeet');
export const clearShowMeet = createAction('clearShowMeet');
export const isMeetingRunning = createAction('isMeetingRunning');
export const clearMeetingRunning = createAction('clearMeetingRunning');
export const setMeetingWindowed = createAction('setMeetingWindowed');
export const clearMeetingWindowed = createAction('clearMeetingWindowed');
export const scheduleMeetingWithUser = createAction('scheduleMeetingWithUser');
export const clearScheduleMeetingWithUser = createAction('clearScheduleMeetingWithUser');
