import { forwardRef } from 'react';
export const ChevronRightBulletIcon = forwardRef(({ ...props }, ref) => {
  return (
    <>
      <svg
        width="14"
        height="13"
        viewBox="0 0 14 13"
        fill="none"
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.71 6.04554L0.710318 0.0456907C0.503323 -0.048307 0.256329 0.00769172 0.112332 0.185687C0.0418711 0.271905 0.0023897 0.379279 0.000213942 0.490606C-0.00196182 0.601932 0.033294 0.710767 0.100333 0.799672L4.37523 6.49953L0.100333 12.1994C-0.037664 12.3824 -0.0326641 12.6364 0.111332 12.8134C0.20833 12.9344 0.353326 12.9994 0.500323 12.9994C0.571321 12.9994 0.642319 12.9844 0.709318 12.9534L13.709 6.95352C13.887 6.87152 14 6.69453 14 6.49953C14 6.30454 13.887 6.12754 13.71 6.04554Z"
          fill={props?.color ?? '#5B5B5B'}
        />
      </svg>
    </>
  );
});
