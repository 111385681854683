/* eslint-disable @nx/enforce-module-boundaries */
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  createGlobalCompanies,
  createGlobalSchools,
  getGlobalCompanies,
  getGlobalSchools,
} from 'v4/store/actions/global.data.actions';

export const useSchools = ({ search,enabled=true }) => {
  const dispatch = useDispatch();
  const tid = useRef(-1);
  const {
    mutateAsync,
    mutate,
    isLoading: createLoading,
  } = useMutation({
    mutationKey: ['global-schools-create', search],
    mutationFn: async () => {
      return dispatch(createGlobalSchools({ name: search }));
    },
    onSuccess: () => {
      refetch();
    },
  });
  const {
    isLoading,
    isFetching,
    data = { pages: [] },
    fetchNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['global-schools', search],
    queryFn: async () => {
      const resp = await dispatch(getGlobalSchools({ search }));
      return resp?.payload;
    },
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.next) {
        const url = new URL(lastPage.next);
        const page = url.searchParams.get('page');
        return parseInt(page, 10);
      }
      return undefined;
    },
    refetchOnWindowFocus: false,
    enabled
  });

  return {
    isLoading: isLoading || isFetching,
    createLoading,
    data,
    fetchNextPage,
    refetch,
    search,
    mutate,
    mutateAsync,
  };
};
