import { createReducer } from '@reduxjs/toolkit';
import {
    addExperience,
    getExperience,
    getMyActivity,
    updateExperience,
    addCertificate,
    addEducation,
    addMilestone,
    addSkill,
    clear,
    deleteCertificate,
    deleteEduction,
    deleteExperience,
    deleteMilestone,
    deleteSkill,
    endorseSkill,
    getCertificate,
    getEducation,
    getIndustries,
    getMilestone,
    getProfileSkills,
    getProfileViewers,
    getPublicProfile,
    getUserAnalytics,
    getUserConnectionCount,
    getUserProfileInfo,
    getUserSlug,
    searchByTerm,
    updateCertificate,
    updateEducation,
    updateMilestone,
    updateUserDetails,
    userProfileAddInfo,
    addToken,
    addUser,
    logout,
    getUserDetailsByChatId,
    updateUserInfo,
    getUserPresenceStatus,
    updateUserPresenceStatus,
    getAiSuggestedIntro,
    clearSuggestion,
    currentProfile,
    currentProfileComment
} from '../actions/user-profile.actions';

const initialState = {
    msg: '',
    loading: true,
    error: false,
    experience: {},
    isNewExperience: false,
    userProfileInfo: {},
    isProfileUpdated: false,
    isNewPersonDetail: false,
    isNewMilestone: false,
    isNewCertificate: false,
    isNewEducation: false,
    milestones: {},
    certificates: {},
    educations: {},
    searchResults: [],
    initialInfo: false,
    isProfileResult: false,
    userSlug: undefined,
    publicProfileData: {},
    industriesList: [],
    addedSkills: undefined,
    profileSkillsList: [],
    analyticsDetail: [],
    endorsedSkill: undefined,
    connectionDegreeCount: {},
    profileViewers: [],
    chatUserDetails:null,
    userPresenceStatus:{},
    suggestedData:null,
    isLoading:false,
    currentProfile:null,
    currentProfileCommentData:null,
};

export const userProfileReducer = createReducer(initialState, builder => {
    builder
        .addCase(addExperience.pending, (state, action) => {
            state.isNewExperience = false;
            state.loading = true;
        })
        .addCase(addExperience.fulfilled, (state, action) => {
            state.loading = false;
            state.isNewExperience = true;
        })
        .addCase(addExperience.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })
        .addCase(getExperience.pending, (state, action) => {
            state.isNewExperience = false;
            state.loading = true;
        })
        .addCase(getExperience.fulfilled, (state, action) => {
            state.experience = action.payload;
            state.loading = false;
        })
        .addCase(getExperience.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })
        .addCase(getMyActivity.pending, (state, action) => {
            state.isMyActivities = false;
            state.loading = true;
        })
        .addCase(getMyActivity.fulfilled, (state, action) => {
            state.myActivities = action.payload;
            state.loading = false;
        })
        .addCase(getMyActivity.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })
        .addCase(updateExperience.pending, (state, action) => {
            state.isNewExperience = false;
            state.loading = true;
        })
        .addCase(updateExperience.fulfilled, (state, action) => {
            state.loading = false;
            state.isNewExperience = true;
        })
        .addCase(updateExperience.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })
        .addCase(deleteExperience.pending, (state, action) => {
            state.isNewExperience = false;
            state.loading = true;
        })
        .addCase(deleteExperience.fulfilled, (state, action) => {
            state.loading = false;
            state.isNewExperience = true;
        })
        .addCase(deleteExperience.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })

        .addCase(userProfileAddInfo.pending, (state, action) => {
            state.isProfileUpdated = false;
            state.loading = true;
        })
        .addCase(userProfileAddInfo.fulfilled, (state, action) => {
            state.loading = false;
            state.isProfileUpdated = true;
        })
        .addCase(userProfileAddInfo.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })
        .addCase(getUserProfileInfo.pending, (state, action) => {
            state.loading = true;
            state.isProfileResult = false;
        })
        .addCase(getUserProfileInfo.fulfilled, (state, action) => {
            state.loading = false;
            state.userProfileInfo = action.payload;
            state.isProfileResult = true;
        })
        .addCase(getUserProfileInfo.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })

        /* milestones */

        .addCase(addMilestone.pending, (state, action) => {
            state.isNewMilestone = false;
            state.loading = true;
        })
        .addCase(addMilestone.fulfilled, (state, action) => {
            state.loading = false;
            state.isNewMilestone = true;
        })
        .addCase(addMilestone.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })
        .addCase(getMilestone.pending, (state, action) => {
            state.isNewMilestone = false;
            state.loading = true;
        })
        .addCase(getMilestone.fulfilled, (state, action) => {
            state.milestones = action.payload;
            state.loading = false;
        })
        .addCase(getMilestone.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })
        .addCase(updateMilestone.pending, (state, action) => {
            state.isNewMilestone = false;
            state.loading = true;
        })
        .addCase(updateMilestone.fulfilled, (state, action) => {
            state.loading = false;
            state.isNewMilestone = true;
        })
        .addCase(updateMilestone.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })
        .addCase(deleteMilestone.pending, (state, action) => {
            state.isNewMilestone = false;
            state.loading = true;
        })
        .addCase(deleteMilestone.fulfilled, (state, action) => {
            state.loading = false;
            state.isNewMilestone = true;
        })
        .addCase(deleteMilestone.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })
        // analytics
        .addCase(getUserAnalytics.fulfilled, (state, action) => {
            state.analyticsDetail = action?.payload?.results;
        })
        /* Skills */
        .addCase(getProfileSkills.fulfilled, (state, action) => {
            state.profileSkillsList = action.payload;
        })
        .addCase(addSkill.fulfilled, (state, action) => {
            state.addedSkills = action.payload;
        })
        .addCase(endorseSkill.fulfilled, (state, action) => {
            state.endorsedSkill = action.payload;
        })

        /* certificate */
        .addCase(addCertificate.pending, (state, action) => {
            state.isNewCertificate = false;
            state.loading = true;
        })
        .addCase(addCertificate.fulfilled, (state, action) => {
            state.loading = false;
            state.isNewCertificate = true;
        })
        .addCase(addCertificate.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })
        .addCase(getCertificate.pending, (state, action) => {
            state.isNewCertificate = false;
            state.loading = true;
        })
        .addCase(getCertificate.fulfilled, (state, action) => {
            state.certificates = action.payload;
            state.loading = false;
        })
        .addCase(getCertificate.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })
        .addCase(updateCertificate.pending, (state, action) => {
            state.isNewCertificate = false;
            state.loading = true;
        })
        .addCase(updateCertificate.fulfilled, (state, action) => {
            state.loading = false;
            state.isNewCertificate = true;
        })
        .addCase(updateCertificate.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })
        .addCase(deleteCertificate.pending, (state, action) => {
            state.isNewCertificate = false;
            state.loading = true;
        })
        .addCase(deleteCertificate.fulfilled, (state, action) => {
            state.loading = false;
            state.isNewCertificate = true;
        })
        .addCase(deleteCertificate.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })

        /* education */

        .addCase(addEducation.pending, (state, action) => {
            state.isNewEducation = false;
            state.loading = true;
        })
        .addCase(addEducation.fulfilled, (state, action) => {
            state.loading = false;
            state.isNewEducation = true;
        })
        .addCase(addEducation.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })
        .addCase(getEducation.pending, (state, action) => {
            state.isNewEducation = false;
            state.loading = true;
        })
        .addCase(getEducation.fulfilled, (state, action) => {
            state.educations = action.payload;
            state.loading = false;
        })
        .addCase(getEducation.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })
        .addCase(updateEducation.pending, (state, action) => {
            state.isNewEducation = false;
            state.loading = true;
        })
        .addCase(updateEducation.fulfilled, (state, action) => {
            state.loading = false;
            state.isNewEducation = true;
        })
        .addCase(updateEducation.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })
        .addCase(deleteEduction.pending, (state, action) => {
            state.isNewEducation = false;
            state.loading = true;
        })
        .addCase(deleteEduction.fulfilled, (state, action) => {
            state.loading = false;
            state.isNewEducation = true;
        })
        .addCase(deleteEduction.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })

        /* All Search API's */
        .addCase(searchByTerm.pending, (state, action) => {
            state.loading = true;
            state.searchResults = [];
        })
        .addCase(searchByTerm.fulfilled, (state, action) => {
            state.loading = false;
            state.searchResults = action?.payload?.results?.map((item) => item.name);
        })
        .addCase(searchByTerm.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })
        .addCase(getUserSlug.pending, (state, action) => {
            state.loading = true;
            state.userSlug = [];
        })
        .addCase(getUserSlug.fulfilled, (state, action) => {
            state.loading = false;
            state.userSlug = action.payload.results;
        })
        .addCase(getUserSlug.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })

        .addCase(getPublicProfile.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getPublicProfile.fulfilled, (state, action) => {
            state.isLoading = false;
            state.publicProfileData = action.payload;
        })
        .addCase(getPublicProfile.rejected, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getIndustries.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getIndustries.fulfilled, (state, action) => {
            state.isLoading = false;
            state.industriesList = action.payload.results;
        })
        .addCase(getIndustries.rejected, (state, action) => {
            state.isLoading = true;
        })
        .addCase(clear, (state, action) => {
            state.userSlug = initialState.userSlug;
        })
        .addCase(clearSuggestion, (state, action) => {
            state.suggestedData = null;
        })

        /* User Details Update Setting Reducers */
        .addCase(updateUserDetails.pending, (state, action) => {
            state.updateStatus = 'pending';
            state.error = false;
            state.isProfileUpdated = false;
        })
        .addCase(updateUserDetails.fulfilled, (state, action) => {
            state.pending = false;
            if (action.payload.error) {
                state.updateStatus = 'error';
                state.error = action.payload.error;
                state.isProfileUpdated = false;
            } else {
                state.updateStatus = 'success';
                state.msg = action.payload.msg;
                state.isProfileUpdated = true;
            }
        })
        .addCase(updateUserDetails.rejected, (state, action) => {
            state.updateStatus = 'error';
            state.error = action.payload;
            state.isProfileUpdated = false;
        })

        /* User Details Update Info Reducers */
        .addCase(updateUserInfo.pending, (state, action) => {
            state.updateStatus = 'pending';
            state.error = false;
            state.isProfileUpdated = false;
        })
        .addCase(updateUserInfo.fulfilled, (state, action) => {
            state.pending = false;
            if (action.payload.error) {
                state.updateStatus = 'error';
                state.error = action.payload.error;
                state.isProfileUpdated = false;
            } else {
                state.updateStatus = 'success';
                state.msg = action.payload.msg;
                state.isProfileUpdated = true;
            }
        })
        .addCase(updateUserInfo.rejected, (state, action) => {
            state.updateStatus = 'error';
            state.error = action.payload;
            state.isProfileUpdated = false;
        })

        .addCase(getProfileViewers.fulfilled, (state, action) => {
            state.profileViewers = action.payload.results;
        })

        /* Connection Reducers */
        .addCase(getUserConnectionCount.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getUserConnectionCount.fulfilled, (state, action) => {
            state.isLoading = false;
            state.connectionDegreeCount = action.payload.results;
        })
        .addCase(getUserConnectionCount.rejected, (state, action) => {
            state.isLoading = false;
        })
        /* Connection Reducers */
        .addCase(getUserDetailsByChatId.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getUserDetailsByChatId.fulfilled, (state, action) => {
            state.isLoading = false;
            state.chatUserDetails = action.payload;
        })
        .addCase(getUserDetailsByChatId.rejected, (state, action) => {
            state.isLoading = false;
        })
        /* User presence Reducers */
        .addCase(getUserPresenceStatus.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getUserPresenceStatus.fulfilled, (state, action) => {
            state.isLoading = false;
            state.userPresenceStatus = action.payload;
        })
        .addCase(getUserPresenceStatus.rejected, (state, action) => {
            state.isLoading = false;
        })

        /* User presence Reducers */
        .addCase(updateUserPresenceStatus.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(updateUserPresenceStatus.fulfilled, (state, action) => {
            state.isLoading = false;
            state.userPresenceStatus = action.payload;
        })
        .addCase(updateUserPresenceStatus.rejected, (state, action) => {
            state.isLoading = false;
        })

        /* About Me AI Suggestions */
        .addCase(getAiSuggestedIntro.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(getAiSuggestedIntro.fulfilled, (state, action) => {
            state.isLoading = false;
            state.suggestedData = action.payload.data;
        })
        .addCase(getAiSuggestedIntro.rejected, (state, action) => {
            state.isLoading = false;
        })
        .addCase(currentProfile, (state, action) => {
            state.currentProfile = action.payload;
        })
        .addCase(currentProfileComment, (state, action) => {
            state.currentProfileCommentData = action.payload;
        })
});