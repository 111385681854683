import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../common/api';
import { APIEndPoints } from '../common/endPoint';

export const teamList = createAsyncThunk('teamList', async (body) => {
    const obj = {
        url: `${APIEndPoints.Teams}`,
        method: 'GET'
    }
    return await API(obj)
})

export const addTeam = createAsyncThunk('addTeam', async (body) => {
    const obj = {
        url: `${APIEndPoints.Teams}`,
        method: 'POST',
        body: JSON.stringify(body),
    }
    return await API(obj)
})

export const updateTeam = createAsyncThunk('updateTeam', async (body) => {
    const obj = {
        url: `${APIEndPoints.Teams}${body.id}/`,
        method: 'PUT',
        body: JSON.stringify(body),
    }
    return await API(obj)
})

export const deleteTeam = createAsyncThunk('deleteTeam', async (id) => {
    const obj = {
        url: `${APIEndPoints.Teams}${id}/`,
        method: 'DELETE',
    }
    return await API(obj)
})

export const getPages = createAsyncThunk('getPages', async (body) => {
    const obj = {
        url: `${APIEndPoints.Pages}`,
        method: 'GET'
    }
    return await API(obj)
})