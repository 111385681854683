import { avatarAnatomy } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(avatarAnatomy.keys);

const sm = defineStyle({
  w: '30px',
  h: '30px',
  '--avatar-font-size': 'xs',
  
});
const smExcess = defineStyle({
  w: '30px',
  h: '30px',
  '--avatar-font-size': 'sm',
  bg: 'transparent',
  ml: 0,
});
const md = defineStyle({
  w: '40px',
  h: '40px',
  '--avatar-font-size': 'md',
});
const mdExcess = defineStyle({
  w: '40px',
  h: '40px',
  '--avatar-font-size': 'md',
  bg: 'transparent',
  ml: 1,
});
const lg = defineStyle({
  w: '60px',
  h: '60px',
  fontSize: '2xl',
  // bg: 'transparent',
});
const Xl = defineStyle({
  w: '120px',
  h: '120px',
  fontSize: '3xl',
  // bg: 'transparent',
});
const doubleXl = defineStyle({
  w: '160px',
  h: '160px',
  fontSize: '6xl',
  // bg: 'transparent',
});
const badgeSm = defineStyle({
  border: 'none',
  right: '5px',
  bottom: '5px',
  width: '10px',
  height: '10px',
});
const badgeMd = defineStyle({
  border: 'none',
  right: '5px',
  bottom: '5px',
  width: '10px',
  height: '10px',
});
const badgeLg = defineStyle({
  border: 'none',
  right: '5px',
  bottom: '5px',
  width: '15px',
  height: '15px',
});

const sizes = {
  sm: definePartsStyle({
    container: sm,
    badge: badgeSm,
    excessLabel: smExcess,
  }),
  md: definePartsStyle({
    container: md,
    badge: badgeMd,
    excessLabel: mdExcess,
  }),
  lg: definePartsStyle({
    container: lg,
    badge: badgeLg,
  }),
  Xl: definePartsStyle({
    container: Xl,
    badge: badgeLg,
  }),
  doubleXl: definePartsStyle({
    container: doubleXl,
    badge: badgeLg,
  }),
};

export const avatarTheme = defineMultiStyleConfig({
  sizes,
});
