/* eslint-disable @nx/enforce-module-boundaries */
import { forwardRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  setPageHead,
  setPageHeadAppend,
  setPageHeadPrepend,
} from 'v4/store/actions/global.data.actions';

export const AppendPageHeadTitle = forwardRef(({ children, ...props }, ref) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (children) dispatch(setPageHeadAppend(children));
    return () => {
      dispatch(setPageHeadAppend(''));
    };
  }, [children]);
  return <></>;
});
export const PrependPageHeadTitle = forwardRef(
  ({ children, ...props }, ref) => {
    const dispatch = useDispatch();

    useEffect(() => {
      if (children) dispatch(setPageHeadPrepend(children));
      return () => {
        dispatch(setPageHeadPrepend(''));
      };
    }, [children]);
    return <></>;
  }
);
export const PageHeadTitle = forwardRef(({ children, ...props }, ref) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (children) dispatch(setPageHead(children));
    return () => {
      setPageHead('');
    };
  }, [children]);
  return <></>;
});
