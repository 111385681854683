import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const baseStyle = definePartsStyle({
    control: {
        width: '18px',
        height: '18px',
        borderRadius: '3px',
        _checked: {
            borderColor:"transparent",
            background: '#3D5A80',
            _hover:{
                borderColor:"transparent",
                background: '#3D5A80'
            }
        },
    },
    icon:{
        _hover:{
            borderColor:"transparent",
            background: '#3D5A80'
        }
    }
})

export const checkboxTheme = defineMultiStyleConfig({ baseStyle })