/* eslint-disable @nx/enforce-module-boundaries */
import { Input, InputGroup } from '@mybridge/ui/input';
import { List, ListItem } from '@mybridge/ui/list';
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@mybridge/ui/popover';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useCountries } from 'v4/lib/hooks/use-countries';
import { useDisclosure } from '@mybridge/ui/hooks';
import SectionLoader from '../common/loader/sectionLoader';
import { formatCommaSeparated } from 'v4/lib/commons';

export const CityStateDropdown = ({
  value,
  defaultValue,
  onChange,
  countryCode,
  ...props
}) => {
  const inputRef = useRef();
  const [places, setPlaces] = useState([]);
  const [focused, setFocused] = useState(false);
  const disc = useDisclosure();
  const [selected, setSelected] = useState(value);
  const [search, setSearch] = useState();
  // value ? value?.city + ', ' + value?.state : ''
  const tid = useRef(-1);

  useEffect(() => {
    if (selected?.city?.length || selected?.state?.length) {
      setSearch(
        [selected?.city ?? '', selected?.state ?? '']
          ?.filter?.((v) => v.length > 0)
          ?.join(', ')
      );
    }
  }, [selected]);

  useEffect(() => {
    if (JSON.stringify(selected) !== JSON.stringify(value)) {
      setSelected({ ...value });
      setSearch(
        [value?.city ?? '', value?.state ?? '']
          ?.filter?.((v) => v.length > 0)
          ?.join(', ')
      );
    }
  }, [value]);

  const fetchPlaces = async (str) => {
    try {
      const res = await fetch(
        `${process.env.BASE_API_URL}api/v1/location/google/search?address=${str}&country_code=${countryCode}`
      );
      const places_ = await res?.json?.();
      setPlaces([...(places_ ?? [])]);
      setFocused(true);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSearch = (str) => {
    setSearch(str);
    clearTimeout(tid.current);
    tid.current = setTimeout(() => {
      if (str?.length) {
        fetchPlaces(str);
      }
    }, 300);
  };

  const handleSelect = (item) => () => {
    setSelected(item);
    onChange?.(item);
  };
  return (
    <Popover
      {...disc}
      matchWidth={true}
      initialFocusRef={inputRef}
      placement="bottom-start"
    >
      <PopoverTrigger>
        <InputGroup>
          <Input
            ref={inputRef}
            // onFocus={(e) => setFocused(true)}
            onKeyUp={(e) =>
              e?.keyCode === 27 ? disc?.onClose?.() : disc?.onOpen?.()
            }
            onBlur={(e) => {
              disc?.onClose?.();
            }}
            value={search}
            onChange={(e) => {
              handleSearch(e.target.value);
              disc?.onOpen?.();
            }}
            placeholder="Type to search..."
            {...props}
          />
        </InputGroup>
      </PopoverTrigger>
      <PopoverContent p={0} w="100%" maxH="200px" overflowY="auto">
        <PopoverBody p={0}>
          {places?.length > 0 ? (
            <List>
              {places?.map?.((c, cind) => (
                <ListItem
                  key={cind}
                  _hover={{
                    bg: 'gray.100',
                  }}
                  px={4}
                  py={2}
                  onClick={handleSelect(c)}
                >
                  {c.city}, {c.state}
                </ListItem>
              ))}
            </List>
          ) : (
            <SectionLoader />
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
