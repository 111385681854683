/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @nx/enforce-module-boundaries */
import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Spinner,
} from '@mybridge/ui';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { CountryDropdown } from '../country-dropdown';
import { CityStateDropdown } from '../city-state-dropdown';
import {
  useCurrentLocation,
  usePostalLocation,
} from 'v4/lib/hooks/use-location';
import { formatCommaSeparated } from 'v4/lib/commons';
import { ALL_COUNTRIES } from 'v4/lib/hooks/use-countries';
export const AutoSuggestLocationFields = forwardRef(
  (
    {
      isV4City,
      value,
      defaultValue,
      onChange,
      countryLabel = 'Country',
      postalLabel = 'Postal Code',
      cityStateLabel = 'City, State',
      regionLabel = 'City, State or Region within this area',
      isRequired = false,
      controlFor = null,
      countryWrapperProps,
      cityStateWrapperProps,
      regionWrapperProps,
      postalWrapperProps,
      countryProps,
      cityStateProps,
      regionProps,
      postalProps,
      noLabels = false,
      ...props
    },
    ref
  ) => {
    const val = defaultValue ?? value;
    const autoLocation = useCurrentLocation({
      enabled: !val?.country,
    });

    const [country, setCountry] = useState(val?.country);
    const countryObj = val?.country
      ? ALL_COUNTRIES?.find?.((c) => c.name === val?.country)
      : undefined;
    const [code, setCode] = useState(countryObj?.code);
    const [city, setCity] = useState(val?.city);
    const [state, setState] = useState(val?.state);
    const [postal, setPostal] = useState(val?.postal);
    const checkForPostalCountry = (code) => {
      return ['US', 'GB']?.includes(code?.toUpperCase?.());
    };
    const [isPostalCountry, setIsPostalCountry] = useState(
      countryObj?.code ? checkForPostalCountry(countryObj?.code) : false
    );
    const tid = useRef(-1);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
      // if (city !== value?.city) {
      setCity(value?.city);
      // }
      // if (state !== value?.state) {
      setState(value?.state);
      // }
      // if (country !== value?.country) {
      setCountry(value?.country);
      // }
      if (!postal && value?.postal) {
        setPostal(value?.postal);
      }
      const country_ = ALL_COUNTRIES?.find?.((c) => c.name === value?.country);
      // if (code !== value?.code) {
      setCode(value?.code ?? country_?.code);
      // }
      if (value?.postal && value?.country) {
        setIsPostalCountry(checkForPostalCountry(country_?.code));
      }
    }, [value]);

    useEffect(() => {
      if (val && val.country) return;
      if (!autoLocation?.isLoading) {
        console.warn(autoLocation);
        setCountry(autoLocation?.country);
        setCode(autoLocation?.countryCode);
        setCity(autoLocation?.city);
        setState(autoLocation?.state);
        setPostal(autoLocation?.postal);
        onChange_({
          country: autoLocation?.country,
          countryCode: autoLocation?.countryCode,
          city: autoLocation?.city,
          state: autoLocation?.state,
          postal: autoLocation?.postal,
        });
        checkForPostalCountry(autoLocation?.countryCode);
      }
    }, [autoLocation?.isLoading, val]);

    const {
      location: postalLocation,
      locationLoading: postalLocationLoading,
      fetchLocation: fetchPostalLocation,
    } = usePostalLocation({});

    const onCountryChange = ({ name, country, code }) => {
      setCountry(name);
      setCode(code);
      setCity(undefined);
      setState(undefined);
      setPostal(undefined);
        onChange_({
          country: name,
          code,
          city: undefined,
          state: undefined,
          postal: undefined,
        });
      
      setIsPostalCountry(checkForPostalCountry(code));
    };

    const handlePostalChange = (e) => {
      clearTimeout(tid?.current);
      tid.current = setTimeout(async () => {
        const postal_ = e?.target?.value;
        setPostal(postal_);
        const loc = (await fetchPostalLocation(postal_, code)) ?? [];
        onChange_({
          city: loc?.[0]?.city,
          state: loc?.[0]?.state,
          postal: postal_,
        });
        setCity(loc?.[0]?.city ?? '');
        setState(loc?.[0]?.state ?? '');
      }, 300);
    };

    const handleRegionChange = (e) => {
      const region = e?.target?.value ?? '';
      const [city, state] = region
        ?.split(',')
        ?.filter?.((_, ind) => ind < 2)
        ?.map?.((v) => v.trim?.());
      setCity(city ?? '');
      // if (state?.length) {
      setState(state ?? '');
      // }
      onChange_({ city, state });
    };

    const onChange_ = (loc = {}) => {
      // console.log('loc', { city, state, country, code, postal, ...loc })
      onChange?.({ city, state, country, code, postal, ...loc });
    };

    // console.warn(city, state, country, postal);
    // console.log('autoLocation', autoLocation);

    if (autoLocation?.isLoading)
      return (
        <FormControl>
          <HStack p={3} justifyContent="center">
            {noLabels ? '' : controlFor === 'job' ? (
              <FormLabel
                mb={0}
                fontSize="sm"
                color="brandGray.30"
                fontWeight="medium"
              >
                {countryLabel}
              </FormLabel>
            ) : (
              <FormLabel>{countryLabel}</FormLabel>
            )}
            <Spinner size="sm" />
          </HStack>
        </FormControl>
      );

    if (isV4City) {
      return (
        <CityStateDropdown
          value={{ city, state }}
          countryCode={code}
          {...props}
          onChange={({ city, state }) => {
            setCity(city);
            setState(state);
            onChange_({ city, state });
          }}
          {...(cityStateProps ?? {})}
        />
      );
    }

    return (
      <>
        {/* {JSON.stringify({ city, state, country, postal, code })} */}
        <HStack spacing={0} gap={3} flexWrap="wrap" w="100%">
          <Box
            minW={controlFor === 'job' ? '100%' : ['full', 'full', '49%']}
            flexShrink={0}
            {...(countryWrapperProps ?? {})}
            
          >
            <FormControl>
            {noLabels ? '' : controlFor === 'job' ? (
                <FormLabel
                  mb={0}
                  fontSize="sm"
                  color="brandGray.30"
                  fontWeight="medium"
                >
                  {countryLabel}
                  {isRequired && countryLabel ? '*' : ''}
                </FormLabel>
              ) : (
                <FormLabel>
                  {countryLabel}
                  {isRequired && countryLabel ? '*' : ''}
                </FormLabel>
              )}

              <CountryDropdown
                value={{ country, code }}
                onChange={onCountryChange}
                variant="whiteInput"
                placeholder="Country"
                {...(countryProps ?? {})}
              />
            </FormControl>
          </Box>
          {country || code ? (
            <>
              {isPostalCountry ? (
                <>
                  <Box
                    minW={
                      controlFor === 'job' ? '100%' : ['full', 'full', '49%']
                    }
                    flexShrink={0}
                    {...(postalWrapperProps ?? {})}
                  >
                    <FormControl>
                      {noLabels ? '' : controlFor === 'job' ? (
                        <FormLabel
                          mb={0}
                          fontSize="sm"
                          color="brandGray.30"
                          fontWeight="medium"
                        >
                          {postalLabel}
                          {isRequired && postalLabel ? '*' : ''}
                        </FormLabel>
                      ) : (
                        <FormLabel>
                          {postalLabel}
                          {isRequired && postalLabel ? '*' : ''}
                        </FormLabel>
                      )}

                      <Input
                        onChange={handlePostalChange}
                        defaultValue={postal}
                        variant="whiteInput"
                        placeholder="Postal Code"
                        {...(postalProps ?? {})}
                      />
                    </FormControl>
                  </Box>
                  {postal && isPostalCountry && !postalLocationLoading ? (
                    <Box
                      minW={
                        controlFor === 'job' ? '100%' : ['full', 'full', '29%']
                      }
                      maxW={'90%'}
                      flexShrink={0}
                      {...(regionWrapperProps ?? {})}
                    >
                      <FormControl>
                        {noLabels ? '' : controlFor === 'job' ? (
                          <FormLabel
                            mb={0}
                            fontSize="sm"
                            color="brandGray.30"
                            fontWeight="medium"
                          >
                            {regionLabel}
                            {isRequired && regionLabel ? '*' : ''}
                          </FormLabel>
                        ) : (
                          <FormLabel>
                            {regionLabel}
                            {isRequired && regionLabel ? '*' : ''}
                          </FormLabel>
                        )}
                        <Input
                          onChange={handleRegionChange}
                          placeholder="Region"
                          variant="whiteInput"
                          defaultValue={formatCommaSeparated([city, state])}
                          {...(regionProps ?? {})}
                        />
                      </FormControl>
                    </Box>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                <Box
                  minW={controlFor === 'job' ? '100%' : ['full', 'full', '49%']}
                  flexShrink={0}
                  {...(cityStateWrapperProps ?? {})}
                >
                  <FormControl>
                    {noLabels ? '' : controlFor === 'job' ? (
                      <FormLabel
                        mb={0}
                        fontSize="sm"
                        color="brandGray.30"
                        fontWeight="medium"
                      >
                        {cityStateLabel}
                        {isRequired && cityStateLabel ? '*' : ''}
                      </FormLabel>
                    ) : (
                      <FormLabel>
                        {cityStateLabel}
                        {isRequired && cityStateLabel ? '*' : ''}
                      </FormLabel>
                    )}
                    <CityStateDropdown
                      value={{ city, state }}
                      countryCode={code}
                      placeholder="City / State"
                      variant="whiteInput"
                      {...props}
                      onChange={({ city, state }) => {
                        setCity(city);
                        setState(state);
                        onChange_({ city, state });
                      }}
                      {...(cityStateProps ?? {})}
                    />
                  </FormControl>
                </Box>
              )}
            </>
          ) : (
            ''
          )}
        </HStack>
      </>
    );
  }
);
