/* eslint-disable @nx/enforce-module-boundaries */
import { useContext } from 'react';
import { MyNetworkContext } from 'v4/components/network/context';
import { MyNetworkUserCard } from 'v4/components/network/network-card-full';
import { UserNetworkingActions } from 'v4/components/user-networking-actions';
import { MyNetworkUserCardBordered } from '../network/network-card-new';

export const SuggestedUserCard = ({ user, avatarProps, ...props }) => {
  const {
    handshakeUser,
    handshakeLoading,
    unfriendUser,
    unfriendLoading,
    followUser,
    followLoading,
    unfollowUser,
    unfollowLoading,
    selectedUser,
    setSelectedUser,
    isHandshaked,
    isFollowed,
  } = useContext(MyNetworkContext);
  return (
    <MyNetworkUserCardBordered
      user={user}
      actions={<UserNetworkingActions size="sm" user={user} px={3} showHandshakeOnly justifyContent="center" />}
      avatarProps={avatarProps}
      {...props}
    />
  );
};
