import { createReducer } from '@reduxjs/toolkit';
import {
  addNewCampaign,
  getAllCampaign,
  addObjectiveDetail,
  getExperienceAndAge,
  createAudienceDetail,
  createBudget,
  getCampaignDetails,
  getObjectiveDetail,
  updateCampaign,
  uploadAdMediaFiles,
  createSingleImageFormat,getCampaignPerformanceReport, updatecurrentPerformanceData, getExistingAdsets, getDashboardSpotlights, clearCampaignData, launchCampaign, clearCampaignLaunchData
} from '../actions/campaign.action';

const initialState = {
  campaignStatus: {},
  campaignList: [],
  objectiveDetailStatus: "",
  experienceAndAge: {},
  campaignDetails:null,
  campaignObjectiveDetails:null,
  loading: false,
  loadingDetails:false,
  loadingObjectiveDetails:false,
  mediaFileLoading:false,
  performanceLoading:false,
  performanceData:null,
  currentPerformance:null,
  existingAdFormatData:null,
  dashboardSpotlightData:null,
  campaignLaunchStatus:null,
};

export const campaignReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addNewCampaign.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(addNewCampaign.fulfilled, (state, action) => {
      state.campaignStatus = action.payload;
      state.loading = false;
    })
    .addCase(updateCampaign.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(updateCampaign.fulfilled, (state, action) => {
      state.campaignStatus = action.payload;
      state.loading = false;
    })
    .addCase(addObjectiveDetail.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(addObjectiveDetail.fulfilled, (state, action) => {
      state.objectiveDetailStatus = action.payload;
      state.loading = false;
    })
    .addCase(getAllCampaign.fulfilled, (state, action) => {
      state.campaignList = action.payload.results;
    })
    .addCase(getExperienceAndAge.fulfilled, (state, action) => {
      state.experienceAndAge = action.payload;
    })
    .addCase(createAudienceDetail.rejected, (state, action) => {
      state.loading = false;
    }).addCase(createAudienceDetail.pending, (state, action) => {
      state.loading = true;
    }).addCase(createAudienceDetail.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(createBudget.rejected, (state, action) => {
      state.loading = false;
    }).addCase(createBudget.pending, (state, action) => {
      state.loading = true;
    }).addCase(createBudget.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(getCampaignDetails.rejected, (state, action) => {
      state.loadingDetails = false;
    }).addCase(getCampaignDetails.pending, (state, action) => {
      state.loadingDetails = true;
    }).addCase(getCampaignDetails.fulfilled, (state, action) => {
      state.loadingDetails = false;
      state.campaignDetails = action.payload;
    })
    .addCase(getObjectiveDetail.rejected, (state, action) => {
      state.loadingObjectiveDetails = false;
    }).addCase(getObjectiveDetail.pending, (state, action) => {
      state.loadingObjectiveDetails = true;
    }).addCase(getObjectiveDetail.fulfilled, (state, action) => {
      state.loadingObjectiveDetails = false;
      state.campaignObjectiveDetails = action.payload;
    })
    .addCase(uploadAdMediaFiles.rejected, (state, action) => {
      state.mediaFileLoading = false;
    }).addCase(uploadAdMediaFiles.pending, (state, action) => {
      state.mediaFileLoading = true;
    }).addCase(uploadAdMediaFiles.fulfilled, (state, action) => {
      state.mediaFileLoading = false;
      // state.campaignObjectiveDetails = action.payload;
    })
    .addCase(createSingleImageFormat.rejected, (state, action) => {
      state.mediaFileLoading = false;
    }).addCase(createSingleImageFormat.pending, (state, action) => {
      state.mediaFileLoading = true;
    }).addCase(createSingleImageFormat.fulfilled, (state, action) => {
      state.mediaFileLoading = false;
      // state.campaignObjectiveDetails = action.payload;
    })
    .addCase(getCampaignPerformanceReport.rejected, (state, action) => {
      state.performanceLoading = false;
    }).addCase(getCampaignPerformanceReport.pending, (state, action) => {
      state.performanceLoading = true;
    }).addCase(getCampaignPerformanceReport.fulfilled, (state, action) => {
      state.performanceLoading = false;
      state.performanceData = action.payload;
    })
    .addCase(getExistingAdsets.rejected, (state, action) => {
      state.loadingDetails = false;
    }).addCase(getExistingAdsets.pending, (state, action) => {
      state.loadingDetails = true;
    }).addCase(getExistingAdsets.fulfilled, (state, action) => {
      state.loadingDetails = false;
      state.existingAdFormatData = action.payload;
    })
    .addCase(getDashboardSpotlights.rejected, (state, action) => {
      state.loading = false;
    }).addCase(getDashboardSpotlights.pending, (state, action) => {
      state.loading = true;
    }).addCase(getDashboardSpotlights.fulfilled, (state, action) => {
      state.loading = false;
      state.dashboardSpotlightData = action.payload;
    })
    .addCase(launchCampaign.fulfilled, (state, action) => {
      state.campaignLaunchStatus = action.payload;
    })
    .addCase(updatecurrentPerformanceData, (state, action) => {
      state.currentPerformance = action.payload;
    })
    .addCase(clearCampaignData, (state, action) => {
      state.dashboardSpotlightData = null;
      state.existingAdFormatData = null;
      state.campaignObjectiveDetails = null;
      state.campaignDetails = null;
    })
    .addCase(clearCampaignLaunchData, (state, action) => {
      state.campaignLaunchStatus = null;
    })
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    // .addDefaultCase((state, action) => { });
});
