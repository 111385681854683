/* eslint-disable @typescript-eslint/no-empty-function */
import { createReducer } from '@reduxjs/toolkit';
import {
  cardList,
  addCard,
  updateCard,
  deleteCard,
  getBillingDet,
  getDiskCheckoutLink,
  addCardCallBack
} from '../actions/payments.actions';

const initialState = {
  msg: '',
  loading: false,
  addingCard: false,
  error: false,
  cardInfo: {},
  billingHistory: {},
  isCardUpdated: false,
  checkoutLink:null,
  callbackResp:null
};

export const paymentSlice = createReducer(initialState, (builder) => {
  builder
    .addCase(cardList.pending, (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
    })
    .addCase(cardList.fulfilled, (state, action) => {
      state.loading = false;
      state.pending = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.cardInfo = action.payload;
      }
    })
    .addCase(cardList.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(addCard.pending, (state, action) => {
      state.pending = true;
      state.addingCard = true;
      state.error = false;
      state.isCardUpdated = false;
    })
    .addCase(addCard.fulfilled, (state, action) => {
      state.pending = false;
      state.addingCard = false;
      // if (action.payload.error) {
      //   state.error = action.payload.error;
      // } else {
      //   state.msg = action && 'Card added successfully';
      //   state.isCardUpdated = true;
      // }
    })
    .addCase(addCard.rejected, (state, action) => {
      state.addingCard = false;
    })
    .addCase(addCardCallBack.pending, (state, action) => {
      state.pending = true;
      state.addingCard = true;
      state.error = false;
      state.isCardUpdated = false;
    })
    .addCase(addCardCallBack.fulfilled, (state, action) => {
      state.pending = false;
      state.addingCard = false;
      state.callbackResp = action.payload;
      // if (action.payload.error) {
      //   state.error = action.payload.error;
      // } else {
      //   state.msg = action && 'Card added successfully';
      //   state.isCardUpdated = true;
      // }
    })
    .addCase(addCardCallBack.rejected, (state, action) => {
      state.addingCard = false;
    })
    .addCase(updateCard.pending, (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
      state.isCardUpdated = false;
    })
    .addCase(updateCard.fulfilled, (state, action) => {
      state.pending = false;
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action.payload.msg;
        state.isCardUpdated = true;
      }
    })
    .addCase(updateCard.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(deleteCard.pending, (state, action) => {
      state.pending = true;
      state.loading = true;
      state.error = false;
      state.isCardUpdated = false;
    })
    .addCase(deleteCard.fulfilled, (state, action) => {
      state.pending = false;
      state.loading = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.msg = action && 'Card deleted successfully';
        state.isCardUpdated = true;
      }
    })
    .addCase(deleteCard.rejected, (state, action) => {
      state.loading = false;
      state.isCardUpdated = true;
    })
    .addCase(getBillingDet.pending, (state, action) => {
        state.billingHistory.pending = true;
        state.billingHistory.loading = true;
        state.billingHistory.error = false;
    })
    .addCase(getBillingDet.fulfilled, (state, action) => {
        state.billingHistory.loading = false;
      state.billingHistory.pending = false;
      if (action.payload.error) {
        state.billingHistory.error = action.payload.error;
      } else {
        state.billingHistory.msg = action.payload.msg;
        state.billingHistory = action.payload;
      }
    })
    .addCase(getBillingDet.rejected, (state, action) => {
        state.billingHistory.loading = false;
    })
    .addCase(getDiskCheckoutLink.pending, (state, action) => {
      state.checkoutLink = null;
    })
    .addCase(getDiskCheckoutLink.fulfilled, (state, action) => {
      state.checkoutLink = action.payload;
    })
    .addCase(getDiskCheckoutLink.rejected, (state, action) => {
      state.checkoutLink = null;
    })
    .addDefaultCase((state, action) => {});
});
