/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @nx/enforce-module-boundaries */
import { Button } from '@mybridge/ui/button';
import { Checkbox } from '@mybridge/ui/checkbox';
import { Divider } from '@mybridge/ui/divider';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@mybridge/ui/form-control';
import { useToast } from '@mybridge/ui/hooks';
import { Input,InputGroup,InputRightElement } from '@mybridge/ui/input';
import { IconButton } from '@mybridge/ui';
import { HStack, Stack } from '@mybridge/ui/layout';
import { Link } from '@mybridge/ui/link';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { Text } from '@mybridge/ui/text';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  setOpenAuthModal,
  signInUser,
  getMatrixEncryption,
} from 'v4/store/actions/auth.actions';
import GoogleLoginButton from '../google-login-btn';
import { DividerWithText } from 'v4/components/divider-with-text';
import { cookieStorageManager } from '@chakra-ui/react';
import { eraseCookie, getCookie, setCookie } from 'v4/lib/commons';
import { userProfileAddInfo } from 'v4/store/actions/user-profile.actions';
import AppleLoginButton from '../apple-login-btn';
import { EyeViewIcon,EyeViewFilledIcon } from '@mybridge/icons';

export const SigninModal = (props) => {
  const { isOpen, onClose } = props;
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const toast = useToast({
    position: 'top',
    isClosable: true,
  });
  const { loggedIn } = useSelector((state) => state.user);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const [loading, setLoading] = useState(false);
  const [chatPassword, setChatPassword] = useState();
  const [remembered, setRemembered] = useState();
  const [showPassword, setShowPassword] = useState(false);
  
  const handleSignin = async (data) => {
    // setChatPassword(data.password);
    try {
      
      setLoading(true);
      const { payload } = (await dispatch(signInUser(data))) ?? {};
      const { code } = payload ?? {};
      if (code === 'INVALID_CREDS') {
        toast({
          title: 'Incorrect email/password. Please try again...',
          status: 'error',
        });
        return;
      }
      handleRemember(data);
      await onClose();
    } catch (e) {
      toast({
        title: e?.message ?? 'Error occured, please try again...',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRemember = (form) => {
    const { remember_me, email } = form ?? {};
    if (remember_me) {
      setCookie('remember_me', email);
    } else {
      eraseCookie('remember_me');
    }
  };

  useEffect(() => {
    const r = getCookie('remember_me');
    if (r) {
      setRemembered(r);
      setValue('email', r);
      setValue('remember_me', true);
    }
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{remembered ? 'Welcome back' : 'Sign in'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <form autoComplete="one-time-code" onSubmit={handleSubmit(handleSignin)}>
            <Stack spacing={4}>
              <FormControl isInvalid={errors.email}>
                <FormLabel>Email</FormLabel>
                <Input
                  autoFocus
                  placeholder="Email"
                  variant="defaultInput"
                  type="email"
                  {...register('email', { required: true })}
                  autoComplete="one-time-code"
                  autosu
                />
                {errors.email && (
                  <FormErrorMessage>Email is required</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={errors.email}>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input
                    placeholder="Password"
                    variant="defaultInput"
                    type={showPassword ? 'text' : 'password'}
                    {...register('password', { required: true })}
                    style={{ border: '1px solid #DDDDDD' }}
                  />
                  <InputRightElement>
                    <IconButton
                      aria-label={showPassword ? 'Hide password' : 'Show password'}
                      icon={showPassword ? <EyeViewFilledIcon /> : <EyeViewIcon />}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </InputRightElement>
                </InputGroup>
                {errors.password && (
                  <FormErrorMessage>Password is required</FormErrorMessage>
                )}
              </FormControl>
              <Button
                isLoading={loading}
                variant="primary"
                type="submit"
                w="100%"
                size="lg"
                fontWeight="bold"
              >
                Sign in
              </Button>
              <HStack>
                <Stack flex={1}>
                  <Checkbox {...register('remember_me')}>Remember Me</Checkbox>
                </Stack>
                <Stack flex={1} alignItems="flex-end">
                  <Link
                    onClick={(e) => {
                      dispatch(setOpenAuthModal('request-reset-password'));
                    }}
                    scroll={false}
                    shallow={true}
                    href="#"
                  >
                    Forgot Password?
                  </Link>
                </Stack>
              </HStack>
              <HStack justifyContent="center">
                <Text
                  color="brandGray.700"
                  fontWeight="medium"
                  textAlign="center"
                >
                  Don't have an account?{' '}
                  <Link
                    color="brandPrimary.500"
                    display="inline"
                    onClick={(e) => {
                      dispatch(setOpenAuthModal('sign-up'));
                    }}
                  >
                    Sign Up
                  </Link>
                </Text>
              </HStack>
              <DividerWithText>
                <Text fontWeight="bold" p={2}>
                  Or
                </Text>
              </DividerWithText>
              <GoogleLoginButton path="signin" />
              <AppleLoginButton path="signin" />
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
