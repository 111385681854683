/* eslint-disable @nx/enforce-module-boundaries */
import { useEffect, useMemo, useState } from 'react';
import { useSchools } from 'v4/lib/hooks/use-schools';
import { CustomDropdown } from '../custom-dropdown';

export const SchoolsDropdown = ({ ...props }) => {
  const [search, setSearch] = useState(props?.value ?? props?.defaultValue);

  const { data, createLoading, mutateAsync, isLoading } = useSchools({
    search,
  });

  const schools = useMemo(
    () => data?.pages?.reduceRight?.((ac, p) => ac.concat(p?.results), []),
    [data?.pages]
  );

  return (
    <CustomDropdown
      {...props}
      data={schools}
      isLoading={isLoading}
      onSearch={(s) => setSearch(s)}
    />
  );
};
