export const EditIcon = (props) => {
  return <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.94824 21.2502H6.19074L21.7472 5.69367L17.5042 1.45117L1.94824 17.0077V21.2502Z"
      stroke={props?.color ?? '#5B5B5B'}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M13.2617 5.69336L17.5042 9.93586"
      stroke={props?.color ?? '#5B5B5B'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>;
};
