import { createReducer } from '@reduxjs/toolkit';
import {
  clearOnboardingData,
  closeExternalPops,
  getOnboardingConnections,
  getOnboardingData,
  getOnboardingStatus,
  setOnboardingData,
} from '../actions/onboarding.actions';
const initialState = {
  onboardingStatus: null,
  currStepData: null,
  connections: null,
  loading: false,
  externalAction: false,
  integrationData: null,
};

export const onboardingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getOnboardingConnections.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getOnboardingConnections.fulfilled, (state, action) => {
      state.connections = action.payload;
      state.loading = false;
    })
    .addCase(getOnboardingConnections.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })

    .addCase(getOnboardingStatus.pending, (state, action) => {})
    .addCase(getOnboardingStatus.fulfilled, (state, action) => {
      state.onboardingStatus = action.payload;
    })
    .addCase(getOnboardingStatus.rejected, (state, action) => {
      state.error = 'Try again later or contact customer support';
    })

    .addCase(getOnboardingData.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getOnboardingData.fulfilled, (state, action) => {
      state.currStepData = action.payload;
      state.loading = false;
    })
    .addCase(getOnboardingData.rejected, (state, action) => {
      state.error = 'Try again later or contact customer support';
    })

    .addCase(setOnboardingData.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(setOnboardingData.fulfilled, (state, action) => {
      state.currStepData = action.payload;
      state.loading = false;
    })
    .addCase(setOnboardingData.rejected, (state, action) => {
      state.error = 'Try again later or contact customer support';
    })
    .addCase(clearOnboardingData, (state, action) => {
      state.currStepData = null;
    })
    .addCase(closeExternalPops, (state, action) => {
      state.externalAction = true;
    });
});
