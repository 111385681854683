import { Skeleton as Skeleton_ } from "@chakra-ui/react";
import { forwardRef } from "react";
export const Skeleton = forwardRef(({ ...props }, ref) => {
    return <Skeleton_ ref={ref} {...props}>{props?.children}</Skeleton_>
});

import { SkeletonCircle as SkeletonCircle_ } from "@chakra-ui/react";
export const SkeletonCircle = forwardRef(({ ...props }, ref) => {
    return <SkeletonCircle_ ref={ref} {...props}>{props?.children}</SkeletonCircle_>
});

import { SkeletonText as SkeletonText_ } from "@chakra-ui/react";
export const SkeletonText = forwardRef(({ ...props }, ref) => {
    return <SkeletonText_ ref={ref} {...props}>{props?.children}</SkeletonText_>
});