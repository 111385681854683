import { createReducer } from '@reduxjs/toolkit';
import {
  acceptProposedNewTime,
  checkMeetingSecurity,
  checkMeetingValidity,
  clearJoinedMeetingData,
  clearMeetingRunning,
  clearMeetingWindowed,
  clearShowChat,
  clearShowMeet,
  deleteGoogleMeeting,
  deleteMeeting,
  deleteMeetingID,
  deleteOutlookMeeting,
  generateMeetingId,
  getEventDetail,
  getGoogleEventDetail,
  getGoogleMeetingDetails,
  getOutlookEventDetail,
  inviteUserToChat,
  isMeetingRunning,
  joinAMeeting,
  joinMeeting,
  joinedUsersMeeting,
  newGoogleMeeting,
  newMeeting,
  newOutlookMeeting,
  proposeNewTime,
  setJoinMeetNow,
  setMeetNow,
  setMeetingWindowed,
  setShowChat,
  setShowMeet,
  startDefaultMeeting,
  updateAttendeeStatus,
  updateMeetingSecurity,
  updateUserNavStatus,
  joinEventMeeting,
  scheduleMeetingWithUser,
  clearScheduleMeetingWithUser,getMeetingRecordings,deleteRecordings
} from '../actions/meeting.actions';
const isClient = typeof window !== 'undefined';
const initialState = {
  loading: false,
  flag: false,
  meetingCreate: {},
  googleMeetingDetails: {},
  googleMeetingCreate: {},
  outlookMeetingCreate: {},
  eventDetails: undefined,
  googleEventDetails: undefined,
  outlookEventDetails: undefined,
  meetingDeleted: undefined,
  googleMeetingDeleted: undefined,
  outlookMeetingDeleted: undefined,
  userNavStatus: 'online',
  joinedUsers: {},
  joinedMeetingData: {},
  isJoinAMeeting: false,
  defaultMeetingOpen: false,
  showChatBoxes: true,
  showMeetBoxes: true,
  generatedMeetingId: null,
  waitingRoomDetails: null,
  currentMeetingState: false,
  meetingWindowed: false,
  InviteStatus: null,
  eventMeetingDetails:null,
  scheduleUsers:{},
  recordingList:null
};

export const meetingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(newMeeting.pending, (state, action) => {
      state.flag = true;
      state.loading = true;
    })
    .addCase(newMeeting.fulfilled, (state, action) => {
      state.flag = false;
      state.loading = false;
      state.meetingCreate = action.payload;
    })
    .addCase(newMeeting.rejected, (state, action) => {
      state.flag = false;
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(newGoogleMeeting.pending, (state, action) => {
      state.flag = true;
      state.loading = true;
    })
    .addCase(newGoogleMeeting.fulfilled, (state, action) => {
      state.flag = false;
      state.loading = false;
      state.googleMeetingCreate = action.payload;
    })
    .addCase(newGoogleMeeting.rejected, (state, action) => {
      state.flag = false;
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(newOutlookMeeting.pending, (state, action) => {
      state.flag = true;
      state.loading = true;
    })
    .addCase(newOutlookMeeting.fulfilled, (state, action) => {
      state.flag = false;
      state.loading = false;
      state.outlookMeetingCreate = action.payload;
    })
    .addCase(newOutlookMeeting.rejected, (state, action) => {
      state.flag = false;
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(updateAttendeeStatus.pending, (state, action) => {
      state.flag = true;
      state.loading = true;
    })
    .addCase(updateAttendeeStatus.fulfilled, (state, action) => {
      state.flag = false;
      state.loading = false;
    })
    .addCase(updateAttendeeStatus.rejected, (state, action) => {
      state.flag = false;
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(acceptProposedNewTime.pending, (state, action) => {
      state.flag = true;
      state.loading = true;
    })
    .addCase(acceptProposedNewTime.fulfilled, (state, action) => {
      state.flag = false;
      state.loading = false;
    })
    .addCase(acceptProposedNewTime.rejected, (state, action) => {
      state.flag = false;
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(getEventDetail.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getEventDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.eventDetails = action.payload;
    })
    .addCase(getEventDetail.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(getGoogleEventDetail.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getGoogleEventDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.googleEventDetails = action.payload;
    })
    .addCase(getGoogleEventDetail.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(getOutlookEventDetail.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getOutlookEventDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.googleEventDetails = action.payload;
    })
    .addCase(getOutlookEventDetail.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(deleteMeeting.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(deleteMeeting.fulfilled, (state, action) => {
      state.loading = false;
      state.meetingDeleted = action.payload;
    })
    .addCase(deleteMeeting.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(deleteGoogleMeeting.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(deleteGoogleMeeting.fulfilled, (state, action) => {
      state.loading = false;
      state.meetingDeleted = action.payload;
    })
    .addCase(deleteGoogleMeeting.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(deleteOutlookMeeting.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(deleteOutlookMeeting.fulfilled, (state, action) => {
      state.loading = false;
      state.meetingDeleted = action.payload;
    })
    .addCase(deleteOutlookMeeting.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(proposeNewTime.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(proposeNewTime.fulfilled, (state, action) => {
      state.loading = false;
      state.meetingDeleted = action.payload;
    })
    .addCase(proposeNewTime.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(generateMeetingId.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(generateMeetingId.fulfilled, (state, action) => {
      state.loading = false;
      state.generatedMeetingId = action.payload.meeting_id;
    })
    .addCase(generateMeetingId.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(updateMeetingSecurity.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(updateMeetingSecurity.fulfilled, (state, action) => {
      state.loading = false;
      // state.generatedMeetingId = action.payload.meeting_id;
    })
    .addCase(updateMeetingSecurity.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(checkMeetingSecurity.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(checkMeetingSecurity.fulfilled, (state, action) => {
      state.loading = false;

      state.waitingRoomDetails = action.payload.meeting_detail;
    })
    .addCase(checkMeetingSecurity.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(checkMeetingValidity.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(checkMeetingValidity.fulfilled, (state, action) => {
      state.loading = false;
      state.meetingValidity = action.payload;
    })
    .addCase(checkMeetingValidity.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(deleteMeetingID.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(deleteMeetingID.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(deleteMeetingID.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(getMeetingRecordings.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getMeetingRecordings.fulfilled, (state, action) => {
      state.loading = false;
      state.recordingList = action.payload
    })
    .addCase(getMeetingRecordings.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(deleteRecordings.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(deleteRecordings.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(deleteRecordings.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Try again later or contact customer support';
    })
    .addCase(inviteUserToChat.fulfilled, (state, action) => {
      state.InviteStatus = action.payload;
    })
    .addCase(updateUserNavStatus, (state, action) => {
      state.userNavStatus = action.payload;
    })
    .addCase(joinedUsersMeeting, (state, action) => {
      state.joinedUsers = action.payload;
    })
    .addCase(joinAMeeting, (state, action) => {
      state.isJoinAMeeting = action.payload;
    })
    .addCase(joinMeeting, (state, action) => {
      state.joinedMeetingData = action.payload;
    })
    .addCase(joinEventMeeting, (state, action) => {
      state.eventMeetingDetails = action.payload;
    })
    .addCase(clearJoinedMeetingData, (state, action) => {
      state.joinedMeetingData = {};
      state.eventMeetingDetails = null;
    })
    .addCase(setShowChat, (state, action) => {
      state.showChatBoxes = action.payload;
    })
    .addCase(clearShowChat, (state, action) => {
      state.showChatBoxes = true;
    })
    .addCase(setShowMeet, (state, action) => {
      state.showMeetBoxes = action.payload;
    })
    .addCase(clearShowMeet, (state, action) => {
      state.showMeetBoxes = true;
    })
    .addCase(isMeetingRunning, (state, action) => {
      state.currentMeetingState = true;
    })
    .addCase(clearMeetingRunning, (state, action) => {
      state.currentMeetingState = false;
    })
    .addCase(setMeetingWindowed, (state, action) => {
      state.meetingWindowed = true;
    })
    .addCase(clearMeetingWindowed, (state, action) => {
      state.meetingWindowed = false;
    })
    .addCase(startDefaultMeeting, (state, action) => {
      state.defaultMeetingOpen = action.payload;
    })
    .addCase(getGoogleMeetingDetails, (state, action) => {
      state.loading = false;
      state.googleMeetingDetails = action.payload;
    })
    .addCase(setMeetNow, (state, action) => {
      state.meetNow = action.payload;
    })
    .addCase(setJoinMeetNow, (state, action) => {
      state.joinMeetNow = action.payload;
    })
    .addCase(scheduleMeetingWithUser, (state, action) => {
      state.scheduleUsers = action.payload;
    })
    .addCase(clearScheduleMeetingWithUser, (state, action) => {
      state.scheduleUsers = initialState.scheduleUsers;
    });
});
