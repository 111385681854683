/* eslint-disable @nx/enforce-module-boundaries */
import React from 'react';
import styles from './loader.module.scss';
import { UsersIcon, HomeIcon, Brifecase } from '@mybridge/icons';
import { Box } from '@mybridge/ui';

const SectionLoader = () => {
  return (
    <Box className={styles.noFreezeSpinner}>
      <Box id={styles.noFreezeSpinner}>
        <Box>
          <Box as="i" display="flex" justifyContent="center"><UsersIcon width={20} height={20} color="#3D5A80" /></Box>
          <Box as="i" display="flex" justifyContent="center"><HomeIcon width={20} height={20} color="#3D5A80" /></Box>
          <Box as="i" display="flex" justifyContent="center"><Brifecase width={20} height={20} color="#3D5A80" /></Box>
          <Box></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SectionLoader;
