import { Menu as Menu_ } from "@chakra-ui/react";
import { forwardRef } from "react";
export const Menu = forwardRef(({ ...props }, ref) => {
    return <Menu_ ref={ref} {...props}>{props?.children}</Menu_>
});

import { MenuButton as MenuButton_ } from "@chakra-ui/react";
export const MenuButton = forwardRef(({ ...props }, ref) => {
    return <MenuButton_ ref={ref} {...props}>{props?.children}</MenuButton_>
});

import { MenuList as MenuList_ } from "@chakra-ui/react";
export const MenuList = forwardRef(({ ...props }, ref) => {
    return <MenuList_ ref={ref} {...props}>{props?.children}</MenuList_>
});

import { MenuItem as MenuItem_ } from "@chakra-ui/react";
export const MenuItem = forwardRef(({ ...props }, ref) => {
    return <MenuItem_ ref={ref} {...props}>{props?.children}</MenuItem_>
});

import { MenuGroup as MenuGroup_ } from "@chakra-ui/react";
export const MenuGroup = forwardRef(({ ...props }, ref) => {
    return <MenuGroup_ ref={ref} {...props}>{props?.children}</MenuGroup_>
});

import { MenuDivider as MenuDivider_ } from "@chakra-ui/react";
export const MenuDivider = forwardRef(({ ...props }, ref) => {
    return <MenuDivider_ ref={ref} {...props}>{props?.children}</MenuDivider_>
});

import { MenuOptionGroup as MenuOptionGroup_ } from "@chakra-ui/react";
export const MenuOptionGroup = forwardRef(({ ...props }, ref) => {
    return <MenuOptionGroup_ ref={ref} {...props}>{props?.children}</MenuOptionGroup_>
});

import { MenuItemOption as MenuItemOption_ } from "@chakra-ui/react";
export const MenuItemOption = forwardRef(({ ...props }, ref) => {
    return <MenuItemOption_ ref={ref} {...props}>{props?.children}</MenuItemOption_>
});