/* eslint-disable @nx/enforce-module-boundaries */
import { Card } from '@mybridge/ui/card';
import { HStack, Stack } from '@mybridge/ui/layout';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { MultiStepFormContainer } from 'v4/components/multi-step-form/container';
import {
  MultiStepFormContext,
  useMultiStepsFormContext,
} from 'v4/components/multi-step-form/context';
import { MultiStepFormStep } from 'v4/components/multi-step-form/step';
import { MultiStepFormIndicator } from 'v4/components/multi-step-form/step-indicator';
import { UserOnboardingFormCareerDetailsStep } from '../career-details';
import { UserOnboardingFormConnectStep } from '../connect';
import { UserOnboardingFormImportContactsStep } from '../import-contacts';
import { UserOnboardingFormJobAvailabilityStep } from '../job-availability';
import { UserOnboardingFormProfilePicStep } from '../profile-pic';
import { UserOnboardingFormLocationStep } from '../user-location';

export const UserOnboardingStepsContainer = () => {
  const tid = useRef(-1);

  const multiStepForm = useMultiStepsFormContext();
  const { formData, setFields, setActive } = multiStepForm ?? {};

  const { onboardingStatus } = useSelector((state) => state.onboarding) ?? {};

  useEffect(() => {
    const { data, next_step: nextStep } = onboardingStatus ?? {};
    const { percentage } = data ?? {};
    if (percentage < 100) {
      const { profile, employment, photo, job_availabilty, contact, connect } = data ?? {};
      const entries = [
        { name: 'profile', value: profile },
        { name: 'employment', value: employment },
        { name: 'photo', value: photo },
        { name: 'job_availabilty', value: job_availabilty },
        { name: 'contact', value: contact },
        { name: 'connect', value: connect },
      ];
      const active = entries?.find?.((e) => !e.value)?.name;
      // console.log(active);
      if (active) {
        setActive(active);
      }
    }
  }, [onboardingStatus]);

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  // console.log(onboardingStatus);

  return (
    <Stack w="100%" pt={12}>
      <MultiStepFormContext.Provider value={multiStepForm}>
        <HStack maxW="3xl" mx="auto" w="100%">
          <MultiStepFormIndicator />
        </HStack>
        <MultiStepFormContainer>
          <Stack pt={8}>
            <MultiStepFormStep name="profile">
              <Card p={4} w="100%" mx="auto" maxW="xl">
                <UserOnboardingFormLocationStep />
              </Card>
            </MultiStepFormStep>
            <MultiStepFormStep name="employment">
              <Card p={4} w="100%" mx="auto" maxW="xl">
                <UserOnboardingFormCareerDetailsStep />
              </Card>
            </MultiStepFormStep>
            <MultiStepFormStep name="photo">
              <Card p={4} w="100%" mx="auto" maxW="xl">
                <UserOnboardingFormProfilePicStep />
              </Card>
            </MultiStepFormStep>
            <MultiStepFormStep name="job_availabilty">
              <Card p={4} w="100%" mx="auto" maxW="xl">
                <UserOnboardingFormJobAvailabilityStep />
              </Card>
            </MultiStepFormStep>
             {/* <MultiStepFormStep name="contact">
              <Card p={4} w="100%" mx="auto" maxW="xl">
                <UserOnboardingFormImportContactsStep />
              </Card>
            </MultiStepFormStep> */}
            <MultiStepFormStep name="connect">
                <UserOnboardingFormConnectStep />
            </MultiStepFormStep>
          </Stack>
        </MultiStepFormContainer>
      </MultiStepFormContext.Provider>
    </Stack>
  );
};
