
/**
 * Returns the extension of a filename
 * @param {*} fileName 
 * @returns 
 */
export const getFileExtension = (fileName) => {
    return (/[.]/.exec(fileName)) ? /[^.]+$/.exec(fileName) : undefined;
}

/**
 * Returns DataURL representation of a client side loaded file
 * @param {*} file 
 */
export const fileToDataURL = async (file) => {
    return new Promise((res, rej) => {
        if (file && typeof(file) !== 'string' && !file.file ) {
            const reader = new FileReader()
            reader.onloadend = () => {
                res(reader.result)
            }
            reader.readAsDataURL(file)
        } else {
            rej(false)
        }
    })
}