export const statusColor = {
  available: '#0AB33E',
  busy: '#FF0600',
  away: '#5B5B5B',
}

export const userStatus = [
  {
    id: 1,
    title: 'Available',
    subTitle: 'Based on activity',
    color: statusColor.available,
  },
  {
    id: 2,
    title: 'Busy',
    subTitle: 'Mute chat notifications',
    color: statusColor.busy,
  },
  {
    id: 3,
    title: 'Away',
    color: statusColor.away,
  },
];

export const userStatusMsg = [
  {
    id:1,
    title: 'In a meeting',
    key:'in_a_meeting',
    icon: '/images/meetingStatusIcon.svg',
    presence:'busy'
  },
  {
    id:2,
    title: 'Traveling',
    key:'traveling',
    icon: '/images/travellingStatusIcon.svg',
    presence:'offline'
  },
  {
    id:3,
    title: 'Out of office',
    key:'out_of_office',
    icon: '/images/oofficeStatusIcon.svg',
    presence:'offline'
  },
  {
    id:4,
    title: 'Out sick',
    key:'out_sick',
    icon: '/images/outSickStatusIcon.svg',
    presence:'offline'
  },
  {
    id:5,
    title: 'Working remotely',
    key:'working_remotely',
    icon: '/images/remoteStatusIcon.svg',
    presence:'busy'
  },
  {
    id:6,
    title: 'Vacationing',
    key:'Vacationing',
    icon: '/images/vacationStatusIcon.svg',
    presence:'offline'
  },
]