import { Editable as Editable_ } from "@chakra-ui/react";
import { forwardRef } from "react";
export const Editable = forwardRef(({ ...props }, ref) => {
    return <Editable_ ref={ref} {...props}>{props?.children}</Editable_>
});

import { EditableInput as EditableInput_ } from "@chakra-ui/react";
export const EditableInput = forwardRef(({ ...props }, ref) => {
    return <EditableInput_ ref={ref} {...props}>{props?.children}</EditableInput_>
});

import { EditableTextarea as EditableTextarea_ } from "@chakra-ui/react";
export const EditableTextarea = forwardRef(({ ...props }, ref) => {
    return <EditableTextarea_ ref={ref} {...props}>{props?.children}</EditableTextarea_>
});

import { EditablePreview as EditablePreview_ } from "@chakra-ui/react";
export const EditablePreview = forwardRef(({ ...props }, ref) => {
    return <EditablePreview_ ref={ref} {...props}>{props?.children}</EditablePreview_>
});