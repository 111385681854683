import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'packages/mybridgev4/store/common/axios';
import { jsonToFormData } from 'v4/lib/utils';
import API from '../common/api';
import { APIEndPoints } from '../common/endPoint';

export const getOnboardingStatus = createAsyncThunk(
  'getOnboardingStatus',
  async (body) => {
    const obj = {
      url: APIEndPoints.GetStatus(body.emailId),
      isNoToken: true,
      method: 'GET',
    };

    return await API(obj);
  }
);

export const setOnboardingData = createAsyncThunk(
  'setOnboardingData',
  async (payload) => {
    try {
      const token = localStorage.getItem('access');
      const headers = payload.isFormData
        ? {
            'Content-type':
              'multipart/form-data; boundary=63c5979328c44e2c869349443a94200e',
            Authorization: `Bearer ${token}`,
          }
        : {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${token}`,
          };
      const formData = payload.isFormData
        ? jsonToFormData(payload.data)
        : JSON.stringify(payload.data);
      const response = await axiosInstance.post(
        `${process.env.BASE_API_URL}${APIEndPoints.SetOnboardingData(payload.step, payload.emailId)}`,
        formData,
        { headers }
      );
      // const obj = {
      //   url: APIEndPoints.SetOnboardingData(payload?.step, payload?.emailId),
      //   body: payload.isFormData
      //     ? jsonToFormData(payload.data)
      //     : JSON.stringify(payload?.data),
      //   method: 'POST',
      //   isFormData: payload?.isFormData,
      //   headers: payload?.headers,
      // };

      // return await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getOnboardingData = createAsyncThunk(
  'getOnboardingData',
  async (body) => {
    const obj = {
      url: APIEndPoints.SetOnboardingData(body.step, body.emailId),
      method: 'GET',
    };

    return await API(obj);
  }
);

export const getOnboardingConnections = createAsyncThunk(
  'getOnboardingConnections',
  async (body) => {
    const obj = {
      url: APIEndPoints.SetOnboardingData(body.step, body.emailId),
      method: 'GET',
    };

    return await API(obj);
  }
);

export const resendEmail = createAsyncThunk('resendEmail', async (body) => {
  const obj = {
    url: APIEndPoints.Resend(body.emailId),
    method: 'POST',
    body: body,
    isNoToken: true,
  };

  return await API(obj);
});

export const clearOnboardingData = createAction('clearOnboardingData');
export const closeExternalPops = createAction('closeExternalPops');
export const showIntegrationModal = createAction('showIntegrationModal');
