// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';

const firebaseConfig = {

  apiKey: "AIzaSyAgVOefwthj1IqstmG8TEwBdeKgmKJMAQQ",

  authDomain: "mybridge-438909.firebaseapp.com",

  projectId: "mybridge-438909",

  storageBucket: "mybridge-438909.appspot.com",

  messagingSenderId: "163819327253",

  appId: "1:163819327253:web:7b07074703c6f71721231c",

  measurementId: "G-5CFS5T93DK"

};

// Initialize Firebase
export let firebaseApp = null;
export const getFirebaseApp = () => {
  if (!firebaseApp) {
    firebaseApp = initializeApp(firebaseConfig);
  }
  return firebaseApp;
};
export default firebaseApp;
