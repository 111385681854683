const API_KEY = process.env.BASE_API_KET;
const BASE_URL = process.env.BASE_API_URL;
const TOKEN_NAME = process.env.BASE_TOKEN_NAME;

const apiKeyHeader = {
  'x-api-key': API_KEY,
};

const get = async (url) => {
  const token = localStorage.getItem(TOKEN_NAME);
  const headers = {
    ...apiKeyHeader,
    accept: 'application/json',
    'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8',
    'content-type': 'application/json',
  };
  if (token) {
    headers.authorization = `Bearer ${token}`;
  }
  return fetch(`${BASE_URL}${url}`, {
    headers,
  });
};

const post = async (url, body) => {
  const token = localStorage.getItem(TOKEN_NAME);
  const headers = {
    ...apiKeyHeader,
    accept: 'application/json',
    // 'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8',
    'content-type': 'application/json charset=UTF-8',
  };

  if (token) {
    headers.authorization = `Bearer ${token}`;
  }

  return fetch(`${BASE_URL}${url}`, {
    headers,
    body: JSON.stringify(body),
    method: 'POST',
  });
};

const patch = async (url, body) => {
  const token = localStorage.getItem(TOKEN_NAME);
  const headers = {
    ...apiKeyHeader,
    accept: 'application/json',
    'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8',
    'content-type': 'application/json',
  };

  if (token) {
    headers.authorization = `Bearer ${token}`;
  }

  return fetch(`${BASE_URL}${url}`, {
    headers,
    body: JSON.stringify(body),
    method: 'PATCH',
  });
};

const put = async (url, body) => {
  const token = localStorage.getItem(TOKEN_NAME);
  const headers = {
    ...apiKeyHeader,
    accept: 'application/json',
    'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8',
    'content-type': 'application/json',
  };
  if (token) {
    headers.authorization = `Bearer ${token}`;
  }

  return fetch(`${BASE_URL}${url}`, {
    headers,
    body: JSON.stringify(body),
    method: 'PUT',
  });
};

const _delete = async (url) => {
  const token = localStorage.getItem(TOKEN_NAME);
  const headers = {
    ...apiKeyHeader,
    accept: 'application/json',
    'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8',
    'content-type': 'application/json',
  };
  if (token) {
    headers.authorization = `Bearer ${token}`;
  }

  return fetch(`${BASE_URL}${url}`, {
    headers,
    method: 'DELETE',
  });
};

const cancel = async (url, body, userIp) => {
  const headers = {
    ...apiKeyHeader,
    accept: 'application/json',
    'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8',
    'content-type': 'application/json',
  };
  if (userIp) {
    headers['x-client-ip'] = userIp;
  }

  return fetch(`${BASE_URL}${url}`, {
    headers,
    body: JSON.stringify(body),
    method: 'DELETE',
  });
};

const upload = async (url, body) => {
  const token = localStorage.getItem(TOKEN_NAME);
  const headers = {
    ...apiKeyHeader,
  };

  if (token) {
    headers.authorization = `Bearer ${token}`;
  }

  return fetch(`${BASE_URL}${url}`, {
    headers,
    body,
    method: 'POST',
  });
};

export default { get, post, put, delete: _delete, cancel, patch, upload };
