import { forwardRef } from 'react';
export const MyEventsIcon = forwardRef(({ ...props }, ref) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.88889 14.2V12H18.1111V14.2H5.88889ZM5.88889 18.6V16.4H14.4444V18.6H5.88889ZM3.44444 23C2.77222 23 2.19656 22.7848 1.71744 22.3543C1.23915 21.9231 1 21.405 1 20.8V5.4C1 4.795 1.23915 4.27727 1.71744 3.8468C2.19656 3.4156 2.77222 3.2 3.44444 3.2H4.66667V1H7.11111V3.2H16.8889V1H19.3333V3.2H20.5556C21.2278 3.2 21.8034 3.4156 22.2826 3.8468C22.7609 4.27727 23 4.795 23 5.4V20.8C23 21.405 22.7609 21.9231 22.2826 22.3543C21.8034 22.7848 21.2278 23 20.5556 23H3.44444ZM3.44444 20.8H20.5556V9.8H3.44444V20.8Z"
        fill={props?.color ?? '#5B5B5B'}
      />
    </svg>
  );
});
export const MyEventsIconColored = forwardRef(({ ...props }, ref) => {
  return (
    <svg {...props}
    width={props.width || 26}
    height={props.height || 26} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.05556 15.5V13H19.9444V15.5H6.05556ZM6.05556 20.5V18H15.7778V20.5H6.05556ZM3.27778 25.5C2.51389 25.5 1.85972 25.2554 1.31528 24.7663C0.771759 24.2763 0.5 23.6875 0.5 23V5.5C0.5 4.8125 0.771759 4.22417 1.31528 3.735C1.85972 3.245 2.51389 3 3.27778 3H4.66667V0.5H7.44444V3H18.5556V0.5H21.3333V3H22.7222C23.4861 3 24.1403 3.245 24.6847 3.735C25.2282 4.22417 25.5 4.8125 25.5 5.5V23C25.5 23.6875 25.2282 24.2763 24.6847 24.7663C24.1403 25.2554 23.4861 25.5 22.7222 25.5H3.27778ZM3.27778 23H22.7222V10.5H3.27778V23Z" fill="#365E7D"/>
<path d="M3.27778 25.5C2.51389 25.5 1.85972 25.2554 1.31528 24.7663C0.771759 24.2763 0.5 23.6875 0.5 23V5.5C0.5 4.8125 0.771759 4.22417 1.31528 3.735C1.85972 3.245 2.51389 3 3.27778 3H4.66667V0.5H7.44444V3H18.5556V0.5H21.3333V3H22.7222C23.4861 3 24.1403 3.245 24.6847 3.735C25.2282 4.22417 25.5 4.8125 25.5 5.5V23C25.5 23.6875 25.2282 24.2763 24.6847 24.7663C24.1403 25.2554 23.4861 25.5 22.7222 25.5H3.27778ZM3.27778 23H22.7222V10.5H3.27778V23Z" fill="#68A4D1"/>
</svg>

  );
});
