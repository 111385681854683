import { Fade as Fade_ } from "@chakra-ui/react";
import { forwardRef } from "react";
export const Fade = forwardRef(({ ...props }, ref) => {
    return <Fade_ ref={ref} {...props}>{props?.children}</Fade_>
});

import { ScaleFade as ScaleFade_ } from "@chakra-ui/react";
export const ScaleFade = forwardRef(({ ...props }, ref) => {
    return <ScaleFade_ ref={ref} {...props}>{props?.children}</ScaleFade_>
});

import { Slide as Slide_ } from "@chakra-ui/react";
export const Slide = forwardRef(({ ...props }, ref) => {
    return <Slide_ ref={ref} {...props}>{props?.children}</Slide_>
});

import { SlideFade as SlideFade_ } from "@chakra-ui/react";
export const SlideFade = forwardRef(({ ...props }, ref) => {
    return <SlideFade_ ref={ref} {...props}>{props?.children}</SlideFade_>
});

import { Collapse as Collapse_ } from "@chakra-ui/react";
export const Collapse = forwardRef(({ ...props }, ref) => {
    return <Collapse_ ref={ref} {...props}>{props?.children}</Collapse_>
});