import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import API from '../common/chat-api';
import { ChatAPiEndpoints } from '../common/endPoint';

export const chatLogin = createAsyncThunk('chatLogin', async (body) => {
    const obj = {
        url: ChatAPiEndpoints.Login,
        method: 'POST',
        body: JSON.stringify(body),
    };
    return await API(obj);
});

export const chatRegisterSession = createAsyncThunk('chatRegisterSession', async (body) => {
    const obj = {
        url: ChatAPiEndpoints.Register,
        method: 'POST',
        body: JSON.stringify(body),
    };
    return await API(obj);
});

export const chatRegister = createAsyncThunk('chatRegister', async (body) => {
    const obj = {
        url: ChatAPiEndpoints.Register,
        method: 'POST',
        body: JSON.stringify(body),
    };
    return await API(obj);
});

export const getUserPresence = createAsyncThunk('getUserPresence', async (userId) => {
    const obj = {
        url: ChatAPiEndpoints.UserPresence(userId),
        method: 'GET',
    };
    return await API(obj);
});
export const updateUserPresence = createAsyncThunk('updateUserPresence', async (body) => {
    const obj = {
        url: ChatAPiEndpoints.UserPresence(body.userId),
        method: 'PUT',
        body: JSON.stringify(body.body),
    };
    return await API(obj);
});

export const createMatrixRoom = createAsyncThunk('createMatrixRoom', async (body) => {
    const obj = {
        url: ChatAPiEndpoints.CreateRoom,
        method: 'POST',
        body: JSON.stringify(body),
    };
    return await API(obj);
});

export const inviteUserMatrixRoom = createAsyncThunk('inviteUserMatrixRoom', async (body) => {
    const obj = {
        url: ChatAPiEndpoints.InviteUser(body.roomId),
        method: 'POST',
        body: JSON.stringify(body),
    };
    return await API(obj);
});

export const acceptUserMatrixInvite = createAsyncThunk('acceptUserMatrixInvite', async (body) => {
    const obj = {
        url: ChatAPiEndpoints.InviteUser(body.roomId),
        method: 'POST',
        body: JSON.stringify(body),
    };
    return await API(obj);
});

export const startChatWithUser = createAction('startChatWithUser');
export const startChatWithUserbyMxId = createAction('startChatWithUserbyMxId');
export const clearChatUser = createAction('clearChatUser');
export const setActiveCallDetails = createAction('setActiveCallDetails');
export const clearActiveCallDetails = createAction('clearActiveCallDetails');
export const clearChatWithUserbyMxId = createAction('clearChatWithUserbyMxId');
export const setTeamChatNotification = createAction('getTeamChatNotification');
export const setMainChatNotification = createAction('setMainChatNotification');