import { Accordion as Accordion_ } from "@chakra-ui/react";
import { forwardRef } from "react";
export const Accordion = forwardRef(({ ...props }, ref) => {
    return <Accordion_ ref={ref} {...props}>{props?.children}</Accordion_>
});

import { AccordionButton as AccordionButton_ } from "@chakra-ui/react";
export const AccordionButton = forwardRef(({ ...props }, ref) => {
    return <AccordionButton_ ref={ref} {...props}>{props?.children}</AccordionButton_>
});

import { AccordionIcon as AccordionIcon_ } from "@chakra-ui/react";
export const AccordionIcon = forwardRef(({ ...props }, ref) => {
    return <AccordionIcon_ ref={ref} {...props}>{props?.children}</AccordionIcon_>
});

import { AccordionPanel as AccordionPanel_ } from "@chakra-ui/react";
export const AccordionPanel = forwardRef(({ ...props }, ref) => {
    return <AccordionPanel_ ref={ref} {...props}>{props?.children}</AccordionPanel_>
});

import { AccordionItem as AccordionItem_ } from "@chakra-ui/react";
export const AccordionItem = forwardRef(({...props}, ref) => {
    return <AccordionItem_ ref={ref} {...props}>{props?.children}</AccordionItem_>
});