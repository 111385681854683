import moment from 'moment';
import SparkMD5 from 'spark-md5';
import {
  AttendingIconMap,
  CelebratingIconMap,
  FeelingsIconMap,
} from './constants';
import { useDispatch } from 'react-redux';

/**
 * Returns combined name of a user
 * @param {*} user
 * @returns
 */
export const getUserFullName = (user) => {
  if (user?.display_name?.length) return user?.display_name;
  const fn =
    user?.former_name?.length && user?.former_name != 'null'
      ? `(${user?.former_name})`
      : '';
  return formatCommaSeparated([user?.first_name, fn, user?.last_name], ' ');
};
export  const getUserFirstName = (user) => {
  if (user?.display_name?.length) return user?.display_name;
  const fn =
    user?.former_name?.length && user?.former_name != 'null'
      ? `(${user?.former_name})`
      : '';
  return user?.first_name;
}

export const fancyTimeFormat = (duration) => {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = '';

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '');
  ret += '' + secs;

  return ret;
};
/**
 * Returns position of user
 * @param {*} user
 * @returns
 */
export const getUserPosition = (user) => {
  return user?.position;
};

/**
 * Returns education level of user
 * @param {*} user
 * @returns
 */
export const getUserEducationName = (user) => {
  const education =
    user?.education?.[0] ?? user?.education ?? user?.educations?.[0] ?? {};
  const { name } = education;
  return name;
};

/**
 * Returns edu of user
 * @param {*} user
 * @returns
 */
export const getUserEducation = (user) => {
  return user?.educations?.[0] ?? user?.education;
};

/**
 * Returns edu span of user
 * @param {*} user
 * @returns
 */
export const getUserEducationSpan = (user) => {
  const education = user?.educations?.[0] ?? user?.education;
  const { start_month, start_year, end_month, end_year } = education ?? {};

  const startMonth = start_month
    ? moment().set('M', start_month).format('MMM')
    : '';
  const startYear = start_year
    ? moment().set('Y', start_year).format('YYYY')
    : '';
  const endMonth = end_month ? moment().set('M', end_month).format('MMM') : '';
  const endYear = end_year ? moment().set('Y', end_year).format('YYYY') : '';
  const start = startMonth + (startYear ? `, ${startYear}` : '');
  const end = endMonth + (endYear ? `, ${endYear}` : '');
  const range = start + (end ? ' - ' + end : '');
  return { start, end, startMonth, startYear, endMonth, endYear, range };
};
/**
 * Returns exp span of user
 * @param {*} user
 * @returns
 */
export const getUserExperienceSpan = (user) => {
  const experience = user?.experiences?.[0] ?? user?.experience;
  const { start_month, start_year, end_month, end_year } = experience ?? {};

  const startMonth = start_month
    ? moment().set('M', start_month).format('MMM')
    : '';
  const startYear = start_year
    ? moment().set('Y', start_year).format('YYYY')
    : '';
  const endMonth = end_month ? moment().set('M', end_month).format('MMM') : '';
  const endYear = end_year ? moment().set('Y', end_year).format('YYYY') : '';
  const start =
    startMonth && startYear
      ? startMonth + (startYear ? `, ${startYear}` : '')
      : '';
  const end =
    endMonth && endYear ? endMonth + (endYear ? `, ${endYear}` : '') : '';
  const range = start + (end ? ' - ' + end : '');
  return { start, end, startMonth, startYear, endMonth, endYear, range };
};

/**
 * Returns the formated string version of user location
 * @param {*} user
 */
export const getUserLocation = (user) => {
  const { country, state, city } = user ?? {};
  return formatLocation(user);
};

/**
 * Extract profile pic from user
 * @param {*} user
 * @returns
 */
export const getUserProfilePic = (user) => {
  return user?.profile_pic?.includes('default-profile-pic')
    ? undefined
    : user?.profile_pic;
};

/**
 * Extract profile pic (filterout default)
 * @param {*} user
 * @returns
 */
export const getProfilePic = (entry, key = 'image_logo') => {
  return entry?.[key]?.includes('default-profile-pic') ||
    entry?.[key]?.includes('placeholder')
    ? undefined
    : entry?.[key];
};

export const getUserExperience = (user) => {
  return user?.experiences?.[0] ?? user?.experience;
};

/**
 * Extract profile pic from user
 * @param {*} user
 * @returns
 */
export const getUserCompanyName = (user) => {
  return user?.profile_pic?.includes('default-profile-pic')
    ? undefined
    : user?.profile_pic;
};

const rangeOfFullYearsWithFuture = (start, end) => {
  const fullYears = (end.getTime() - start.getTime()) / 31536000000;
  return Array(end.getFullYear() - start.getFullYear() + 1)
    .fill(start.getFullYear())
    .map((year, index) => year + index)
    .filter((year, index) => index <= fullYears)
    .map((year) => ({ label: year, value: year }));
};

const rangeOfFullYears = (startYear = 1920, type = 'forward') => {
  let currentYear = new Date().getFullYear();
  const yearRange = [];

  if (type === 'forward') {
    while (startYear <= currentYear) {
      const year = startYear++;
      yearRange.push({ label: year, value: year });
    }
  } else {
    while (currentYear >= startYear) {
      const year = currentYear--;
      yearRange.push({ label: year, value: year });
    }
  }

  return yearRange;
};

// const days = Array.from({length: 31}, (_, i) => i + 1).map(val => ({value:val, label: val}))

const getDaysInMonth = (month, year) => {
  // var date = new Date(year, month, 1);
  var date = new Date(Date.UTC(year, month, 1));
  var days = [];
  while (date.getUTCMonth() === month) {
    days.push(date.getUTCDate());
    date.setUTCDate(date.getUTCDate() + 1);
  }
  let finalDaysData = days.map((val) => ({ value: val, label: val }));

  return finalDaysData;
};

const meetingsByDate = (meetings) =>
  meetings?.reduce((acc, meeting) => {
    const date = new Date(meeting?.start_time);
    const key = isToday(date)
      ? 'Today'
      : isTomorrow(date)
      ? 'Tomorrow'
      : moment(date).format('ddd MMM D, YYYY');
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(meeting);
    return acc;
  }, {});

const todayDate = moment().format('YYYY-MM-DD');
const presentTime = moment();
const tomorrowDate = moment().startOf('day').add(1, 'day').format('YYYY-MM-DD');
const endOfToday = moment().endOf('day');

const isToday = (meetingDate) => {
  return moment(meetingDate).format('YYYY-MM-DD') === todayDate;
};

const isTomorrow = (meetingDate) => {
  return moment(meetingDate).format('YYYY-MM-DD') === tomorrowDate;
};

const isAfterTomorrow = (meetingDate) => {
  return moment(meetingDate).isAfter(tomorrowDate);
};

const isAfterPresentTimeAndToday = (meetingDate) => {
  return (
    moment(meetingDate).isAfter(presentTime) &&
    moment(meetingDate).isBefore(endOfToday)
  );
};

const isAfterPresentTime = (meetingDate) => {
  return moment(meetingDate).isAfter(presentTime);
};

const isBeforeToday = (meetingDate) => {
  return moment(meetingDate).isBefore(todayDate);
};

/**
 * Trims a long string and adds ellipsis(...) at the end
 * @param {*} str
 * @param {*} opts {length(number), appendSuffix(boolean), suffix(string)}
 * @returns
 */
export const trimStr = (str, opts) => {
  const { length = 20, appendSuffix = true, suffix = '...' } = opts || {};
  return str?.length > length
    ? str?.substring(0, length - 1) + (appendSuffix ? suffix : '')
    : str;
};

/**
 * Convers a string to pascal case
 * @param {*} str
 * @returns
 */
export const toPascalCase = (str) => {
  return str.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase();
  });
};

const isImageURL = (url) => {
  return /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png|jpeg)/g.test(url);
};

/**
 * Generate random ID of specific length
 * @param {*} length
 * @returns
 */
export const randomId = (length = 5) => {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    document.body.removeChild(textArea);
    return successful;
  } catch (err) {
    document.body.removeChild(textArea);
    console.error('Fallback: Oops, unable to copy', err);
    return false;
  } finally {
    document.body.removeChild(textArea);
  }
}

export async function copyTextToClipboard(text) {
  if (
    typeof window !== 'undefined' &&
    typeof window.navigator !== 'undefined'
  ) {
    if (!navigator.clipboard) {
      return fallbackCopyTextToClipboard(text);
    }
    await navigator.clipboard.writeText(text);
    return true;
  }
}

export const currencyFormatter = (number, currency = 'USD') => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency ? currency : 'USD',
    maximumFractionDigits: 0,

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(number);
};

export const convertToSlug = (text) => {
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
};

export {
  getDaysInMonth,
  isAfterPresentTime,
  isAfterPresentTimeAndToday,
  isAfterTomorrow,
  isBeforeToday,
  isImageURL,
  isToday,
  isTomorrow,
  meetingsByDate,
  rangeOfFullYears,
  rangeOfFullYearsWithFuture,
};

export const calculateChecksum = async (file, chunkSize) => {
  return new Promise((res, rej) => {
    let md5;
    let slice =
        File.prototype.slice ||
        File.prototype.mozSlice ||
        File.prototype.webkitSlice,
      chunks = Math.ceil(file.size / chunkSize),
      current_chunk = 0,
      spark = new SparkMD5.ArrayBuffer();

    function onload(e) {
      try {
        spark.append(e.target.result); // append chunk
        current_chunk++;
        if (current_chunk < chunks) {
          read_next_chunk();
        } else {
          md5 = spark.end();
          res({ md5, totalChunks: chunks });
        }
      } catch (e) {
        rej(e);
      }
    }

    function read_next_chunk() {
      try {
        var reader = new FileReader();
        reader.onload = onload;
        var start = current_chunk * chunkSize,
          end = Math.min(start + chunkSize, file.size);
        reader.readAsArrayBuffer(slice.call(file, start, end));
      } catch (e) {
        rej(e);
      }
    }
    read_next_chunk();
  });
};

export const postHasInsights = (post) => {
  const { like_count, dislike_count, comment_count, reposts_by } = post ?? {};
  return (
    (like_count ?? 0) + (dislike_count ?? 0) > 0 ||
    comment_count > 0 ||
    reposts_by?.length > 0
  );
};

export const cleanHTML = (str, nodes) => {
  // Do stuff here...
  function stringToHTML() {
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, 'text/html');
    return doc.body;
  }

  // Convert the string to HTML
  let html = stringToHTML();
  return html;
};

export const imageLinkToFileObject = async (url, props) => {
  return new Promise((res, rej) => {
    fetch(url)
      .then(async (response) => {
        const contentType = props?.type ?? response.headers.get('content-type');
        const blob = await response.blob();
        const file = new File([blob], props?.fileName ?? randomId(), {
          contentType,
        });
        res(file);
        // access file here
      })
      .catch((e) => {
        rej(e);
      });
  });
};

export const getFileExtension = (fileName) => {
  return fileName?.substring(fileName?.lastIndexOf('.') + 1);
};
export const getFileName = (fileName) => {
  return fileName?.substring(0, fileName?.lastIndexOf('.'));
};

export const getFullFileName = (file) => {
  return file?.name + (file?.extension ? `.${file?.extension}` : '');
};

export const getDomainName = () => {
  if (typeof window === 'undefined') return;
  const { host, protocol } = window.location ?? {};
  return protocol + '//' + host;
};

export const randomString = (length, chars) => {
  var mask = '';
  if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
  if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  if (chars.indexOf('#') > -1) mask += '0123456789';
  if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
  var result = '';
  for (var i = length; i > 0; --i)
    result += mask[Math.floor(Math.random() * mask.length)];
  return result;
};

export const convertHTMLToPlainText = (html) => {
  if (typeof window === 'undefined') return;
  var tempDivElement = document.createElement('div');
  tempDivElement.innerHTML = html;
  const text = tempDivElement.textContent || tempDivElement.innerText || '';
  tempDivElement.remove();
  return text;
};

export const formatLocation = (location, { countryVisible = true } = {}) => {
  if (location?.city && location?.city !== "undefined" ) {
    return [
      location?.city ?? '',
      (location?.state || location?.state_region) ?? '',
      countryVisible ? location?.country ?? '' : '',
    ]
      ?.filter?.((v) => v.length)
      ?.join(', ');
  } else if (
    (location?.statelocation?.state && location?.statelocation?.state !== "undefined" ) ||
    (location?.state  && location?.state!== "undefined")||
    (location?.state_region &&  location?.state_region!== "undefined")
  ) {
    return [
      (location?.state || location?.state_region) ?? '',
      countryVisible ? location?.country ?? '' : '',
    ]
      ?.filter?.((v) => v.length)
      ?.join(', ');
  } else if(location?.country && location?.country!=="undefined") {
    return location?.country;
  }
};

export const phoneWithCode = (num) => {
  if (num?.length > 10) {
    const code = num?.substr?.(0, num.length - 10);
    const phone = num?.substr?.(num.length - 10);
    return (
      <>
        {code.replace('-', '')} {phone}
      </>
    );
  }
  return num;
};

export const formatCommaSeparated = (arr = [], delimiter = ', ') => {
  return arr?.filter?.((v) => v?.length)?.join(delimiter);
};

export const formatPipeSeparated = (arr = [], delimiter = '| ') => {
  return arr?.filter?.((v) => v?.length)?.join(delimiter);
};

export const getFeelingObject = (str) => {
  const [type, name] = str?.split?.(':') ?? [];
  if (type && name) {
    let obj = null;
    if (type === 'feeling') {
      obj = FeelingsIconMap?.find((f) => f.name === name);
    }
    if (type === 'celebrating') {
      obj = CelebratingIconMap?.find((f) => f.name === name);
    }
    if (type === 'attending') {
      obj = AttendingIconMap?.find((f) => f.name === name);
    }
    return { ...obj, type };
  }
};

export const extractCookie = (cookie, name) => {
  const pattern = new RegExp(`${name}=([^;]+)`);
  try {
    const matched = cookie.match(pattern);
    return matched ? matched[1] : null;
  } catch (e) {
    console.error(e);
    return null;
  }
};
export const setCookie = (name, value, days = 7) => {
  if (typeof window === 'undefined') return;
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie =
    name + '=' + (JSON.stringify(value) || '') + expires + '; path=/';
};
export const getCookie = (name) => {
  if (typeof window === 'undefined') return;
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) {
      const str = c.substring(nameEQ.length, c.length);
      return JSON.parse(str?.length ? str : '""');
    }
  }
  return null;
};
export const eraseCookie = (name) => {
  if (typeof window === 'undefined') return;
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export const setLocalKV = (name, value, days = 7) => {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(name, JSON.stringify(value));
  }
};
export const getLocalKV = (name) => {
  if (typeof localStorage !== 'undefined') {
    const item = localStorage.getItem(name);
    return item ? JSON.parse(item) : null;
  }
  return null;
};
export const eraseLocalKV = (name) => {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(name);
  }
};
