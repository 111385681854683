import { Input as Input_ } from '@chakra-ui/react';
import { forwardRef } from 'react';
export const Input = forwardRef(({ ...props }, ref) => {
  return (
    <Input_ ref={ref} {...props}>
      {props?.children}
    </Input_>
  );
});

import { InputLeftAddon as InputLeftAddon_ } from '@chakra-ui/react';
export const InputLeftAddon = forwardRef(({ ...props }, ref) => {
  return (
    <InputLeftAddon_ ref={ref} {...props}>
      {props?.children}
    </InputLeftAddon_>
  );
});

import { InputAddon as InputAddon_ } from '@chakra-ui/react';
export const InputAddon = forwardRef(({ ...props }, ref) => {
  return (
    <InputAddon_ ref={ref} {...props}>
      {props?.children}
    </InputAddon_>
  );
});

import { InputGroup as InputGroup_ } from '@chakra-ui/react';
export const InputGroup = forwardRef(({ ...props }, ref) => {
  return (
    <InputGroup_ ref={ref} {...props}>
      {props?.children}
    </InputGroup_>
  );
});

import { InputRightAddon as InputRightAddon_ } from '@chakra-ui/react';
export const InputRightAddon = forwardRef(({ ...props }, ref) => {
  return (
    <InputRightAddon_ ref={ref} {...props}>
      {props?.children}
    </InputRightAddon_>
  );
});

import { InputLeftElement as InputLeftElement_ } from '@chakra-ui/react';
export const InputLeftElement = forwardRef(({ ...props }, ref) => {
  return (
    <InputLeftElement_ ref={ref} {...props}>
      {props?.children}
    </InputLeftElement_>
  );
});

import { InputRightElement as InputRightElement_ } from '@chakra-ui/react';
export const InputRightElement = forwardRef(({ ...props }, ref) => {
  return (
    <InputRightElement_ ref={ref} {...props}>
      {props?.children}
    </InputRightElement_>
  );
});
