import { createReducer } from '@reduxjs/toolkit';
import { createSupportTicket } from '../actions/support.actions';

const initialState = {
    loading: false,
    supportResponse:null
}

export const supportReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(createSupportTicket.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    })
    .addCase(createSupportTicket.fulfilled, (state, action) => {
      state.loading = false;
      state.supportResponse = action.payload;
    })
    .addCase(createSupportTicket.rejected, (state, action) => {
        state.loading = false
    })
});
