import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../common/api';
import { APIEndPoints } from '../common/endPoint';

export const cardList = createAsyncThunk('cardList', async (body) => {
    const obj = {
      url: `${APIEndPoints.PaymentCards}`,
      method: 'GET',
    };
    return await API(obj);
  });
  
  export const addCard = createAsyncThunk('addCard', async (body) => {
    const obj = {
      url: `${APIEndPoints.PaymentCards}`,
      method: 'POST',
      body: JSON.stringify(body),
    };
    return await API(obj);
  });

  export const addCardCallBack = createAsyncThunk('addCardCallBack', async (body) => {
    const obj = {
      url: `${APIEndPoints.PaymentCards}callback`,
      method: 'POST',
      body: JSON.stringify(body),
    };
    return await API(obj);
  });
  
  export const updateCard = createAsyncThunk('updateCard', async (body) => {
    const obj = {
      url: `${APIEndPoints.PaymentCards}`,
      method: 'PATCH',
      body: JSON.stringify(body),
    };
    return await API(obj);
  });
  
  export const deleteCard = createAsyncThunk('deleteCard', async (id) => {
    const obj = {
      url: `${APIEndPoints.PaymentCards}${id}/`,
      method: 'DELETE',
    };
    return await API(obj);
  });
  
  export const getBillingDet = createAsyncThunk('getBillingDet', async (payload) => {
    // console.log(payload);
    const { page, start_date, end_date, transaction_type, service_type, currency} = payload ?? {};
    const obj = {
      url: `${APIEndPoints.BillingHistory}?page=${page ? page : 1}&currency=${currency ? currency : ''}&service_type=${service_type ? service_type : ''}&transaction_type=${transaction_type ? transaction_type : ''}&start_date=${start_date ? start_date : ''}&end_date=${end_date ? end_date : ''}`,
    };
    return await API(obj);
  });

  export const getDiskCheckoutLink = createAsyncThunk('getDiskCheckoutLink', async (body) => {
    const obj = {
      url: `${APIEndPoints.DiskCheckout}`,
      method: 'POST',
      body: JSON.stringify(body),
    };
    return await API(obj);
  });