/* eslint-disable @nx/enforce-module-boundaries */
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getGlobalCities } from 'v4/store/actions/global.data.actions';
import { getNextPageFromURL } from 'v4/utils/utils';

export const useCityStateCountry = ({ search, countryCode }) => {
  const dispatch = useDispatch();
  const tid = useRef(-1);

  const searchFn = async ({ search, countryCode }) => {
    clearTimeout(tid.current);
    return new Promise((res, rej) => {
      tid.current = setTimeout(async () => {
        const resp = await fetch(
          `${process.env.BASE_API_URL}api/v1/location/google/search?address=${search ?? ''}${
            countryCode?.length ? `&countryCode=${countryCode ?? ''}` : ``
          }`
        );
        // const resp = await fetch(
        //   `api/v1/location/google/search?address=${search ?? ''}${
        //     countryCode?.length ? `&countryCode=${countryCode ?? ''}` : ``
        //   }`
        // );
        res(await resp?.json?.());
        // res(
        //   (
        //     await dispatch(
        //       getGlobalCities({
        //         search,
        //         country_code,
        //       })
        //     )
        //   )?.payload ?? {}
        // );
      }, 300);
    });
  };

  const { data, fetchNextPage, refetch, isFetching, isLoading,status } =
    useInfiniteQuery({
      queryKey: ['cityStateCountry', search],
      // eslint-disable-next-line no-empty-pattern
      queryFn: async ({}) => {
        return await searchFn({ search, countryCode });
      },
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.next) {
          return getNextPageFromURL(lastPage.next);
        }
        return undefined;
      },
      enabled: false,
    });

    // console.log(data);

  return {
    data,
    refetch,
    fetchNextPage,
    isLoading,
    isFetching,
    status
  };
};
