import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const defaultProps = defineStyle({
  color: "brandPrimary.500"
});

export const linkTheme = defineStyleConfig({
  defaultProps: {
    color: "brandPrimary.500",
    textDecoration: 'none',
    link: {
      textDecoration: 'none',
    },
    _hover: {
      textDecoration: "none!important"
    }
  }
});
