import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../common/api';
import { APIEndPoints, GlobalAPIEndpoints } from '../common/endPoint';

export const getFeelingsAndActivities = createAsyncThunk(
  'getFeelingsAndActivities',
  async () => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetFeelingsAndActivities()}`,
    };
    return await API(obj);
  }
);

export const getGlobalCountries = createAsyncThunk(
  'globalCountries',
  async ({ page = 1, search = '' } = {}) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetCountries(page, search)}`,
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const getGlobalStates = createAsyncThunk(
  'globalStates',
  async ({ page = 1, search = '', country_code = '' } = {}) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetStates(page, search, country_code)}`,
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const getGlobalCities = createAsyncThunk(
  'globalCities',
  async ({ search = '', country_code = '' } = {}) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetCities({ search, country_code })}`,
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const getGlobalCompanies = createAsyncThunk(
  'globalCompanies',
  async ({ page = 1, search = '' } = {}) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetCompanies(page, search)}`,
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const createGlobalCompanies = createAsyncThunk(
  'createGlobalCompanies',
  async (body) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GDCompanies}`,
      method: 'POST',
      body: JSON.stringify(body),
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const createGlobalJobTitles = createAsyncThunk(
  'createGlobalJobTitles',
  async (body) => {
    const obj = {
      url: `${GlobalAPIEndpoints.CreateJobTitles}`,
      method: 'POST',
      body: JSON.stringify(body),
    };
    return await API(obj);
  }
);

export const getGlobalJobTitles = createAsyncThunk(
  'getGlobalJobTitles',
  async ({ page = 1, search }) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetJobTitles}?page=${page}&search=${search}`,
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const getGlobalCompanyTypes = createAsyncThunk(
  'globalCompanyTypes',
  async ({ page = 1, search = '' } = {}) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetCompanyTypes(page, search)}`,
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const getGlobalCompanySize = createAsyncThunk(
  'globalCompanySize',
  async ({ page = 1, search = '' } = {}) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetCompanySize(page, search)}`,
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const getGlobalSkills = createAsyncThunk(
  'globalSkills',
  async ({ page = 1, search = '' } = {}) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetSkills(page, search)}`,
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const getGlobalSchools = createAsyncThunk(
  'globalSchools',
  async ({ page = 1, search = '' } = {}) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetSchools(page, search)}`,
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const createGlobalSchools = createAsyncThunk(
  'createGlobalSchools',
  async (body) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetSchools}`,
      method: 'POST',
      body: JSON.stringify(body),
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const createGlobalSkills = createAsyncThunk(
  'createGlobalSkills',
  async (body) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetSkills}`,
      method: 'POST',
      body: JSON.stringify(body),
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const getGlobalColleges = createAsyncThunk(
  'globalColleges',
  async ({ page = 1, search = '' } = {}) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetColleges(page, search)}`,
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const createGlobalColleges = createAsyncThunk(
  'createGlobalColleges',
  async ({ page = 1, search = '' } = {}) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetColleges}`,
      method: 'POST',
      // body: JSON.stringify(body),
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const getGlobalCertificates = createAsyncThunk(
  'globalCertificates',
  async ({ page = 1, search = '' } = {}) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetCertificates(page, search)}`,
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const getGlobalLanguages = createAsyncThunk(
  'globalLanguages',
  async ({ page = 1, search = '' } = {}) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetLanguagea(page, search)}`,
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const createGlobalLanguages = createAsyncThunk(
  'createGlobalCourses',
  async ({ page = 1, search = '' } = {}) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetCourses}`,
      method: 'POST',
      // body: JSON.stringify(body),
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const getGlobalCertificateAuthorities = createAsyncThunk(
  'globalCertificateAuthorities',
  async ({ page = 1, search = '' } = {}) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetCertificateAuthorities(page, search)}`,
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const getGlobalCourses = createAsyncThunk(
  'globalCourses',
  async ({ page = 1, search } = {}) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetCourses(page, search)}`,
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const createGlobalCourses = createAsyncThunk(
  'createGlobalCourses',
  async ({ page = 1, search = '' } = {}) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetCourses}`,
      method: 'POST',
      // body: JSON.stringify(body),
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const getGlobalDegrees = createAsyncThunk(
  'globalDegrees',
  async ({ page = 1, search = '' } = {}) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetDegrees(page, search)}`,
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const createGlobalDegrees = createAsyncThunk(
  'createGlobalDegrees',
  async ({ page = 1, search = '' } = {}) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetDegrees}`,
      method: 'POST',
      // body: JSON.stringify(body),
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const getGlobalIndustries = createAsyncThunk(
  'globalIndustries',
  async ({ page = 1, search = '', pageSize } = {}) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetIndustries({ page, search, pageSize })}`,
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const getGlobalIndustriesDetail = createAsyncThunk(
  'getGlobalIndustriesDetail',
  async (id) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetIndustryDetails}${id}/`,
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const getGlobalCurrencies = createAsyncThunk(
  'getGlobalCurrencies',
  async ({ page = 1, search = '' } = {}) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetCurrencies(page, search)}`,
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const createGlobalIndustries = createAsyncThunk(
  'createGlobalIndustries',
  async (payload) => {
    const obj = {
      url: `${GlobalAPIEndpoints.CreateIndustry()}`,
      body: JSON.stringify(payload ?? {}),
      method: 'POST',
    };
    return await API(obj);
  }
);


export const searchGlobalLocations = createAsyncThunk(
  'searchGlobalLocations',
  async (payload) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetGlobalLocations(payload)}`,
    };
    return await API(obj);
  }
);

export const searchAll = createAsyncThunk('search-all', async (payload) => {
  const obj = {
    url: `${APIEndPoints.Search(payload)}`,
    isNoToken: payload?.isNoToken,
  };
  return await API(obj);
});
export const searchAllPublic = createAsyncThunk(
  'search-all-public',
  async (payload) => {
    const obj = {
      url: payload?.category
        ? `${APIEndPoints.SearchPublic(payload)}`
        : `${APIEndPoints.SearchAllPublic(payload)}`,
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const getSearchArticles = createAsyncThunk(
  'getSearchArticles',
  async (payload) => {
    const obj = {
      url: `${APIEndPoints.ArticleSearch(payload)}`,
      method: "GET",
      isNoToken: true,
    }
    return await API(obj);
  }
);


export const getRecentSearch = createAsyncThunk(
  'getRecentSearch',
  async (payload) => {
    const obj = {
      url: `${APIEndPoints.RecentSearch}/`,
      method: "GET",
    }
    return await API(obj);
  }
);

export const updateRecentSearch = createAsyncThunk(
  'updateRecentSearch',
  async (payload) => {
    const obj = {
      url: `${APIEndPoints.RecentSearch}/`,
      method: "POST",
      body: JSON.stringify(payload)
    }
    return await API(obj);
  }
);

export const deleteRecentSearchID = createAsyncThunk(
  'deleteRecentSearchID',
  async (payload) => {
    const obj = {
      url: `${APIEndPoints.RecentSearch}/`,
      method: "DELETE",
      body: JSON.stringify(payload)
    }
    return await API(obj);
  }
);


export const getStaticPageContent = createAsyncThunk(
  'getStaticPageContent',
  async (payload) => {
    const obj = {
      url: `${APIEndPoints.StaticPages}/${payload}/`,
      method: "GET",
      isNoToken: true,
    }
    return await API(obj);
  }
);

export const getGlobalInterestAndTraits = createAsyncThunk(
  'getGlobalInterestAndTraits',
  async ({ page = 1, search } = {}) => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetInterestTraits(page, search)}`,
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const getUpcomingMeetingCount = createAsyncThunk(
  'getUpcomingMeetingCount',
  async () => {
    const obj = {
      url: `${GlobalAPIEndpoints.GetUpcomingMeetingCount}`,
    };
    return await API(obj);
  }
);


export const updateMeetingSeen = createAsyncThunk(
  'updateMeetingSeen',
  async (payload) => {
    const obj = {
      url: `${GlobalAPIEndpoints.UpdateMeetingSeen}`,
      method: "POST",
      body: JSON.stringify({ meeting_id: payload }),
    }
    return await API(obj);
  }
);


export const setMobileDevice = createAction("IS_MOBILE");
export const setTabletDevice = createAction("IS_TABLET");
export const setPageHeadPrepend = createAction('setPageHeadPrepend');
export const setPageHeadAppend = createAction('setPageHeadAppend');
export const setPageHead = createAction('setPageHead');
export const setHeaderGlobalNotifCount = createAction('setHeaderGlobalNotifCount');
