/* eslint-disable @nx/enforce-module-boundaries */
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  createGlobalCompanies,
  getGlobalCompanies,
} from 'v4/store/actions/global.data.actions';
import { getNextPageFromURL } from 'v4/utils/utils';

export const useCompanies = ({ search, enabled = true }) => {
  const dispatch = useDispatch();
  const tid = useRef(-1);

  useEffect(() => {
    if (search?.length) {
      clearTimeout(tid.current);
      tid.current = setTimeout(() => {
        refetch?.();
      }, 300);
    }
  }, [search]);

  const {
    mutateAsync,
    mutate,
    isLoading: createLoading,
  } = useMutation({
    mutationKey: ['global-companies-create', search],
    mutationFn: async () => {
      return dispatch(createGlobalCompanies({ name: search }));
    },
    onSuccess: () => {
      refetch();
    },
  });
  const {
    isLoading,
    isFetching,
    data = { pages: [] },
    fetchNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['global-companies', search],
    queryFn: async () => {
      return (await dispatch(getGlobalCompanies({ search })))?.payload;
    },
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.next) {
        return getNextPageFromURL(lastPage?.next);
      }
      return undefined;
    },
    refetchOnWindowFocus: false,
    enabled,
  });

  return {
    isLoading: isFetching,
    createLoading,
    data,
    fetchNextPage,
    refetch,
    search,
    mutate,
    mutateAsync,
  };
};
