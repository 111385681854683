/* eslint-disable @nx/enforce-module-boundaries */
import {
  ArticleIcon,
  BoxPlus,
  CheckAll,
  JobsIcon,
  PagesIcon,
  UsersIcon,
} from '@mybridge/icons';
import { MyEventsIcon } from '@mybridge/icons/MyEvents';
import { TeamsIcon } from '@mybridge/icons/user-menu';
import { useInfiniteQuery, useQuery, useMutation } from '@tanstack/react-query';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { stringify } from 'querystring';
import { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenAuthModal } from 'v4/store/actions/auth.actions';
import {
  getRecentSearch,
  searchAll,
  searchAllPublic,
  updateRecentSearch,
} from 'v4/store/actions/global.data.actions';
import { getNextPageFromURL } from 'v4/utils/utils';
export const SearchContext = createContext();

export const SearchContextProvider = ({ children }) => {
  const ctx = useSearchContext({});
  return (
    <SearchContext.Provider value={ctx}>{children}</SearchContext.Provider>
  );
};

export const SearchTabs = ({ query } = {}) => {
  const qry = typeof window !== 'undefined' ? window.location.search : '';

  return [
    {
      title: 'All',
      name: 'all',
      link: '/g' + qry,
      icon: (props) => <CheckAll width={20} height={20} {...props} />,
    },
    {
      title: 'Posts',
      name: 'posts',
      link: '/' + qry,
      icon: (props) => <BoxPlus width={20} height={20} {...props} />,
    },
    {
      title: 'People',
      name: 'people',
      link: '/g/people' + qry,
      icon: (props) => <UsersIcon width={20} height={20} {...props} />,
    },
    {
      title: 'Jobs',
      name: 'jobs',
      link: '/g/jobs' + qry,
      icon: (props) => <JobsIcon width={20} height={20} color="#5b5b5b" {...props} />,
    },
    {
      title: 'Pages',
      link: '/g/pages' + qry,
      name: 'pages',
      icon: (props) => <PagesIcon width={20} height={20} {...props} />,
    },
    {
      title: 'Teams',
      name: 'team',
      link: '/g/teams' + qry,
      icon: (props) => <TeamsIcon width={20} height={20} {...props} />,
    },
    {
      title: 'Events',
      name: 'events',
      link: '/g/events' + qry,
      icon: (props) => <MyEventsIcon width={22} height={22} {...props} />,
    },
    {
      title: 'Articles',
      name: 'articles',
      link: '/g/articles' + qry,
      icon: (props) => <ArticleIcon width={20} height={20} {...props} />,
    },
  ];
};

export const useSearchContext = ({
  searchAllEnabled = false,
  searchPeopleEnabled = false,
  searchJobsEnabled = false,
  searchCompanyPagesEnabled = false,
  searchPostsEnabled = false,
  searchTeamsEnabled = false,
  searchEventsEnabled = false,
  searchArticlesEnabled = false,
}) => {
  const { pathname, query, push } = useRouter();
  const [search, setSearch] = useState();
  const queryStr = stringify(query ?? {});
  const { loggedIn, userLoggedOut } = useSelector((s) => s.user);
  const [activeTab, setActiveTab] = useState();
  const [postsFilters, setPostsFilters] = useState();
  const [eventsFilters, setEventsFilters] = useState();
  const [articlesFilters, setArticlesFilters] = useState();
  const [peopleFilters, setPeopleFilters] = useState();
  const [jobsFilters, setJobsFilters] = useState();
  const [companyPagesFilters, setCompanyPagesFilters] = useState();
  const [teamsFilters, setTeamsFilters] = useState();
  const [resetFilters, setResetFilters] = useState(false)
  const [recentQueryResults, setRecentQueryResults] = useState();
  const dispatch = useDispatch();
  const tid = useRef(-1);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { recentSearches } = useSelector((state) => state.globalData);
  useEffect(() => {
    setSearch(query?.query);
  }, [query?.query]);

  useEffect(() => {
    if (loggedIn) dispatch(getRecentSearch());
  }, [loggedIn]);

  useEffect(() => {
    setRecentQueryResults(
      _.find(recentSearches?.results, (result) => result.query === query?.query)
    );
  }, [recentSearches, query?.query]);

  // console.log('resetFilters', resetFilters)
  // useEffect(() => {
  //   if (activeTab === 'jobs') {
  //     if (query?.query) {
  //       push(`/jobs/search?title=${query?.query}&query=${query?.query}`);
  //     } else {
  //       push(`/jobs/search`);
  //     }
  //   }
  // }, [activeTab]);

  useEffect(() => {
    clearTimeout(tid.current);
    tid.current = setTimeout(() => {
      if (activeTab === 'posts') {
        refetchPostsSearch?.();
      }
    }, 10);
  }, [postsFilters, search, loggedIn, userLoggedOut]);

  useEffect(() => {
    clearTimeout(tid.current);
    tid.current = setTimeout(() => {
      if (activeTab === 'posts') {
        refetchPostsSearch?.();
      }
    }, 10);
  }, []);

  useEffect(() => {
    clearTimeout(tid.current);
    tid.current = setTimeout(() => {
      if (activeTab === 'events') {
        refetchEventsSearch?.();
      }
    }, 10);
  }, [eventsFilters, search]);

  useEffect(() => {
    clearTimeout(tid.current);
    tid.current = setTimeout(() => {
      if (activeTab === 'articles') {
        refetchArticlesSearch?.();
      }
    }, 10);
  }, [articlesFilters, search]);

  useEffect(() => {
    clearTimeout(tid.current);
    tid.current = setTimeout(() => {
      if (activeTab === 'people') {
        refetchPeopleSearch?.();
      }
    }, 10);
  }, [peopleFilters, search]);

  useEffect(() => {
    clearTimeout(tid.current);
    tid.current = setTimeout(() => {
      if (activeTab === 'pages') {
        // console.log(companyPagesFilters);
        refetchCompanyPagesSearch?.();
      }
    }, 10);
  }, [companyPagesFilters, search]);

  useEffect(() => {
    clearTimeout(tid.current);
    tid.current = setTimeout(() => {
      if (activeTab === 'team') {
        refetchTeamsSearch?.();
      }
    }, 10);
  }, [teamsFilters, search]);

  useEffect(() => {
    clearTimeout(tid.current);
    tid.current = setTimeout(() => {
      if (activeTab === 'jobs') {
        refetchJobsSearch?.();
      }
    }, 10);
  }, [jobsFilters, search]);

  useEffect(() => {
    clearTimeout(tid.current);
    tid.current = setTimeout(() => {
      if (search) {
        const recentSearchesCount = _.find(recentSearches?.results, (result) => result.query === search);
        console.log('recentSearches', recentSearchesCount)
        refetchAllSearch?.();
        if (!recentSearchesCount && search) {
          updateRecentSearch_?.();
        }
      }
    }, 10);
  }, [search]);

  const searchFn = loggedIn ? searchAll : searchAllPublic;

  /**
   * All search
   */
  const {
    data: allSearch,
    refetch: refetchAllSearch,
    isLoading: allSearchIsLoading,
    isFetching: allSearchIsFetching,
  } = useQuery({
    queryKey: ['all-search', search],
    queryFn: async ({ queryKey }) => {
      try {
        const fn = loggedIn ? searchAll : searchAllPublic;
        const resp = await dispatch(fn({ query: queryKey?.[1] }));
        return resp?.payload ?? {};
      } catch (e) {
        console.error(e);
      }
    },
    refetchOnWindowFocus: false,
    enabled: searchAllEnabled,
    // retry:5,
    // keepPreviousData:true,
    // refetchInterval: 5000,
  });

  /** Update recent search **/
  const {
    mutateAsync: updateRecentSearchAsync,
    isLoading: updateRecentSearchLoading,
  } = useMutation({
    mutationKey: ['udpate-recent-search'],
    mutationFn: async (payload) => {
      const resp = await dispatch(updateRecentSearch(payload));
      dispatch(getRecentSearch());
      return resp;
    },
  });

  const updateRecentSearch_ = async () => {
    try {
      await updateRecentSearchAsync({
        type: 'GENERAL', //GENERAL //JOB
        query: search,
      });
    } catch (e) {
      console.error(e);
    } finally {
      console.info();
    }
  };

  /**
   * People search
   */
  const {
    data: peopleSearchListing,
    refetch: refetchPeopleSearch,
    isLoading: peopleSearchIsLoading,
    isFetching: peopleSearchIsFetching,
    fetchNextPage: peopleFetchNextPage,
    hasNextPage: peopleHasNextPage,
  } = useInfiniteQuery({
    queryKey: ['people-search', { search, peopleFilters }],
    queryFn: async ({ queryKey, pageParam }) => {
      try {
        const { search, peopleFilters } = queryKey?.[1] ?? {};
        const resp = await dispatch(
          searchAll({
            category: 'people',
            query: search,
            pageParam,
            params: stringify(peopleFilters),
            isNoToken: !loggedIn,
          })
        );
        return resp?.payload ?? {};
      } catch (e) {
        console.error(e);
      }
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: searchPeopleEnabled,
  });

  /**
   * Jobs search
   */
  const {
    data: jobsSearchListing,
    refetch: refetchJobsSearch,
    isLoading: jobsSearchIsLoading,
    isFetching: jobsSearchIsFetching,
    fetchNextPage: jobsFetchNextPage,
    hasNextPage: jobsHasNextPage,
  } = useInfiniteQuery({
    queryKey: ['jobs-search', { search, jobsFilters }],
    queryFn: async ({ queryKey, pageParam }) => {
      try {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { search, jobsFilters } = queryKey?.[1];
        const resp = await dispatch(
          searchAll({
            category: 'jobs',
            query: search,
            pageParam,
            params: stringify(jobsFilters),
            isNoToken: !loggedIn,
          })
        );
        return resp?.payload ?? {};
      } catch (e) {
        console.error(e);
      }
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: searchJobsEnabled,
  });

  /**
   * company pages search
   */
  const {
    data: companyPagesSearchListing,
    refetch: refetchCompanyPagesSearch,
    isLoading: companyPagesSearchIsLoading,
    isFetching: companyPagesSearchIsFetching,
    fetchNextPage: companyPagesFetchNextPage,
    hasNextPage: companyPagesHasNextPage,
  } = useInfiniteQuery({
    queryKey: ['company-pages-search', { search, companyPagesFilters }],
    queryFn: async ({ queryKey, pageParam }) => {
      try {
        const { search, companyPagesFilters } = queryKey?.[1] ?? {};
        console.log('companyPagesFilters', companyPagesFilters);
        const resp = await dispatch(
          searchFn({
            category: 'pages',
            query: search,
            pageParam,
            params: stringify(companyPagesFilters),
          })
        );
        return resp?.payload ?? {};
      } catch (e) {
        console.error(e);
      }
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: searchCompanyPagesEnabled,
  });

  /**
   * posts search
   */

  const {
    data: postsSearchListing,
    refetch: refetchPostsSearch,
    isLoading: postsSearchIsLoading,
    isFetching: postsSearchIsFetching,
    fetchNextPage: postsFetchNextPage,
    hasNextPage: postsHasNextPage,
  } = useInfiniteQuery(
    ['posts-search', { postsFilters, search }],
    async ({ queryKey, pageParam }) => {
      try {
        const { postsFilters, search } = queryKey?.[1] ?? {};
        const fn = loggedIn ? searchAll : searchAllPublic;

        const resp = await dispatch(
          fn({
            category: 'post',
            query: search,
            pageParam,
            params: stringify(postsFilters),
            pageSize: 5,
          })
        );
        return resp?.payload ?? {};
      } catch (e) {
        console.error(e);
      }
    },
    {
      getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
      refetchOnWindowFocus: false,
      enabled: searchPostsEnabled,
      retry: 5,
      cacheTime: 300000,
      retryDelay: 1000,
    }
  );

  /**
   * events search
   */

  const {
    data: eventsSearchListing,
    refetch: refetchEventsSearch,
    isLoading: eventsSearchIsLoading,
    isFetching: eventsSearchIsFetching,
    fetchNextPage: eventsFetchNextPage,
    hasNextPage: eventsHasNextPage,
  } = useInfiniteQuery(
    ['posts-search', { eventsFilters, search }],
    async ({ queryKey, pageParam }) => {
      try {
        const { eventsFilters, search } = queryKey?.[1] ?? {};
        const fn = loggedIn ? searchAll : searchAllPublic;
        const resp = await dispatch(
          fn({
            category: 'events',
            query: search,
            pageParam,
            params: stringify(eventsFilters),
          })
        );
        return resp?.payload ?? {};
      } catch (e) {
        console.error(e);
      }
    },
    {
      getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
      refetchOnWindowFocus: false,
      enabled: searchEventsEnabled,
      cacheTime: 0,
    }
  );

  /**
   * articles search
   */

  const {
    data: articlesSearchListing,
    refetch: refetchArticlesSearch,
    isLoading: articlesSearchIsLoading,
    isFetching: articlesSearchIsFetching,
    fetchNextPage: articlesFetchNextPage,
    hasNextPage: articlesHasNextPage,
  } = useInfiniteQuery(
    ['posts-search', { articlesFilters, search }],
    async ({ queryKey, pageParam }) => {
      try {
        const { articlesFilters, search } = queryKey?.[1] ?? {};
        const fn = loggedIn ? searchAll : searchAllPublic;
        const resp = await dispatch(
          searchAll({
            category: 'articles',
            query: search,
            pageParam,
            params: stringify(articlesFilters),
            isNoToken: !loggedIn,
          })
        );
        return resp?.payload ?? {};
      } catch (e) {
        console.error(e);
      }
    },
    {
      getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
      refetchOnWindowFocus: false,
      enabled: searchArticlesEnabled,
      cacheTime: 0,
    }
  );

  // } = useInfiniteQuery({
  //   queryKey: ['posts-search', search, postsFilters],
  //   queryFn: async ({ queryKey, pageParam }) => {
  //     try {
  //       console.log(loggedIn);
  //       const fn = loggedIn ? searchAll : searchAllPublic;
  //       const resp = await dispatch(
  //         searchFn({
  //           category: 'post',
  //           query: queryKey?.[1],
  //           pageParam,
  //           params: stringify(queryKey?.[2]),
  //         })
  //       );
  //       return resp?.payload ?? {};
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   },
  //   getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
  //   refetchOnWindowFocus: false,
  //   enabled: searchPostsEnabled,
  // });

  /**
   * teams search
   */
  const {
    data: teamsSearchListing,
    refetch: refetchTeamsSearch,
    isLoading: teamsSearchIsLoading,
    isFetching: teamsSearchIsFetching,
    fetchNextPage: teamsFetchNextPage,
    hasNextPage: teamsHasNextPage,
  } = useInfiniteQuery({
    queryKey: ['teams-search', { search, teamsFilters }],
    queryFn: async ({ queryKey, pageParam }) => {
      try {
        const { search, teamsFilters } = queryKey?.[1] ?? {};
        const resp = await dispatch(
          searchFn({
            category: 'team',
            query: search,
            pageParam,
            params: stringify(teamsFilters),
          })
        );
        return resp?.payload ?? {};
      } catch (e) {
        console.error(e);
      }
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: searchTeamsEnabled,
  });

  const postsSearchListing_ = useMemo(() => {
    return [].concat.apply(
      [],
      postsSearchListing?.pages?.map((p) => p?.results ?? [])
    );
  }, [postsSearchListing]);

  const eventsSearchListing_ = useMemo(() => {
    return [].concat.apply(
      [],
      eventsSearchListing?.pages?.map((p) => p?.results ?? [])
    );
  }, [eventsSearchListing]);
  const articlesSearchListing_ = useMemo(() => {
    return [].concat.apply(
      [],
      articlesSearchListing?.pages?.map((p) => p?.results ?? [])
    );
  }, [eventsSearchListing]);

  const peopleSearchListing_ = useMemo(() => {
    return [].concat.apply(
      [],
      peopleSearchListing?.pages?.map((p) => p?.results ?? [])
    );
  }, [peopleSearchListing]);

  const jobsSearchListing_ = useMemo(() => {
    return [].concat.apply(
      [],
      jobsSearchListing?.pages?.map((p) => p?.results ?? [])
    );
  }, [jobsSearchListing]);

  const teamsSearchListing_ = useMemo(() => {
    return [].concat.apply(
      [],
      teamsSearchListing?.pages?.map((p) => p?.results ?? [])
    );
  }, [teamsSearchListing]);

  const companyPagesSearchListing_ = useMemo(() => {
    return [].concat.apply(
      [],
      companyPagesSearchListing?.pages?.map((p) => p?.results ?? [])
    );
  }, [companyPagesSearchListing]);

  const handleAuthenticatedClick = (cb) => (e) => {
    if (!loggedIn) {
      dispatch(setOpenAuthModal('sign-in'));
    } else {
      cb?.(e);
    }
  };

  return {
    query,
    pathname,
    queryStr,
    search,
    setSearch,
    activeTab,
    setActiveTab,
    handleAuthenticatedClick,
    loggedIn,
    userLoggedOut,

    // all search
    allSearch,
    refetchAllSearch,
    allSearchIsLoading,
    allSearchIsFetching,

    //people
    peopleSearchListing: peopleSearchListing_,
    refetchPeopleSearch,
    peopleSearchIsLoading,
    peopleSearchIsFetching,
    peopleFetchNextPage,
    peopleHasNextPage,
    peopleTotalCount: peopleSearchListing?.pages?.[0]?.count,

    // jobs
    jobsSearchListing: jobsSearchListing_,
    refetchJobsSearch,
    jobsSearchIsLoading,
    jobsSearchIsFetching,
    jobsFetchNextPage,
    jobsHasNextPage,
    jobsTotalCount: jobsSearchListing?.pages?.[0]?.count,

    // companies
    companyPagesSearchListing: companyPagesSearchListing_,
    refetchCompanyPagesSearch,
    companyPagesSearchIsLoading,
    companyPagesSearchIsFetching,
    companyPagesFetchNextPage,
    companyPagesHasNextPage,
    companyPagesTotalCount: companyPagesSearchListing?.pages?.[0]?.count,

    //posts
    postsSearchListing: postsSearchListing_,
    refetchPostsSearch,
    postsSearchIsLoading,
    postsSearchIsFetching,
    postsFetchNextPage,
    postsHasNextPage,
    postsTotalCount: postsSearchListing?.pages?.[0]?.count,

    //events
    eventsSearchListing: eventsSearchListing_,
    refetchEventsSearch,
    eventsSearchIsLoading,
    eventsSearchIsFetching,
    eventsFetchNextPage,
    eventsHasNextPage,
    eventsTotalCount: eventsSearchListing?.pages?.[0]?.count,

    //events
    articlesSearchListing: articlesSearchListing_,
    refetchArticlesSearch,
    articlesSearchIsLoading,
    articlesSearchIsFetching,
    articlesFetchNextPage,
    articlesHasNextPage,
    articlesTotalCount: articlesSearchListing?.pages?.[0]?.count,

    //teams
    teamsSearchListing: teamsSearchListing_,
    refetchTeamsSearch,
    teamsSearchIsLoading,
    teamsSearchIsFetching,
    teamsFetchNextPage,
    teamsHasNextPage,
    teamsTotalCount: teamsSearchListing?.pages?.[0]?.count,

    //filters
    postsFilters,
    setPostsFilters,
    eventsFilters,
    setEventsFilters,
    peopleFilters,
    setPeopleFilters,
    jobsFilters,
    setJobsFilters,
    teamsFilters,
    setTeamsFilters,
    companyPagesFilters,
    setCompanyPagesFilters,
    setArticlesFilters,
    resetFilters,
    setResetFilters
  };
};
