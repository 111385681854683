import React, { useContext, useEffect, useState, useMemo, useRef } from 'react';
import { Box, HStack, VStack } from '@mybridge/ui/layout';
import { Avatar, AvatarBadge } from '@mybridge/ui/avatar';
import { Text } from '@mybridge/ui/text';
import { Heading } from '@mybridge/ui/heading';
import { MatrixContext } from 'packages/mybridgev4/context/chat-context';
import { StylesProvider } from '@chakra-ui/react';
import styles from 'v4/components/chat/chatInit.module.scss';
import { startChatWithUser } from 'v4/store/actions/chat.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetailsByChatId } from 'v4/store/actions/user-profile.actions';
import moment from 'moment';
import {
  Badge,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@mybridge/ui';
import { ThreeDotsIcon } from '@mybridge/icons';
import { useDisclosure } from '@chakra-ui/react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react';

const ConversationListCard = (props) => {
  const { chatDetails, onClose, menuState } = props;
  const dispatch = useDispatch();
  const menuDisc = useDisclosure();
  const chatWindowCardRef = useRef();
  const { matrixClient, loggedInUser, teamsChats, isRoomDirect } =
    useContext(MatrixContext);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const [status, setStatus] = useState();
  const [roomType, setRoomType] = useState();
  const [roomSummary, setRoomSummary] = useState();
  const [loading, setLoading] = useState(true);
  const [lastMessage, setLastMessage] = useState(null);
  const [profileUrl, setProfileUrl] = useState(null);
  const [roomUserId, setRoomUserId] = useState(null);
  const [memberInfo, setMemberInfo] = useState(null);
  const [roomInfo, setRoomInfo] = useState(null);
  const [unreadCount, setUnreadCount] = useState();
  const roomId = chatDetails.roomId;

  const getChatRoomDetails = () => {
    try {
      // Get the room instance
      const room = matrixClient.getRoom(roomId);
      // Get the list of current joined room members
      setRoomInfo(room);
      //Get Unread message count
      const unreadMessages = room.getUnreadNotificationCount('total');
      setUnreadCount(unreadMessages);
      const profile = room.getMxcAvatarUrl();

      const members = room.getJoinedMembers();
      //Get current loggedin user
      const currentUser = matrixClient.getUserId();
      //Get latest room message
      const timelineEvents = room.getLiveTimeline().getEvents();
      //checking room membership for invites
      if (room.selfMembership === 'invite') {
        matrixClient.joinRoom(roomId).then(function () {
          console.log('Auto-joined %s', roomId);
        });
      }

      // Extract summary details
      const summary = {
        roomId: room.roomId,
        name: room.name, // The name of the room
        topic:
          room.currentState.getStateEvents('m.room.topic')[0]?.getContent()
            ?.topic || '', // The topic of the room
        memberCount: room.getJoinedMemberCount(), // Number of members in the room
        lastEvent: room.timeline[room.timeline.length - 1]?.event || null, // Last event in the room
        isDirect: isRoomDirect(room.roomId),
      };
      if(summary){
        setRoomSummary(summary)
      }
      // Find the last message in the timeline
      const lastMessageEvent = timelineEvents.reverse().find((event) => {
        return event.getType() === 'm.room.message';
      });
      // console.log('roomSummary', lastMessageEvent);
      // Extract last message information if available
      let lastMessage = {};
      if (lastMessageEvent) {
        const messageTime = lastMessageEvent.localTimestamp;
        const sender = lastMessageEvent.getSender();
        const content = lastMessageEvent.getContent();
        lastMessage = {
          sender: sender,
          message: content,
          messageTime: messageTime,
        };
        setLastMessage(lastMessage);
      }
      // Get presence status for each member
      let presenceStatus = '';
      if (members.length <= 2) {
        setRoomType('individual');
        for (const member of members) {
          const userId = member.userId;
          if (loggedInUser !== userId) {
            const userPresence = member.user ? member?.user?.presence : null;
            const userAvatar = member.user
              ? // ? matrixClient.mxcUrlToHttp(member.user.avatarUrl)
                member?.user?.avatarUrl
              : null;
            setMemberInfo(member);
            presenceStatus = userPresence;
            setProfileUrl(userAvatar);
          }
        }
      } else {
        // Get presence status for each member
        // console.log('profile', matrixClient.mxcUrlToHttp(profile.content_uri));
        setProfileUrl(matrixClient.mxcUrlToHttp(profile?.content_uri));
        setRoomType('group');
      }
      // presenceState(presenceStatus);
      setLoading(false);
      // room.off('Room.timeline')
    } catch (error) {
      console.error('Error fetching room details', error);
    }
  };

  const presenceState = (presence, status) => {
    switch (presence) {
      case 'online':
        return setStatus({
          color: '#0AB33E',
        });
      case 'offline':
        return setStatus({
          color: '#5B5B5B',
        });
      case 'busy':
        return setStatus({
          color: '#FF0600',
        });
      default:
        return setStatus({
          color: '#5B5B5B',
        });
    }
  };

  const handleStartChat = (roomId) => {
    dispatch(startChatWithUser(roomId));
    onClose(true);
  };

  useEffect(() => {
    // getLastMessage();
    // dispatch(getUserDetailsByChatId())
    getChatRoomDetails();
  }, [chatDetails]);
  // console.log('memberInfo', lastMessage);

  const handleDeleteConv = (roomId) => {
    matrixClient
      ?.leave(roomId)
      .then(() => {
        console.log('Left the room.');
        onClose(true);
      })
      .catch((error) => {
        console.error('Error leaving the room:', error);
      });
  };

  useEffect(() => {
    if (matrixClient && roomInfo) {
      roomInfo?.on('Room.timeline', (event, room, toStartOfTimeline) => {
        if (event.getType() === 'm.room.message' && !toStartOfTimeline) {
          // Handle the message event here
          if (
            room?.roomId === roomId &&
            chatWindowCardRef?.current?.dataset?.roomid === room?.roomId
          ) {
            getChatRoomDetails();
          }
          // if (teamsChats.includes(room.roomId) === false) {
          //   dispatch(startChatWithUser(room.roomId));
          // }
          // messageObject(event);
        }
      });
    }
  }, [matrixClient, roomInfo]);

  // useEffect(() => {
  //   if (
  //     memberInfo &&
  //     roomType === 'individual' &&
  //     menuState === false &&
  //     !status
  //   ) {
  //     dispatch(getUserDetailsByChatId(memberInfo?.userId)).then((res) => {
  //       if (res) {
  //         presenceState(
  //           res?.payload?.matrix_presence,
  //           res?.payload?.matrix_status
  //         );
  //         // if(profileUrl === null){
  //         setProfileUrl(res?.payload?.profile_pic);
  //         // }
  //       }
  //     });
  //   }
  // }, [memberInfo, chatDetails, menuState]);

  // console.log('currentRoom ', memberInfo);

  return (
    <>
      {!loading && (
        <Box
          py="5px"
          className={styles.chatCardComponent}
          ref={chatWindowCardRef}
          data-roomId={roomId}
        >
          <HStack spacing="15px">
            <Avatar
              name={memberInfo ? memberInfo?.name : roomInfo.name}
              size="md"
              src={profileUrl}
            >
              {roomType === 'individual' && (
                <AvatarBadge bg={status?.color} boxSize="12px" />
              )}
            </Avatar>
            <Box flex="1" onClick={() => handleStartChat(roomId)}>
              <Heading as="h4" fontSize="sm" fontWeight="medium" mb="0px">
                {roomSummary?.isDirect || roomType === 'individual'
                  ? memberInfo?.name
                  : roomInfo.name}
              </Heading>
              {lastMessage?.message?.format &&
              lastMessage?.message?.format === 'org.matrix.custom.html' ? (
                <Box
                  fontSize="xs"
                  maxH="20px"
                  maxW="180px"
                  overflow="hidden"
                  dangerouslySetInnerHTML={{
                    __html: lastMessage?.message?.body,
                  }}
                />
              ) : (
                <Box fontSize="xs" maxW="180px">
                  {lastMessage?.message?.body?.slice(0, 20)}
                </Box>
              )}

              <Box fontSize="xs">
                {moment(lastMessage?.messageTime).local().fromNow()}
              </Box>
            </Box>
            <VStack alignItems="space-between">
              {unreadCount > 0 && (
                <Badge colorScheme="red">{unreadCount}</Badge>
              )}
              {/*<Menu {...menuDisc}>
                 <IconButton onClick={() => menuDisc.onOpen} rightIcon={<ThreeDotsIcon
                    width="15px"
                    height="10px"
                    
                  />}>
                  Actions
                </IconButton> */}
              {/* <MenuButton
                  as={IconButton}
                  variant="transparent"
                  p={0}
                  w="12px"
                  h="10px"
                  justifyContent="center"
                  icon={<ThreeDotsIcon width={11} height={4} />}
                />
                <MenuList>
                  <MenuItem onClick={() => handleDeleteConv(roomId)}>
                    Delete Conversation
                  </MenuItem>
                </MenuList>
              </Menu> */}
              {/* <Popover {...menuDisc}>
                <PopoverTrigger>
                  <ThreeDotsIcon
                    width="15px"
                    height="10px"
                    onClick={() => menuDisc.onOpen}
                  />
                </PopoverTrigger>
                <Portal>
                  <PopoverContent>
                    <PopoverBody>
                      <Text>Popover Content here</Text>
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover> */}
            </VStack>
          </HStack>
        </Box>
      )}
    </>
  );
};

export default ConversationListCard;
