import { createReducer } from '@reduxjs/toolkit';
import { chatLogin, chatRegister, getUserPresence, updateUserPresence, startChatWithUser, clearChatUser, setActiveCallDetails, clearActiveCallDetails, startChatWithUserbyMxId, clearChatWithUserbyMxId, setTeamChatNotification, setMainChatNotification } from '../actions/chat.actions';
const isClient = typeof window !== 'undefined';
const initialState = {
  chatLoggedIn: false,
  loading: false,
  error: null,
  userPresence:null,
  userActiveChats:null,
  chatLoginDetails:null,
  activeCallDetails:null,
  userActiveChatsMxId:null,
  chatAccessToken:null,
  teamChatNotications:0,
  mainChatNotications:0
};

export const chatReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(chatLogin.pending, (state, action) => {
      state.loading = true;
      state.chatLoggedIn = false;
    })
    .addCase(chatLogin.fulfilled, (state, action) => {
      if (action.payload) {
          localStorage.setItem('chatAccess', action.payload.access_token);
          localStorage.setItem('chatDeviceId', action.payload.device_id);
          localStorage.setItem('chatData', JSON.stringify(action.payload));
        state.chatLoggedIn = true;
        state.chatAccessToken = action.payload.access_token;
        state.chatLoginDetails = action.payload
      }
    })
    .addCase(chatLogin.rejected, (state, action) => {
      state.loading = false;
      state.error = 'There was some error in login user';
      state.chatLoggedIn = false;
    })
    .addCase(chatRegister.pending, (state, action) => {
      state.loading = true;
      state.chatLoggedIn = false;
    })
    .addCase(chatRegister.fulfilled, (state, action) => {
      if (action.payload) {
          localStorage.setItem('chatAccess', action.payload.access_token);
          localStorage.setItem('chatDeviceId', action.payload.device_id);
          localStorage.setItem('chatData', JSON.stringify(action.payload));
        state.chatLoggedIn = true;
        state.chatAccessToken = action.payload.access_token;
        state.chatLoginDetails = action.payload
      }
    })
    .addCase(chatRegister.rejected, (state, action) => {
      state.loading = false;
      state.error = 'There was some error in login user';
      state.chatLoggedIn = false;
    })
    .addCase(getUserPresence.pending, (state, action) => {
      state.userPresence = null;
    })
    .addCase(getUserPresence.fulfilled, (state, action) => {
      state.userPresence = action.payload
    })
    .addCase(getUserPresence.rejected, (state, action) => {
      state.error = 'There was some error in login user';
    })
    .addCase(updateUserPresence.fulfilled, (state, action) => {
      state.userPresence = action.payload.presence
    })
    .addCase(startChatWithUser, (state, action) => {
      state.userActiveChats = action.payload
    })
    .addCase(startChatWithUserbyMxId, (state, action) => {
      state.userActiveChatsMxId = action.payload
    })
    .addCase(setTeamChatNotification, (state, action) => {
      state.teamChatNotications = state.teamChatNotications + action.payload
    })
    .addCase(setMainChatNotification, (state, action) => {
      state.mainChatNotications = action.payload
    })
    .addCase(clearChatUser, (state, action) => {
      state.userActiveChats = null
    })
    .addCase(setActiveCallDetails, (state, action) => {
      state.activeCallDetails = action.payload
    })
    .addCase(clearActiveCallDetails, (state, action) => {
      state.activeCallDetails = null
    })
    .addCase(clearChatWithUserbyMxId, (state, action) => {
      state.userActiveChatsMxId = null
    })
});
