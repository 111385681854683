import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyle = {
  indicator: {
    height: '40px',
    width: '40px',
    borderRadius: '8px',
    bg:'brandGray.500',
    border:"none"
  },
  title:{
    fontSize:"16px",
    color:"brandPrimary.500"
  },
  number:{
    fontSize:"16px",
    color:"brandGray.30",
  },
  separator:{
    bg:"#C5C5C5"
  }
};

// export the component theme
// export const stepperTheme = defineMultiStyleConfig({ variants });
export const stepperTheme = { baseStyle };
