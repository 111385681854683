/* eslint-disable @nx/enforce-module-boundaries */
import React from 'react';

import StaticPageFooter from 'v4/snippets/footer/static-footer';
import Header from 'v4/snippets/header';
const StaticPageLayout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <StaticPageFooter />
    </>
  );
};

export default StaticPageLayout;
