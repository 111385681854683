/* eslint-disable @nx/enforce-module-boundaries */
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../common/api';
import { CompanyAPIEndpoints } from '../common/endPoint';
import { jsonToFormData } from 'v4/lib/utils';
import axiosInstance from '../common/axios';
import { stringify } from 'querystring';

export const clear = createAction('clear');

export const getActiveJobs = createAsyncThunk('getActiveJobs', async ({pageParam = 1}) => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompanyJobsAPI}?status=active&page=${pageParam}&page_size=10`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getAllJobs = createAsyncThunk('getAllJobs', async () => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompanyJobsAPI}?status=all`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getSavedJobs = createAsyncThunk('getSavedJobs', async () => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompanyJobsAPI}/bookmark`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getSearchJobs = createAsyncThunk(
  'getSearchJobs',
  async (payload) => {
    try {
      console.log('payload', payload);
      const filters = stringify(payload?.query);
      const obj = {
        url: `${CompanyAPIEndpoints.JobSearchAPI}/?${filters}&page=${payload?.pageParam}`,
        method: 'GET',
        isNoToken:true
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const applyJob = createAsyncThunk('applyJob', async (payload) => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompanyJobsAPI}/apply`,
      method: 'POST',
      body: JSON.stringify(payload),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const reportJob = createAsyncThunk('reportJob', async (payload) => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompanyJobsAPI}/report/`,
      method: 'POST',
      body: jsonToFormData(payload),
      isFormData: true,
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const saveJobs = createAsyncThunk('saveJobs', async (payload) => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompanyJobsAPI}/bookmark`,
      method: 'POST',
      body: JSON.stringify(payload),
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const removeJob = createAsyncThunk('removeJob', async (payload) => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompanyJobsAPI}/bookmark?saved_job_id=${payload?.id}`,
      method: 'DELETE',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getJobsDetail = createAsyncThunk('getJobsDetail', async (id) => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompanyJobsAPI}/${id}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getPublicJobsDetail = createAsyncThunk(
  'getPublicJobsDetail',
  async (id) => {
    try {
      const obj = {
        url: `${CompanyAPIEndpoints.PublicCompanyJobsAPI}/${id}`,
        method: 'GET',
        isNoToken: true,
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const editJobDetail = createAsyncThunk('editJobDetail', async (id) => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompanyJobsAPI}/${id}`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getApplyedJobs = createAsyncThunk('getApplyedJobs', async (id) => {
  try {
    const obj = {
      url: `${CompanyAPIEndpoints.CompanyJobsAPI}/apply`,
      method: 'GET',
    };
    const response = await API(obj);
    return response;
  } catch (error) {
    return error;
  }
});

export const getUserJobPreferences = createAsyncThunk(
  'getUserJobPreferences',
  async () => {
    try {
      const obj = {
        url: `${CompanyAPIEndpoints.JobPreferences}/preference`,
        method: 'GET',
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const setUserJobPreferencesJSON = createAsyncThunk(
  'setUserJobPreferencesJSON',
  async (body) => {
    try {
      const response = await API({
        url: `${CompanyAPIEndpoints.JobPreferences}/preference`,
        method: 'PUT',
        body: JSON.stringify(body),
      });
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const setUserJobPreferences = createAsyncThunk(
  'setUserJobPreferences',
  async (body, mode = 'form') => {
    try {
      const token = localStorage.getItem('access');
      const headers = {
        'Content-type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      };
      const formData = jsonToFormData(body?.data ?? body ?? {});
      const response = await axiosInstance.put(
        `${process.env.BASE_API_URL}${CompanyAPIEndpoints.JobPreferences}/preference`,
        formData,
        { headers }
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const removeUserJobPreferencesResume = createAsyncThunk(
  'removeJobPreferencesResume',
  async (resume = 'first') => {
    try {
      const obj = {
        url: `${CompanyAPIEndpoints.JobPreferences}/remove_resume/${resume}/`,
        method: 'PUT',
      };
      const response = await API(obj);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const setRepostToEditPost = createAction('setRepostToEditPost');
export const clearUpdateJobDetails = createAction('clearUpdateJobDetails');
