"use client"
import { Box as Box_ } from "@chakra-ui/react";
import { forwardRef } from "react";
export const Box = forwardRef(({ ...props }, ref) => {
    return <Box_ ref={ref} {...props}>{props?.children}</Box_>
});

import { Stack as Stack_ } from "@chakra-ui/react";
export const Stack = forwardRef(({ ...props }, ref) => {
    return <Stack_ ref={ref} {...props}>{props?.children}</Stack_>
});

import { HStack as HStack_ } from "@chakra-ui/react";
export const HStack = forwardRef(({ ...props }, ref) => {
    return <HStack_ ref={ref} {...props}>{props?.children}</HStack_>
});

import { AspectRatio as AspectRatio_ } from "@chakra-ui/react";
export const AspectRatio = forwardRef(({ ...props }, ref) => {
    return <AspectRatio_ ref={ref} {...props}>{props?.children}</AspectRatio_>
});

import { Center as Center_ } from "@chakra-ui/react";
export const Center = forwardRef(({ ...props }, ref) => {
    return <Center_ ref={ref} {...props}>{props?.children}</Center_>
});

import { Square as Square_ } from "@chakra-ui/react";
export const Square = forwardRef(({ ...props }, ref) => {
    return <Square_ ref={ref} {...props}>{props?.children}</Square_>
});

import { Circle as Circle_ } from "@chakra-ui/react";
export const Circle = forwardRef(({ ...props }, ref) => {
    return <Circle_ ref={ref} {...props}>{props?.children}</Circle_>
});

import { Container as Container_ } from "@chakra-ui/react";
export const Container = forwardRef(({ ...props }, ref) => {
    return <Container_ ref={ref} {...props}>{props?.children}</Container_>
});


import { Flex as Flex_ } from "@chakra-ui/react";
export const Flex = forwardRef(({ ...props }, ref) => {
    return <Flex_ ref={ref} {...props}>{props?.children}</Flex_>
});

import { Spacer as Spacer_ } from "@chakra-ui/react";
export const Spacer = forwardRef(({ ...props }, ref) => {
    return <Spacer_ ref={ref} {...props}>{props?.children}</Spacer_>
});

import { Grid as Grid_ } from "@chakra-ui/react";
export const Grid = forwardRef(({ ...props }, ref) => {
    return <Grid_ ref={ref} {...props}>{props?.children}</Grid_>
});

import { GridItem as GridItem_ } from "@chakra-ui/react";
export const GridItem = forwardRef(({ ...props }, ref) => {
    return <GridItem_ ref={ref} {...props}>{props?.children}</GridItem_>
});

import { SimpleGrid as SimpleGrid_ } from "@chakra-ui/react";
export const SimpleGrid = forwardRef(({ ...props }, ref) => {
    return <SimpleGrid_ ref={ref} {...props}>{props?.children}</SimpleGrid_>
});

import { VStack as VStack_ } from "@chakra-ui/react";
export const VStack = forwardRef(({ ...props }, ref) => {
    return <VStack_ ref={ref} {...props}>{props?.children}</VStack_>
});

import { Wrap as Wrap_ } from "@chakra-ui/react";
export const Wrap = forwardRef(({ ...props }, ref) => {
    return <Wrap_ ref={ref} {...props}>{props?.children}</Wrap_>
});

import { WrapItem as WrapItem_ } from "@chakra-ui/react";
export const WrapItem = forwardRef(({ ...props }, ref) => {
    return <WrapItem_ ref={ref} {...props}>{props?.children}</WrapItem_>
});