import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const thin = defineStyle({
  borderWidth: '1px', // change the width of the border
  borderStyle: 'solid', // change the style of the border
  borderRadius: 0, // set border radius to 10
  borderColor: 'gray.300',
  opacity: 1,
});

export const dividerTheme = defineStyleConfig({
  defaultProps: { opacity: 1 },
  variants: { thin },
});
