import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

  // define a custom variant
const myBridgeVariant = definePartsStyle((props) => {

  return {
    roo:{},
    tab: {
      borderBottom: '2px solid',
      borderColor: 'transparent',
      color:"#5B5B5B",
      // use colorScheme to change background color with dark and light mode options
      bg:'transparent',
      mb: '-2px',
      _selected: {
        color: '#3D5A80',
        borderColor: '#3D5A80',
        fontWeight:'400'
      },
    },
    tablist: {
      borderBottom: '1px solid #E4E6EB',
    },
    tabpanel: {},
    tabpanels: {},
  }
})


const verticalVariant = definePartsStyle((props) => {
  const { colorScheme: c } = props; // extract colorScheme from component props

  return {
    root: {},
    tab: {
      justifyContent: 'flex-start',
      paddingLeft: '10px',
      _selected: {
        borderLeft: '2px solid #3D5A80',
        color: '#3D5A80',
      },
    },
    tablist: {
      flexDirection: 'column',
      border: 'none',
      height: '50vh',
      overflow: 'auto',
      width: '200px!important',
      borderRight: '1px solid #E4E6EB',
      borderRadius: '0',
    },
    tabpanel: {},
    tabpanels: {},
  };
});

const verticalVariantMB = definePartsStyle((props) => {
  const { colorScheme: c } = props; // extract colorScheme from component props

  return {
    root: {},
    tab: {
      justifyContent: 'flex-start',
      paddingLeft: '10px',
      _selected: {
        borderLeft: '2px solid #3D5A80',
        color: '#3D5A80',
      },
    },
    tablist: {
      flexDirection: 'column',
      overflow: 'auto',
      width: '200px!important',
      borderLeft: '1px solid #E4E6EB',
      borderRadius: '0',
    },
    tabpanel: {},
    tabpanels: {},
  };
});
const verticalVariantMBAcrticles = definePartsStyle((props) => {
  const { colorScheme: c } = props; // extract colorScheme from component props

  return {
    root: {},
    tab: {
      justifyContent: 'flex-start',
      padding: '10px',
      fontSize:'16px',
      color: '#3D5A80',
      borderRadius: '4px',
      _selected: {
        background:"#E4E6EB"
      },
    },
    tablist: {
      flexDirection: 'column',
      overflow: 'auto',
      width: '275px!important',
      borderLeft: 'none',
      borderRadius: '0',
    },
    tabpanel: {},
    tabpanels: {},
  };
});

const verticalVariantAboutUs = definePartsStyle((props) => {
  const { colorScheme: c } = props; // extract colorScheme from component props

  return {
    root: {},
    tab: {
      justifyContent: 'flex-start',
      padding: '10px',
      fontSize:'16px',
      color: '#3D5A80',
      borderRadius: '4px',
      minWidth: '285px!important',
      textAlign:'left',
      _selected: {
        background:"#E4E6EB"
      },
    },
    tablist: {
      flexDirection: 'column',
      overflow: 'auto',
      
      borderLeft: 'none',
      borderRadius: '0',
      padding:3,
    },
    tabpanel: {},
    tabpanels: {
      borderLeft:'1px solid #D6D6D6',
    },
  };
});

const variants = {
  verticalTabs: verticalVariant, myBridgeVariant, verticalVariantMB, verticalVariantMBAcrticles, verticalVariantAboutUs
};

// export the component theme
export const tabsTheme = defineMultiStyleConfig({ variants });
